export const firebaseEnvMap = {
  local: {
    firebaseConfig: {
      // Your web app's Firebase configuration here
      // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
      apiKey: "AIzaSyDsr1O14gp_-LcZf3f1gSGC-HOyjsUe3-I",
      authDomain: "somon-dev.firebaseapp.com",
      projectId: "somon-dev",
      storageBucket: "somon-dev.appspot.com",
      messagingSenderId: "492868608621",
      appId: "1:492868608621:web:ba2fe06b071aa8a03e1564",
      measurementId: "G-HJ94MCPNNG",
    }
  },
  dev: {
    firebaseConfig: {
      // Your web app's Firebase configuration here
      // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
      apiKey: "AIzaSyDsr1O14gp_-LcZf3f1gSGC-HOyjsUe3-I",
      authDomain: "somon-dev.firebaseapp.com",
      projectId: "somon-dev",
      storageBucket: "somon-dev.appspot.com",
      messagingSenderId: "492868608621",
      appId: "1:492868608621:web:ba2fe06b071aa8a03e1564",
      measurementId: "G-HJ94MCPNNG",
    }
  },
  beta: {
    firebaseConfig: {
      // Your web app's Firebase configuration here
      // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
      apiKey: "AIzaSyDOEVg1Djpf-Uv_YoY8jsCmwjr7yn-kk-w",
      authDomain: "somon-beta.firebaseapp.com",
      projectId: "somon-beta",
      storageBucket: "somon-beta.appspot.com",
      messagingSenderId: "428366536233",
      appId: "1:428366536233:web:842fbaea03ec807e32c638",
      measurementId: "G-E9SH795KPH"
    },
  },
  beta1: {
    firebaseConfig: {
      // Your web app's Firebase configuration here
      // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
      apiKey: "AIzaSyDOEVg1Djpf-Uv_YoY8jsCmwjr7yn-kk-w",
      authDomain: "somon-beta.firebaseapp.com",
      projectId: "somon-beta",
      storageBucket: "somon-beta.appspot.com",
      messagingSenderId: "428366536233",
      appId: "1:428366536233:web:842fbaea03ec807e32c638",
      measurementId: "G-E9SH795KPH"
    },
  },
  pre: {
    firebaseConfig: {
      // Your web app's Firebase configuration here
      // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
      apiKey: "AIzaSyAePnw0P5JAgDoZWUXO171IPxqWePpcoV4",
      authDomain: "somon-pre.firebaseapp.com",
      projectId: "somon-pre",
      storageBucket: "somon-pre.appspot.com",
      messagingSenderId: "83434832950",
      appId: "1:83434832950:web:d875b58f78d7ed09168497",
      measurementId: "G-480CZ7R8K7"
    },
  },
  prod: {
    firebaseConfig: {
      // Your web app's Firebase configuration here
      // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
      apiKey: "AIzaSyBoxe--v3PZ4dhywOTaejPPBXnLMMfMlWY",
      authDomain: "somon-prod.firebaseapp.com",
      projectId: "somon-prod",
      storageBucket: "somon-prod.appspot.com",
      messagingSenderId: "573366599555",
      appId: "1:573366599555:web:a6dbf2b3fe209094d5c0b6",
      measurementId: "G-421XY8LQH4"
    },
  },
};
export type Environment = "local" | "dev" | "beta" | "beta1" | "pre" | "prod";