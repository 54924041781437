// @ts-ignore
import { SwapForm } from './SwapForm';
import { SwapSuccess } from './SwapSuccess';
import { Header } from '../Header'

export const OwoSwap = () => {
  return (
    <div >
      <Header title="Swap" />
      <SwapForm />
      {/*<SwapSuccess/>*/}
    </div>
  )
}
