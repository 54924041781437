import React, { useContext } from 'react';
import { cn } from 'utils';
import { Header } from '../Header'
import { ScrollLoading } from '../scrollLoading'
import { HistoryItem, HistoryItemDataProps } from './HistoryItem'
import { usePointLedgersInfinite } from '@/client';
import { TaskContext } from '@/utils/global';
import { PointsCard } from './PointsCard';
// @ts-ignore
import TipLogo from "/src/assets/tip.svg?react";

type PointsHistoryProps = {
  className?: string
}

export const PointsHistory = (props: PointsHistoryProps) => {
  
  const { state: { userInfo } } = useContext(TaskContext);
  const { className } = props;

  const { available, originalAvailable, rows, fetchNextPage, hasNextPage, isFetching } = usePointLedgersInfinite('transfer_in', {
    idToken: userInfo?.idToken
  });

  const getNextData = () => {
    if(!isFetching && hasNextPage)
    fetchNextPage();
  }

  return (
    <div className={cn("pb-[100px] bg-[#0A0A0A]", className)}>
      <Header 
        title="Points Record"
       />
      <div className='pageContent bg-vbg-10 px-4 mt-[78px]'>
        <PointsCard
          beforeMultipliers={originalAvailable}
          afterMultipliers={available}
        />
        <div className='text-[#6D6D6D] bg-[#0A0A0A] w-full z-10 flex items-start pb-4'>
          <TipLogo className='mt-1 text-[#6D6D6D]' />
          <span className=' ml-1'>Below are points before NFT and Membership multipliers</span>
        </div>
          {rows?.length < 1 && <div className='h-[calc(100vh-200px)] flex flex-row items-center justify-center text-[#6D6D6D] text-sm leading-[21px] font-SFProDisplay font-normal'>no record yet</div>}
          <ScrollLoading
            invisibleHeight={50}
            fetchData={getNextData}
          >
          {(rows || []).map((item: HistoryItemDataProps, index: number) => {
            return (
              <HistoryItem
                key={`${index}-${item.id}`}
                itemData={item}
              />
            )
          })}
          </ScrollLoading>
      </div>
    </div>
  )
}