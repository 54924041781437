
// @ts-ignore
import LeftLogo from "/src/assets/angle-left-b.svg?react";
import { useNavigate } from 'react-router-dom';
import { sendMessage } from 'utils';

type IHeaderProps = {
	title?: string
}

export const Header = (props: IHeaderProps) => {
	const navigate = useNavigate();
	const goBack = () => {
		if(window?.ReactNativeWebView) {
			const messageData = {
				action: 'back'
			};
			const message = JSON.stringify(messageData);
			sendMessage(message);
		} else {
			navigate(-1);
		}
	}

	return (
		<div className="flex items-center fixed top-0 z-10 px-4 bg-[#0A0A0A] w-full h-[78px]">
			<div 
				className="bg-[#FEFEFE]/[0.2] w-[46px] h-[46px] rounded-full flex items-center justify-center"
				onClick={goBack}
			>
				<LeftLogo className="w-6 h-6 text-[#FEFEFE]" />
			</div>
			<div className="text-[#FEFEFE] ml-2 text-xl font-bold font-SFProDisplay">{props?.title}</div>
		</div>
	)   
};
