export enum settleStatusEum {
  UN_SETTLE,
  SETTLEING,
  SETTLED,
};

export enum claimStatusEum {
  CLAIMED = "CLAIMED",
  UN_CLAIMED = "UN_CLAIMED",
  UN_STARTED = "UN_STARTED",
  EXPIRED = "EXPIRED",
};

export const tabAnalyticsType = {
  [claimStatusEum.UN_CLAIMED]: 1,
  [claimStatusEum.CLAIMED]: 2,
  [claimStatusEum.EXPIRED]: 3,
  [claimStatusEum.UN_STARTED]: 4,
} as any;

export const defaultCount = "--";
