
export const userLocalStore = {
  set:(user: any)=>{
      localStorage.setItem('userInfo',user)
  },
  remove: ()=>{
      localStorage.removeItem('userInfo')
  },
  get: ()=>{
      return localStorage.getItem('userInfo')
  }
}