import { Base64 } from "./base64";
import { HttpClient } from "./httpClient";
import { mapToQueryString } from '../utils';

type ShortUrlHeaderType = {
  authorization: string;
};
type ShortUrlOptionType = {
  origin: string;
  paramsRetainArr: string[];
};

declare type GetShortLinkType = {
  origin: string;
  shortLink?: string;
  paramsRetainArr?: string[]; // eg: ['a', 'b']
};

export class ShortUrlApiClient extends HttpClient {
  constructor(
    url: string,
    { authorization }: ShortUrlHeaderType,
    { origin, paramsRetainArr }: ShortUrlOptionType,
  ) {
    // base url
    const base64Link = Base64.encode(origin);
    const options = {
      origin: base64Link,
      rk: paramsRetainArr,
    };
    const baseURL = `${url}?${mapToQueryString(options)}`;

    // header
    const headers = {
      "Content-Type": "application/json",
      JWT_TOKEN: authorization || "",
    };

    super({
      baseURL,
      headers,
    });
  }
}

export const shortUrl = async (
  authorization: string,
  { shortLink, origin, paramsRetainArr }: GetShortLinkType,
) => {
  let res = origin;

  try {
    const ShortUrl = new ShortUrlApiClient(
      // @ts-ignore
      shortLink,
      { authorization },
      { origin, paramsRetainArr },
    );

    /* eslint-disable */
    res = await ShortUrl.post("", undefined, undefined, "text");
  } catch (e) {
    console.log('shortUrlError: ', e);
    return origin;
  }
  return res;
};
