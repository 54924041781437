import { useSeasonConfig } from '@/hooks';
import { cn } from 'utils';

export type PointsCardProps = {
  beforeMultipliers: number | string;
  afterMultipliers: number | string;
}

export type PointsItemProps = {
  points: number | string;
  pointsClass: string;
  tips: string;
}

const PointsItem = ({points = 0, pointsClass, tips}: PointsItemProps) => {
  const { isCalculating } = useSeasonConfig();
  const showPoint = Number(Number(points).toFixed(0)).toLocaleString();
  return (
    <div className='flex flex-col flex-1 w-full justify-center items-center'>
      <div className={cn('text-2xl leading-[36px] font-SFProDisplay font-bold', pointsClass)}>{isCalculating ? "Calculating" : showPoint}</div>
      <div className='text-[#6D6D6D] text-sm leading-[21px] font-SFProDisplay font-normal'>{tips}</div>
    </div>
  )
}

export const PointsCard = (props: PointsCardProps) => {
  const { beforeMultipliers, afterMultipliers } = props;

  return (
    <div className='flex flex-row justify-between mt-2 mb-4 p-3 h-[85px] rounded-xl border border-solid border-[#FEFEFE]/20'>
      <PointsItem
        points={beforeMultipliers}
        pointsClass={'text-[#FEFEFE]'}
        tips='Before multipliers'
      />
      <i className='w-[1px] h-full bg-[#FEFEFE]/20' />
      <PointsItem
        points={afterMultipliers}
        pointsClass={'text-[#97DE44]'}
        tips='After multipliers'
      />
    </div>
  )
}