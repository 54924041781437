
import React, { useContext } from "react";
import { cn, sendMessage } from "utils"
import { Overlay, Button } from 'react-vant';
import { useClientProvider } from "client";
import { useToast } from "@/hooks";
import { useSearchParams } from "react-router-dom";
import { formatNum, trimEndZero } from './util'
import { TaskContext } from "@/utils/global";
// @ts-ignore
import BaseChain from "/src/assets/base.svg?react";
// @ts-ignore
import OwOCoin from "/src/assets/coin_owo.svg?react";

// @ts-ignore
import ETHCoin from "/src/assets/eth.svg?react";

// @ts-ignore
import SuccessIcon from "/src/assets/success.svg?react";
// @ts-ignore
import ArrowDownIcon from "/src/assets/arrow-down.svg?react";

type CLAIM_PROPS  = {
  visible: boolean;
  onCancel: () => void;
}

export const OwOSwapSuccess = (props: CLAIM_PROPS) => {
  const { isPC } = useClientProvider();
  const Toast = useToast();
  const { state: { userInfo } } = useContext(TaskContext);
  const { appVersion, platformOS } = userInfo || {};
  const [searchParams] = useSearchParams();
  const isSellOwO = searchParams.get('isSellOwO') === 'true'
  console.log('searchParams', searchParams.get('toAmount'))
  const toWallet = () => {
    if(platformOS === 'web' || !!appVersion) {
      const messageData = {
        action: 'navigate',
        router: 'Wallet',
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
      // onCancel();
    } else {
      // V1.3.0 my badge 拆分后需要强更，但是强更在V1.2.0才有。先给用户个提醒。
      Toast.show("You need to upgrade to the latest version.");
    }
  }
  return (
    <>
      <div className='px-4 overflow-scroll h-[calc(100dvh-78px-104px)] mt-[78px] no-scrollbar gap-4 flex flex-col'>
        <div className="w-full h-full flex-col justify-start items-start gap-[56px] flex">
          <div className="gap-3 flex flex-col items-center w-full">

            <div className="w-20 h-20 relative">
              <div className="w-20 h-20 left-0 top-0 absolute bg-[#252525] rounded-full">

                <div className="w-[44.14px] h-[44.14px] left-[18px] bg-[#FFF345]  top-[18px] absolute rounded-full" >
                  <SuccessIcon className="absolute w-[24.73px] h-[20.99px] left-[9px]  top-[10px]" />
                </div>

              </div>
            </div>

            <div className="h-[30px] flex-col justify-start items-center gap-4 inline-flex">
              <div className="w-[345px] text-center text-[#fdfdfd] text-xl font-bold font-SFProDisplay leading-[30px]">Swap Successful</div>
            </div>



          </div>

          <div className="self-stretch flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch  p-3  rounded-2xl border border-[#292929] flex-col justify-start items-center flex">
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <div className="w-10 h-10 relative">
                    {
                      isSellOwO
                      ?
                      <OwOCoin className="w-10 h-10" />
                      :
                      <ETHCoin className="w-10 h-10" />
                    }

                    <BaseChain className="flex-no-shrink  w-5 h-5 absolute bottom-0 right-0" />

                  </div>
                  <div className="flex-col justify-center items-start inline-flex">
                    <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{isSellOwO ? 'OwO' : 'ETH'}</div>
                  </div>
                </div>
                <div className="h-full w-[43px] flex-col justify-center items-end  inline-flex">
                  <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{trimEndZero(formatNum(searchParams.get('fromAmount'), isSellOwO ? 2 : 8 ))}</div>
                </div>
              </div>
              <div className="self-stretch pl-2 justify-start items-center gap-2 inline-flex">
                <ArrowDownIcon className="w-6 h-6 relative" />
                <div className="h-[1px] w-full bg-[#292929]"/>
              </div>
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <div className="w-10 h-10 relative">
                    {
                      isSellOwO
                      ?
                      <ETHCoin className="w-10 h-10" />
                      :
                      <OwOCoin className="w-10 h-10" />                      
                    }

                    <BaseChain className="w-5 h-5 absolute bottom-0 right-0" />
                  </div>
                  <div className="flex-col justify-center items-start inline-flex">
                    <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{isSellOwO ? 'ETH' : 'OwO'}</div>
                  </div>
                </div>
                <div className="h-full flex-col justify-center items-end gap-1 inline-flex">
                  <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{trimEndZero(formatNum(searchParams.get('toAmount'), isSellOwO ? 8 : 2))}</div>
                </div>
              </div>
            </div>




            <div className="h-[117px] flex-col justify-start items-start gap-[11px] flex w-full">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Rate</div>
                <div className="justify-start items-center gap-0.5 flex">
                  <div className="text-[#FEFEFE] text-sm font-normal font-SFProDisplay leading-[21px]">1 OwO = {trimEndZero(formatNum(searchParams.get('oneOwOSwapETH'), 8))} ETH</div>
                </div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Gas</div>
                <div className="text-[#FEFEFE] text-sm font-normal font-SFProDisplay leading-[21px]">{formatNum(searchParams.get('chainGasFee'), 10)} ETH</div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Processing fee</div>
                <div className="text-[#FEFEFE] text-sm font-normal font-SFProDisplay leading-[21px]">{searchParams.get('portionBips') / 100}%</div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div
        className={cn("mx-4 sticky bottom-0  rounded-[36px] bg-Content-Accent1  text-center py-4 text-base font-SFProDisplay font-bold")}
        onClick={toWallet}
      >Back to wallet
      </div>
    </>



  )
}