import { usePostTasksDotask, usePostTasksVerify } from '@/client';
import { useToast } from '@/hooks';
import { MsgKeyMessage } from '@/consts';

export const useTask = () => {
  const Toast = useToast();
  // @ts-ignore
  const mutationDoTask = usePostTasksDotask();
  // @ts-ignore
  const mutationVerify = usePostTasksVerify();

  const toVerifyTask = (taskId: string, stopLoding: () => void) => {
    // taskid存起来
    localStorage.setItem('taskId', taskId);
    const params = {
      taskId
    };
    return new Promise<string>((resolve, reject) => {
      // @ts-ignore
      mutationVerify.mutate(params, {
        onSuccess: (response) => {
          const responseData = response.data;
          const { success, code, obj, msgKey } = responseData;
          if (!success) {
            if (code === '100020') {
              // 去授权
              return resolve('connect');
            } else if (code === '100041') {
              localStorage.removeItem('taskId');
              // 验证失败，需要给用户特殊提示
              return resolve('waiting');
            }
            stopLoding();
            const message = MsgKeyMessage?.[msgKey] ?? 'Verification failed. Please try again.';
            Toast.show(message);
            // taskId删掉
            localStorage.removeItem('taskId');
            return resolve('failed');;
          }
          // taskId删掉
          localStorage.removeItem('taskId');
          resolve("success");
        },
        onError: (error) => {
          localStorage.removeItem('taskId');
          stopLoding();
          Toast.show('system.error');
          resolve('failed');
        }
      });
    })
  };

  const checkIn = (taskId: string, stopLoding: () => void) => {
    return new Promise<string>((resolve, reject) => {
      // @ts-ignore
      mutationDoTask.mutate({
        taskId: taskId
      }, {
        onSuccess: (response) => {
          const responseData = response.data;
          console.log('responseData', responseData)
          // @ts-ignore
          const { success, msgKey } = responseData;
          if (!success) {
            Toast.show('Check in failed. Please try again.');
            console.log('msgKey', msgKey)
            stopLoding();
            return resolve('failed');
          }
          resolve('success');
        },
        onError: (error) => {
          stopLoding();
          Toast.show('system.error');
          console.log('msgKey', error);
          resolve('failed');
        }
      });
    })
  };

  return {
    toVerifyTask,
    checkIn
  }
};
