import { sendMessage } from 'utils';
import { BadgeDetailsProps } from './type';
import { envMap } from '@/consts'
import { getTwitterShareUrl } from 'short-link'

export const getLatestBadgeInSelected = (unClaimedList: BadgeDetailsProps[], selectedList: string[]): any => {
  let latestBadge;
  unClaimedList.some(v => {
    const a = selectedList.find(j => {
      if(v.id === j) {
        latestBadge = v
        return v
      }
    });
    if (a) {
      return true
    }
    return false
  })
  return latestBadge;
}

export const onShareBadge = async (shareParams: {
  badge_id: number | string;
  inviteCode: string;
  handle: string;
  avatar: string;
}, idToken: string) => {
  const { badge_id, inviteCode, handle, avatar } = shareParams;
  const landingPageUrl = envMap.landingUrl;
  const textContent = 'More badges,more $S$(ow)LS$$ Join me to become $(ow)L$t-o.gether!'
  const authorization = idToken || '';
  const shortLinkApiUrl = import.meta.env.VITE_PUBLIC_SHORT_LINK
  const link = `${landingPageUrl}/badge`
  const params = {
    handle,
    avatar,
    inviteCode,
    badgeType: 'badge',
    badgeId: badge_id,
  }

  const router = await getTwitterShareUrl({
    link,
    params,
    text: textContent,
    authorization,
    shortLinkApiUrl
  })

  const messageData = {
    action: 'navigate',
    router
  };

  const message = JSON.stringify(messageData);
  sendMessage(message);
}
