import React, { useEffect } from 'react';
import { cn } from 'utils';
import { Header } from '../Header';
import { FeedItem } from './FeedItem'
import { useGetTwitterFeed } from './useGetTwitterFeed'
import { ScrollLoading } from '../scrollLoading'
import { useParams } from 'react-router-dom';

export const TwitterFeed = (props) => {
  const { className } = props;

  const { taskId } = useParams();

  const {rows, fetchData} = useGetTwitterFeed(taskId);

  //回调函数
  function receiveMessageFromIndex ( event ) {
    console.log( '我是iframe,我接受到了：', '-----', event.data.element, '-----',event.data.height );
    const height = event?.data?.height;
    const elementId = event?.data?.element;
    if (height) {
      const iframeEle = document.getElementById(elementId)
      if (!!iframeEle) {
        iframeEle.style.height = height + "px"
      }
    }
  }

  useEffect(() => {
    window.addEventListener("message", receiveMessageFromIndex, false);
  }, [])

  return (
    <div className={cn("pb-[100px] bg-[#0A0A0A]", className)}>
      <Header title="Selected tweets" />

      <div className='pageContent mt-[calc(78px)]'>
        {rows?.length < 1 && <div className='h-[calc(100vh-200px)] flex flex-row items-center justify-center text-[#6D6D6D] text-sm leading-[21px] font-SFProDisplay font-normal'>no tweets yet</div>}
        <ScrollLoading
          invisibleHeight={30}
          fetchData={fetchData}
        >
        {rows.map((item, index: number) => {
          return <FeedItem item={item} key={index} index={index} />
        })}
        </ScrollLoading>
      </div>
    </div>
  )
}