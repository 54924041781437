import React, { useEffect } from 'react';
import { Button, Overlay, Image } from 'react-vant';

// @ts-ignore
import TwitterSvg from "/src/assets/twitter.svg?react";
import { logEventAnalytics } from 'firebase';
import { useClientProvider } from 'client';
import { cn } from 'utils';

type ClaimedSuccessProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onShare: () => void;
}

export const ClaimedSuccess = ({
  visible,
  setVisible,
  onShare
}: ClaimedSuccessProps) => {
  const { isPC } = useClientProvider();
  useEffect(() => {
    visible && logEventAnalytics('exposure_successfullyClaimedOwO')
  }, [visible])
  return (
    <Overlay visible={visible} onClick={() => setVisible(false)}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10
      }}>
      <div className={cn('flex flex-col justify-center items-center mx-8', {
        'w-[408px] bg-Backgroun-Tertiary h-auto px-6 pb-6 pt-[64px] rounded-2xl': isPC
      })}>
        <div
          className={cn('text-3xl font-SFProDisplay font-bold leading-[33.6px] mb-[57px] bg-clip-text text-transparent bg-gradient-to-r from-[#EEFFD8] to-[#FFFA93]', {
            'mb-10': isPC
          })}
        >
          Successfully claimed
        </div>
        <div className='mx-2'>
          <Image src={'img/owoSuccessClaim.webp'} width={isPC ? 313 : '100%'} fit='cover' />
        </div>
        <Button
          type='primary'
          block
          round
          color='#FFF345'
          loadingText={'Claiming'}
          className={cn('h-14 text-[#0A0A0A] mt-[86px]', {
            'mt-10': isPC
          })}
          onClick={() => {
            logEventAnalytics("click_successfullyClaimedOwO_share");
            onShare();
          }}
        >
          <span
            className='text-[#0A0A0A] text-base font-SFProDisplay font-bold leading-6 flex flex-row items-center gap-1'
          >
            <TwitterSvg />
            Share to Twitter
          </span>
        </Button>
        <div
          className='h-14 w-full mt-2 flex flex-row items-center justify-center rounded-full'
          onClick={() => {
            setVisible(false)
          }}
        >
          <span
            className='text-[#FFF345] text-base font-SFProDisplay font-bold leading-6  gap-1'
          >
            Cancel
          </span>
        </div>
      </div>
    </Overlay>
  );
};