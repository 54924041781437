import { Skeleton } from 'react-vant';

export const UnclaimedSkeleton = () => {
  return (
    <div
      className='overflow-scroll h-[calc(90dvh-78px-68px)]'
      style={{
        // @ts-ignore
        '--rv-skeleton-avatar-size': '48px',
        '--rv-padding-md': '0px'
      }}
    >
      <div className='px-4 flex flex-col gap-3 pb-[104px]'>
      {new Array(5).fill(null).map((_i, index) => {
        return (
          <div
            key={index}
            className="rounded-2xl p-3 flex flex-row items-center w-[100%] h-[82px]"
          >
            <Skeleton row={0} avatar className='mr-3' />
            <div>
              <Skeleton row={2} rowHeight={20} rowWidth={152} style={{'--rv-border-radius-max': '4px'}}/>
            </div>
          </div>
        )})}
      </div>
    </div>
  )
}