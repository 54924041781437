import { shortUrl } from "./shortUrl";
import { mapToQueryString } from "./utils";

type GetSUrlOptionType = {
  authorization: string;
  shortLinkApiUrl: string;
  link: string;
  params?: object;
};

type TwitterShareOptionType = GetSUrlOptionType & {
  text?: string;
}

export const getSUrl = async ({
  authorization,
  link,
  params,
  shortLinkApiUrl
}: GetSUrlOptionType) => {
  const originUrl = params ? `${link}?${mapToQueryString(params)}` : link;
  const paramsRetainArr = Object.keys(params || {});

  const shortLinkOptions = {
    shortLink: shortLinkApiUrl,
    origin: originUrl,
    paramsRetainArr,
  };
  const sUrl = await shortUrl(authorization, shortLinkOptions);

  return sUrl;
};

export const getTwitterShareUrl = async ({
  link,
  params,
  text,
  authorization,
  shortLinkApiUrl
}: TwitterShareOptionType) => {
  const sUrl = await getSUrl({
    authorization,
    link,
    params,
    shortLinkApiUrl
  });
  const router = `https://twitter.com/compose/post?text=${text} ${sUrl}`;

  return router
};
