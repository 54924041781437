import React, { useContext, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
// @ts-ignore
import HistoryLogo from "/src/assets/history.svg?react";
import { cn, sendMessage } from 'utils';
import { useCountUp } from '@/hooks/useCountUp';
import { TaskContext } from '@/utils/global';
import { useFirebaseSeasonConfig, useSeasonConfig } from '@/hooks';

type QuestDataType = {
  available: number,
  isVip: boolean,
  vip?: string,
  pfp?: string,
};

type PropsType = {
  questData: QuestDataType
};

type PointDataType = {
  available: number;
  isHighlight: boolean;
  isCalculating?: boolean;
 }
const Points = forwardRef((props: PointDataType, ref) => {
  const { available, isHighlight, isCalculating } = props;
  const point = available?.toFixed(0) ?? 0;
  const { nextValue, transition } = useCountUp();

  useImperativeHandle(ref, () => ({
    pointAnimation
  }));

  const pointAnimation = () => {
    const curPoints = Number(point);
    const prePoints = curPoints - Math.min(curPoints, 1000);

    if (curPoints !== prePoints) {
      const params = {
        start: prePoints,
        end: curPoints,
        duration: 500
      };
      transition(params);
    }
  };

  const pointText = isCalculating ? "Calculating" : (nextValue ? Number(nextValue).toLocaleString() : (Number(point || 0).toLocaleString()));

  return (
    <span className={cn("text-left align-top text-[28px] font-SFProDisplay font-bold whitespace-nowrap text-[#fdfdfd]", {
      "text-[#97DE44]": isHighlight
    })}>
      {pointText}
    </span>
  );
});

export const TaskHeader = (props: PropsType) => {
  const { isVip, vip, available, pfp } = props?.questData;
  const isHighLight = !!((isVip && vip) || pfp);
  const { state: { userInfo } } = useContext(TaskContext);
  const { currentSeasonId, isCalculating } = useSeasonConfig();
  const pointsRef: any = useRef();
  const isHidden = userInfo?.isHidden ?? true;
  const { curFirebaseSeasonConfig } = useFirebaseSeasonConfig();

  useEffect(() => {
    if (!!userInfo && !!available) {
      // console.log('userInfouserInfo', userInfo)
      pointsRef?.current?.pointAnimation();
    }
  }, [userInfo, available]);

  const toPointHistory = () => {
    const messageData = {
      action: 'navigate',
      router: 'PointsHistory'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const toBuyMember = () => {
    const messageData = {
      action: 'popup',
      router: 'Member'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  return (
   
    <div className="h-[308px] w-full bg-[#252525] rounded-b-[32px]">
      <div className={`w-full h-[219px] rounded-b-[32px] bg-[url("/img/header-bg.webp")] bg-cover relative pl-6 pt-[77px]`}>
        <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/monster.webp`} class="absolute w-[130px] h-[156px] right-0 bottom-[-3px]" />
        <div className="flex flex-col w-[212px] h-fit">
          <div className="bg-[#fff9a9] rounded-md inline-flex px-2 py-1 flex-row justify-center items-center w-fit h-[26px]">
            <span className="text-center align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#0a0a0a]">
              · Season {currentSeasonId} ·
            </span>
          </div>
          <span className="text-left align-top text-[44px] leading-[52px] font-Oswald font-bold tracking-[-1.76px] whitespace-nowrap text-[#0a0a0a] inline-flex">
            QUESTS
          </span>
          <span className="text-left align-top text-xs font-SFProDisplay font-normal leading-[18px] text-[#4d4d4d] mt-0.5">
            Embark on quests, watch your points soar, and unlock a world of rewards!
          </span>
        </div>
      </div>
      <div className="w-full h-[89px] px-6 flex flex-row justify-between items-center">
        <div className="inline-flex flex-row items-center w-fit h-fit">
          <div className={cn("inline-flex flex-row items-center justify-center w-9 h-9 bg-transparent rounded-full mr-1", {
            "bg-[#FFF345] mr-2": isHighLight
          })}>
          <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/points-icon.svg`} class=" w-[30px] h-[30px]" />
          </div>
          <div className="inline-flex flex-col w-fit h-fit">
            <div className="inline-flex flex-row items-center h-[34px]">
              <Points ref={pointsRef} available={available} isHighlight={isHighLight} isCalculating={isCalculating} />
              {!isHidden && (vip !== '100%' || pfp !== '200%') && <div
                className="bg-[#486b1e] rounded-full px-2 py-0.5 flex flex-row justify-center items-center ml-2 w-[67px] h-fit cursor-pointer"
                onClick={toBuyMember}
              >
                <span className="text-left align-top text-xs font-SFProDisplay font-bold leading-[18px] whitespace-nowrap text-[#97de44]">
                  Get more
                </span>
              </div>}
            </div>
            {isHighLight && <div className="inline-flex flex-row items-center h-[21px] mt-[-2px] text-left align-top text-sm font-SFProDisplay font-bold whitespace-nowrap text-[#FFF345]">
              {isVip && <>
                <osp-image src={curFirebaseSeasonConfig?.lightMemberIcon} class="mr-1 w-[14px] h-[14px]" />  
                {vip}
              </>}
              {pfp && <>
                {isVip && <span className="mr-1 ml-1">+</span>}
                <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/nft.svg`} class="mr-1" />
                {pfp}  
              </>}  
              <span className="text-xs font-SFProDisplay font-normal text-[#FEFEFE] inline-flex ml-1">
                Boosted
              </span>
            </div>}
          </div>
        </div>

        <div className="bg-[#0a0a0a] rounded-2xl flex flex-col justify-center items-center w-11 h-11 cursor-pointer" onClick={toPointHistory}>
          <HistoryLogo />
        </div>
      </div>
    </div>
  )
};
