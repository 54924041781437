export const mapToQueryString = (map: { [key: string]: any }) => {
  const v: any[] = [];

  Object.keys(map).forEach((value) => {
    if (typeof map[value] !== "undefined")
      if (Array.isArray(map[value]))
        // 兼容array类型参数only 使用后端为spring java的类型
        v.push(`${value}=${map[value].join(",")}`);
      else {
        const vv = map[value];

        v.push(`${value}=${vv ?? ""}`);
      }
  });
  return v.join("&");
};