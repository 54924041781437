import { useState, useContext } from 'react'
import { BADGE_LIST } from '@/consts';
import { cn, dayjs } from 'utils';
import { useEffect } from 'react';
// @ts-ignore
import ClaimSvg from "/src/assets/claim.svg?react";
// @ts-ignore
import HistoryLogo from "/src/assets/history.svg?react";
import { useGlobalLadder, useGlobalLadderHistory } from '@/client';
import { TaskContext } from '@/utils/global';
import { Header } from '@/components/Header';
import { Tabs } from 'react-vant'
import { LeaderList } from '../LeaderList';
import { getMinimumScoreRank } from '../LeaderBoard';
import { rangeArr } from '@/utils';

export const LeaderBoardHistory = () => {
  const { state: { userInfo } } = useContext(TaskContext);
  const { data: currentLadder, refetch: refetchLadder } = useGlobalLadder({ idToken: userInfo?.idToken });

  const [tabArr, setTabArr] = useState<number[]>();
  const [activeCycle, setActiveCycle] = useState(tabArr?.[0]);
  const { data: ladderHistory, refetch: refetchLadderHistory } = useGlobalLadderHistory({
     idToken: userInfo?.idToken,
     cycle: activeCycle ?? 1,
     season: "season2"
  });
  const minimumScoreRank = getMinimumScoreRank({
    level: ladderHistory?.myLadder?.level,
    globalLadder: ladderHistory?.globalLadder
  });

  useEffect(() => {
    if (!!userInfo?.idToken) {
      refetchLadder();
    }
  }, [userInfo]);

  useEffect(() => {
    if(currentLadder) {
      const tabValue = rangeArr(currentLadder?.cycle ?? 1);
      setTabArr(tabValue);
      setActiveCycle(tabValue?.[0])
    }
  }, [currentLadder]);

  useEffect(() => {
    if(activeCycle) {
      refetchLadderHistory();
    }
  }, [activeCycle]);

  const onClickTab = ({ index, name }: { index: number, name: number }) => {
    setActiveCycle(name);
  };

  return (
    <div className="bg-Content-Inverse-Primary-BG">
      <Header title="History" />
      {tabArr?.length
        ? <Tabs
          align='start'
          type='capsule'
          sticky
          swipeThreshold={4}
          offsetTop={78}
          // scrollspy={{ autoFocusLast: true, reachBottomThreshold: 50 }}
          defaultActive={tabArr[0]}
          color="#FEFEFE"
          titleActiveColor='#0A0A0A'
          titleInactiveColor='#7E7E7E'
          className='mt-[78px] px-3'
          style={{
            '--rv-tab-capsule-padding': '12px 4px',
            '--rv-tabs-line-height': '56px',
            '--rv-tabs-nav-background-color': '#0A0A0A'
          }}
          onClickTab={onClickTab}
        >
          {tabArr.map((item) => {
            return (
              <Tabs.TabPane
                key={item}
                name={item}
                title={(active) => (
                  <div className={cn('px-4 py-2 rounded-full', {
                    'bg-[#121212]': !active
                  })}>
                    <span
                      className={cn('text-left align-top font-SFProDisplay', {
                        'font-bold': active
                      })}
                    >
                      {`Week ${item}`}
                    </span>
                  </div>

                )}
              >
                {ladderHistory
                  ? <>
                      <div className="inline-flex flex-row items-center h-fit px-1 pt-4 pb-3">
                        <osp-image src={BADGE_LIST[ladderHistory?.myLadder.level]?.icon} class="w-[88px] h-[88px]" />
                        <div className="flex flex-col ml-4 w-fit h-fit">
                          <span className="text-center align-top text-[28px] font-SFProDisplay font-bold leading-[34px] whitespace-nowrap text-[#FEFEFE] inline-flex">
                            {`${BADGE_LIST[ladderHistory?.myLadder.level]?.name} League`}
                          </span>
                          <span className="text-left align-top text-sm font-SFProDisplay font-normal leading-[21px] whitespace-nowrap text-[#C6C6C6] inline-flex">
                            {`${dayjs(ladderHistory?.startTime).utc().format('MMMM D')} - ${dayjs(ladderHistory?.endTime).utc().format('MMMM D, YYYY')}`}
                          </span>
                        </div>
                      </div>
                      <div className="mx-[-12px]">
                        <LeaderList globalLadder={ladderHistory?.globalLadder} myLadder={ladderHistory?.myLadder} userInfo={userInfo} minimumScoreRank={minimumScoreRank} isHistory={true}/>
                      </div>    
                    </>
                  : <div className='h-[calc(100vh-300px)] flex flex-row items-center justify-center text-[#6D6D6D] text-xs font-SFProDisplay font-normal'>No history yet</div>
                }                    
              </Tabs.TabPane>
            )
          })}
        </Tabs>
        : <div className='h-[calc(100vh-200px)] mt-[78px] flex flex-row items-center justify-center text-[#6D6D6D] text-xs font-SFProDisplay font-normal'>No history yet</div>}
    </div>
  )
}
