import { useState, useEffect } from 'react'
import { useDebounce } from '@/hooks'
import { useGetTwitterTweets } from '@/client'

const Limit = 3;

export const useGetTwitterFeed = (taskId) => {
  const [showData, setShowData] = useState<string[]>([])
  const [offset, setOffset] = useState<number>(0)
  const { data, error, isLoading } = useGetTwitterTweets(taskId);

  const fetchFn = () => {
    if (!data?.length) return;

    let newOffset;

    if((offset + Limit) > data.length) {
      newOffset = data.length
    } else {
      newOffset = offset + Limit
    }
    setShowData(data.slice(0, newOffset))
    setOffset(newOffset)
  }

  const fetchData = useDebounce(fetchFn, 500)

  useEffect(() => {
    if(!isLoading) {
      console.log('useGetTwitterFeed----init')
      fetchData()
    }
  }, [isLoading])

  return {
    rows: showData,
    isLoading,
    fetchData,
    showData,
  };
};



const mockData: string[] = [
  // 'https://twitter.com/talkofthecharts/status/1768508440597704709/photo/1',
  // "https://twitter.com/LineaBuild/status/1765831539668181489",
  // "https://twitter.com/PlayWithMugen/status/1767490720301224363",
  // "https%3A%2F%2Ftwitter.com%2Fjack%2Fstatus%2F20",
  // "https://twitter.com/Reza_Zadeh/status/1344009123004747778?ref_src=twsrc%5Etfw",
  // "https://twitter.com/CAPy9iUiGOJqqFG/status/1767005776025936299",
  // "https://twitter.com/PlayWithMugen/status/1743573120022380801",
  // "https://twitter.com/PlayWithMugen/status/1752904610866397501",
  // "https://twitter.com/LastOdyssey_io/status/1767484643228655681",
  // "https://twitter.com/karma_shopping/status/1748064966790275541",
  // "https://twitter.com/CryptoSignalsU/status/1759818299146166557",
  // "https://twitter.com/MentorsTrading/status/1755994574760157467",
  // "https://twitter.com/operatiorpay/status/1746019600960307292",
  // "https://twitter.com/respect_vn/status/1764662607603282231",
  // "https://twitter.com/TWTOPTOON/status/1644240493856030722",
  // "https://twitter.com/ohthoughtsquote/status/1767751099652686190",
  // "https://twitter.com/CryptooSeneR/status/1766964834677977136",
]