import { createContext, useReducer } from 'react';

import { IActionEum, IContext, IReduce, IState } from './type';

const initState = {
  userInfo: {}
} as IState;

const initContext = {
  state: initState,
  dispatch: () => {}
}

const reduce: IReduce = (state, action) => {
  switch (action.type) {
    case IActionEum.SET_USERINFO: {
      const newState = {
        ...state,
        userInfo: { ...action.payload },
      };
      localStorage.setItem('userInfo', JSON.stringify(newState.userInfo))
      return newState;
    }
    case IActionEum.UPDATE_USERINFO: {
      const newState = {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
        isLogin: true
      };
      localStorage.setItem('userInfo', JSON.stringify(newState.userInfo))
      return newState;
    }
    case IActionEum.REMOVE_USERINFO: {
      const newState = { ...state, isLogin: false };
      localStorage.removeItem('userInfo');
      return newState;
    }
    default:
      throw new Error();
  }
}

export const useGlobalData = () => {
  const [state, dispatch] = useReducer<IReduce>(reduce, initState);

  return {
    state,
    dispatch,
  }
}

export const TaskContext = createContext<IContext>(initContext as unknown as IContext);
