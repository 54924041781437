import React, { useContext, useState, useEffect } from 'react';
// @ts-ignore
import CheckedLogo from "/src/assets/check-alt.svg?react";
// @ts-ignore
import Arrow from "/src/assets/angle-left-b.svg?react";
// @ts-ignore
import Spinner from "/src/assets/spinner.svg?react";
import { TaskProgress } from './TaskProgress';
import { fomateDesc } from '@/utils';
import { compare, sendMessage } from 'utils';
import { BUTTON_TEXT, TASK_ICON, POPUP_ROUTER, APP_STORE_ADDRESS } from './consts';
import { useTask, useSocial, useToast } from '@/hooks';
import { TaskListResponse } from '@/client/models';
import { cn } from 'utils';
import { TaskContext } from '@/utils/global';

type PropsType = {
  item: TaskListResponse
  refreshData: () => void
  isVerify: boolean,
  isClaimBadge?: boolean,
  setStopLoading: (stopLoading:boolean) => void,
  stopLoading: boolean,
};

export const TaskItem = (props: PropsType) => {
  const { item, refreshData, isVerify, isClaimBadge, stopLoading, setStopLoading } = props;
  const { state: { userInfo } } = useContext(TaskContext);
  const Toast = useToast();
  const { checkIn, toVerifyTask } = useTask();
  const { toLink, toConnectSocial } = useSocial();
  const [isLoading, setIsLoading] = useState(false);
  const [itemStatus, setItemStatus] = useState(!!item?.status);
  const [itemBtnType, setItemBtnType] = useState(item?.btn);
  const itemIcon = TASK_ICON?.[item?.code] ?? TASK_ICON.sign_in;
  const progress = Math.min(Number(item?.progress), Number(item?.limit));

  useEffect(() => {
    const taskId = localStorage.getItem('taskId');
    if (taskId === item?.taskId && isVerify) {
      toVerify();
    };
  }, [isVerify]);

  useEffect(() => {
    if (item) {
      setItemStatus(!!item?.status);
    }
  }, [item]);

  useEffect(() => {
    stopLoading && stopLoding();
  }, [stopLoading])


  const onClickBtn = () => {
    switch (itemBtnType) {
      case 1: toJumpPage(); break;
      case 2: toVerify(); break;
      case 3: toCheckIn(); break;
      case 4: toRate(); break;
      case 5: toOwo(); break;
    }
  };

  const toJumpPage = () => {
    if (item?.code === 'claim_badge' && !isClaimBadge) {
      Toast.show('No available badges to claim');
      return;
    }
    const action = POPUP_ROUTER.includes(item?.url) ? 'popup' : 'navigate';
    const messageData = {
      action,
      router: item?.url ?? ''
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const toRate = () => {
    const url = APP_STORE_ADDRESS?.[userInfo?.platformOS];
    if (url) {
      const messageData = {
        action: 'navigate',
        router: url
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
      setItemBtnType(2);
    }
  };

  const stopLoding = () => {
    setIsLoading(false);
    setStopLoading(false);
  };

  const toVerify = async () => {
    setIsLoading(true);
    localStorage.removeItem('taskId');
    const res = await toVerifyTask(item?.taskId, stopLoding);
    if (res === 'connect') {
      toConnectSocial(item?.connectUrl, item?.domain);
      stopLoding();
      return;
    } else if( res === 'waiting') {
      // UI说这个颜色是feedback/warning，但目前还未引用到新的颜色变量，临时写死色值。
      Toast.show(<span className='text-[#FFC068]' style={{wordBreak:'break-word'}}>Verification in progress. Points given when requirements met.</span>);
      refresh();
      return;
    } else if (res === 'success') {
      if (item.limit === 1) {
        setItemStatus(true);
      }
      refresh();
      const messageData = {
        action: 'popup',
        router: 'TaskIntro'
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
    }
  };

  const toCheckIn = async () => {
    setIsLoading(true);
    const res = await checkIn(item?.taskId, stopLoding);
    if (res === 'success') {
      Toast.show(<span className='text-Content-Positive'>Checked in!</span>);
      setItemStatus(true);
      refresh();
      const messageData = {
        action: 'popup',
        router: 'TaskIntro'
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
    }
  };

  const refresh = () => {
    setTimeout(() => {
      refreshData();
      stopLoding();
    }, 2000);
  };

  const openLink = () => {
    const linkUrl: any = Object.values(item?.link)[0] ?? '';
    toLink(linkUrl);
  };

  const toOwo = () => {
    const messageData = {
      action: 'popup',
      router: 'owo'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  }
  // 使用app打开 & 版本小于1.6.0 不展示owo入口
  const isShowOwoEntry = !(window?.ReactNativeWebView && (!!userInfo?.appVersion && compare(
    userInfo?.appVersion,
    "1.6.0",
    "<"
  )));
  return (
    <div className="flex flex-col w-full h-fit mt-2 py-3">
      <div className={cn(
        'flex flex-row items-center w-full h-fit min-h-[62px]',
        { 'items-start': item.code=== 'owo_asset' },
      )}>
        <osp-image src={itemIcon} class="w-12 h-12 rounded-xl" />
        <div className="flex flex-col ml-3 w-full justify-center">
          <div className="flex flex-row items-center w-full">
            <div className="flex flex-col w-full justify-center">
              <span className="text-left align-top text-base font-SFProDisplay font-bold text-[#fdfdfd] inline-flex">
                {item?.title}
              </span>
              <div className="inline flex-row items-center mt-0.5 text-xs font-SFProDisplay font-normal leading-[18px] text-[#A1A1A1]"
                dangerouslySetInnerHTML={{ __html: fomateDesc(item?.desc) }} />
              {item?.link && <div className="flex flex-row items-center mt-0.5 h-[18px] cursor-pointer" onClick={openLink}>
                <span className="text-left align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#fff345]">
                  {Object.keys(item?.link)[0]}
                </span>
                <Arrow className="w-3 h-3 ml-0.5 text-[#FFF345] rotate-180" />
              </div>}
            </div>
            {!!itemBtnType ?
              (itemStatus
                ? <CheckedLogo className="w-4 h-4	text-[#FFF345] ml-2" />
                : <div className="relative bg-[#0a0a0a] border-2 border-solid border-[#fff345] rounded-full flex flex-col justify-center items-center h-9 ml-2 min-w-[69px] px-4 cursor-pointer"
                  onClick={onClickBtn}
                >
                  {isLoading && <Spinner className="absolute fill-[#FFF345] w-5 h-5 animate-spin" />}
                  <span className={cn("text-left align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#fdfdfd] mx-4 flex-shrink-0 opacity-100", {
                    "opacity-0": isLoading
                  })}>
                    {BUTTON_TEXT[itemBtnType]}
                  </span>
                </div>)
              : null
            }
            {/* {
              (!!itemBtnType && item.code ==='owo_asset' && isShowOwoEntry) && (
                <div className="relative bg-[#0a0a0a] border-2 border-solid border-[#fff345] rounded-full flex flex-col justify-center items-center h-9 ml-2 min-w-[69px] px-4"
                  onClick={onClickBtn}
                >
                  {isLoading && <Spinner className="absolute fill-[#FFF345] w-5 h-5 animate-spin" />}
                  <span className={cn("text-left align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#fdfdfd] mx-4 flex-shrink-0 opacity-100", {
                    "opacity-0": isLoading
                  })}>
                    {BUTTON_TEXT[itemBtnType]}
                  </span>
                </div>
              )
            } */}
            {!item.showProgress && !!item.progress && ['tribe_recommended', 'high_upvote'].includes(item?.code || '') && (<span className="text-Content-Accent1">{item.progress}</span>)}
            {(itemStatus && ['owo_asset_over_limit'].includes(item?.code || ''))|| (['tribe_recommended', 'high_upvote'].includes(item?.code || '') && !!item.progress) && <CheckedLogo className="w-4 h-4	text-[#FFF345] ml-2" />}
          </div>
          {item.showProgress && <div className="flex flex-row justify-between items-center w-full h-[16px] mt-0.5">
            <div className="bg-[#252525] rounded-lg w-full h-2 mr-4">
              <div
                className="bg-[#fff345] rounded-lg h-2"
                style={{ width: `${(progress / Number(item.limit)) * 100}%` }}
              />
            </div>
            {itemStatus
              ? !itemBtnType && <CheckedLogo className="w-4 h-4	text-[#FFF345]" />
              : <span className="text-[#FEFEFE] text-[10px] font-bold font-SFProDisplay">{`${progress}/${Number(item.limit)}`}</span>
            }
            </div>}
          {userInfo?.owoAmount && item.code=== 'owo_asset' && (
             <div className='flex items-center justify-between bg-Backgroun-Tertiary rounded-lg py-1 px-3 mt-2'>
                <div className='text-[#FEFEFE] flex items-center'>
                  <span>
                    Owned 
                  </span>
                  <osp-image src={'/img/owo.webp'} class="w-3 h-3 ml-1 mr-[2px]" />:
                  <span className='font-bold'>{userInfo?.owoAmount}</span>
                </div>
                <div className='flex flex-row items-center cursor-pointer' onClick={toOwo}>
                  <span className='text-Content-Accent1 font-SFProDisplay font-bold text-xs'>Get more</span>
                  <Arrow className="w-3 h-3 ml-0.5 text-[#FFF345] rotate-180" />
                </div>
             </div>
            )}
        </div>
      </div>
      {item.subTask && <TaskProgress subTask={item.subTask} />}
    </div>
  )
}

