/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WebResult {
  code: string;
  msgKey: string;
  success: boolean;
}

export type Map = object;

export type WebResultTaskKolVO = WebResult & {
  obj?: KolVO[];
};

export type WebResultSocialTokenVO = WebResult & {
  obj?: SocialTokenVO;
};

export interface SocialTokenVO {
  access_token?: string;
  refresh_token?: string;
}

export type WebResultTaskTweetVO = WebResult & {
  obj?: string[];
};

export type WebResultTaskRewardVO = WebResult & {
  obj?: Award;
};

export type WebResultActiveTaskVO = WebResult & {
  obj?: Award;
};

export type WebResultDoTaskVO = WebResult & {
  obj?: DoTaskVO;
};

export interface KolVO {
  userName?: string;
  icon?: string;
  profileUrl?: string;
}

export interface DoTaskVO {
  accessToken?: string;
  refreshToken?: string;
  postUrl?: string;
}

export type WebResultTaskVO = WebResult & {
  obj?: TaskListResponse[];
};

export interface TaskListResponse {
  taskId?: string;
  groupId?: string;
  title?: string;
  desc?: string;
  image?: string;
  labelName?: string;
  labelColor?: string;
  url?: string;
  connectUrl?: string;
  dataUrl?: string;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  progress?: number;
  /** @format int32 */
  status?: number;
  domain?: string;
  /** 0:不显示 1:go 2:verify */
  btn?: number;
  /** 是否显示进度条 */
  showProgress?: boolean;
  link?: Map;
  code?: string;
  subTask?: TaskSubVO[];
}

export interface Award {
  /** 奖品描述 */
  desc?: string;
  /** NFT,TOKEN,POINT */
  type?: string;
  /** 奖励金额 */
  amount?: string;
  currency?: string;
  index?: number;
  /**
   * 1:已发放 0：未发放
   * @format int32
   */
  status?: number;
}

export type WebResultTaskDetailVO = WebResult & {
  obj?: TaskDetailVO;
};

export interface TaskDetailVO {
  taskId?: string;
  title?: string;
  desc?: string;
  login_url?: string;
  image?: Map;
  platform?: string;
  rewardType?: string;
  rewardWay?: string;
  subTasks?: TaskSubVO[];
  rewards?: Award[];
  labelName?: string;
  labelColor?: string;
  /** @format int64 */
  startTime?: number;
  /** @format int64 */
  endTime?: number;
  total?: number;
  process?: number;
  rewardStatus?: number;
}

export interface TaskSubVO {
  taskId?: string;
  title?: string;
  desc?: string;
  reward?: string;
  domain?: string;
  url?: string;
  image?: string;
  status?: number;
  platform?: string;
  code?: string;
  connectUrl?: string;
}

export type WebResultSeriesTaskVO = WebResult & {
  obj?: TaskCodeVO;
};

export interface TaskCodeVO {
  /** 是否签过到 */
  checkIn?: boolean;
  /** 任务id */
  taskId?: string;
  /** 任务标题 */
  title?: string;
  /** 任务描述 */
  desc?: string;
  nodes?: NodeVO[];
  process?: number;
  image?: string[];
}

export interface NodeVO {
  /** 1 */
  index?: number;
  /** 0:无奖品 1:普通奖品 */
  nodeLogo?: number;
}

export type WebResultPointLedgerList = WebResult & {
  obj?: PointLedgerList;
};

export interface PointLedgerList {
  /**
   * 用户id
   * @example "10023123123221111"
   */
  customerId: string;
  /**
   * 会员拥有的积分总数量
   * @example 100
   */
  point: number;
  /**
   * 今日获取的积分
   * @example 100
   */
  todayPoint?: number;
  /**
   * 平台总积分
   * @example 100
   */
  appPoint?: number;
  /** 排名 */
  rank?: string;
  /** 积分流水数据 */
  pointLedgers?: PointLedger[];
}

/** 积分流水数据 */
export interface PointLedger {
  /**
   * 积分流水ID
   * @example 90
   */
  amount?: number;
  /** 流水描述 ｜ ***国际化*** */
  descI18n?: string;
  created?: any;
  /**
   * 操作类型 (4)转入 (5)转出
   * @format int32
   * @example 4
   */
  operateType?: number;
  /**
   * 积分流水ID
   * @example "2020023232"
   */
  id?: string;
  /** @example "Post" */
  businessType?: string;
  /** menu */
  menu?: string;
}

export type WebResultCustomerBindVO = WebResult & {
  obj?: CustomerBindVO;
};

export interface CustomerBindVO {
  cid?: string;
  uid?: string;
  saasId?: string;
  /** 头像 */
  avatar?: string;
  jwt?: string;
  mugenJwt?: string;
  available?: number;
  twitterName?: string;
  pfp?: string;
  vip?: string;
  isVip?: boolean;
  rankPercent?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://api.quests.dev.dipbit.xyz";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key);
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Kweb API
 * @version 1.0
 * @license Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0)
 * @baseUrl http://api.quests.dev.dipbit.xyz
 *
 * The Kweb API v1.0
 */
export class Task<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  customer = {
    /**
     * @description 三方认证登录
     *
     * @tags CustomerV2
     * @name AuthToken
     * @summary get twitter/discord token
     * @request POST:/customer/oauth/social/token
     */
    authToken: (
      query: {
        /** twitter、discord、google、facebook */
        app: string;
        /** twitter、discord */
        platform: string;
        /** code(一次性) */
        code: string;
        /** redirectUri */
        redirectUri?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultSocialTokenVO, any>({
        path: `/customer/oauth/social/token`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  v2 = {
    /**
     * @description 三方认证登录
     *
     * @tags CustomerV2
     * @name Me
     * @summary get current time
     * @request GET:/v2/customer/me
     */
    me: (
      query: {
        /** businessType */
        businessType?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultCustomerBindVO, any>({
        path: `/v2/customer/me`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksListList
     * @summary 获取任务列表
     * @request GET:/v2/tasks/list
     */
    tasksListList: (
      query?: {
        /**
         * channel
         * @default "app"
         * @example "app、pc"
         */
        channel?: string;
        /**
         * home
         * @example "home"
         */
        position?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultTaskVO, any>({
        path: `/v2/tasks/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksDetailList
     * @summary 任务详情
     * @request GET:/v2/tasks/detail
     */
    tasksDetailList: (
      query?: {
        /** 任务id */
        taskId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultTaskDetailVO, any>({
        path: `/v2/tasks/detail`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksVerifyCreate
     * @summary 做任务
     * @request POST:/v2/tasks/verify
     */
    tasksVerifyCreate: (
      query: {
        /** 任务id */
        taskId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultDoTaskVO, any>({
        path: `/v2/tasks/verify`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksDotaskCreate
     * @summary 做任务
     * @request POST:/v2/tasks/dotask
     */
    tasksDotaskCreate: (
      query: {
        /** 任务id */
        taskId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultActiveTaskVO, any>({
        path: `/v2/tasks/dotask`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksDoquestsCreate
     * @summary 做任务
     * @request POST:/v2/tasks/doquests
     */
    tasksDoquestsCreate: (
      query: {
        /** 用户id、address */
        userId: string;
        /** 事件名称 */
        name: string;
        /** 当前事件对应的资源id */
        contentId: string;
        /** 当前事件对应的资源的原资源用户id */
        targetUserId?: string;
        /** 当前事件对应的资源的原资源id */
        targetContentId?: string;
        /** 当前事件对应的资源价钱 */
        price?: string;
        /**
         * 当前事件对应的资源价钱
         * @format int64
         */
        eventTime: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultTaskRewardVO, any>({
        path: `/v2/tasks/doquests`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksCodeDetail
     * @summary 根据code查询任务详情
     * @request GET:/v2/tasks/code/{code}
     */
    tasksCodeDetail: (code: string, params: RequestParams = {}) =>
      this.request<WebResultSeriesTaskVO, any>({
        path: `/v2/tasks/code/${code}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaskV2
     * @name TasksTwitterTweetsDetail
     * @summary 查询twitter最近的帖子
     * @request GET:/v2/tasks/twitter/tweets/{taskId}
     */
    tasksTwitterTweetsDetail: (taskId: string, params: RequestParams = {}) =>
      this.request<WebResultTaskTweetVO, any>({
        path: `/v2/tasks/twitter/tweets/${taskId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  member = {
    /**
     * @description 积分流水-已领取|已使用
     *
     * @tags MemberV2
     * @name PointLedgers
     * @summary 积分流水
     * @request GET:/member/points/ledgers/{type}
     */
    pointLedgers: (
      type: "transfer_in" | "transfer_out",
      query: {
        /**
         * 分页offset，默认0
         * @format int32
         * @default 0
         */
        offset: number;
        /**
         * 分页limit,默认10
         * @format int32
         * @default 10
         */
        limit: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<WebResultPointLedgerList, any>({
        path: `/member/points/ledgers/${type}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
