import React from 'react';
import { dayjs } from 'utils';

export interface HistoryItemDataProps {
  id: string;
  amount: number;
  created: string;
  descI18n: string;
}

export interface HistoryItemProps {
  itemData: HistoryItemDataProps;
}

export const HistoryItem = (props: HistoryItemProps) => {
  const { itemData } = props;

  const { descI18n, created, amount} = itemData;
  return (
    <div className='bg-[#121212] flex flex-col justify-between mb-3 p-3 h-[77px] rounded-2xl'>
      <div className='flex flex-col text-sm leading-[21px] font-SFProDisplay font-normal text-white'>{descI18n}</div>
      <div className='flex flex-row justify-between items-center'>
        <div className='text-xs leading-[18px] font-SFProDisplay font-normal text-[#A1A1A1]'>{dayjs(created).format("MMM DD, YYYY HH:mm:ss")}</div>
        <div>
          <span className='text-base leading-6 font-SFProDisplay font-bold text-[#FEFEFE]'>+ {amount} </span>
          <span className='text-xs leading-[18px] font-SFProDisplay font-normal text-[#FEFEFE]'>POINTS</span>
        </div>
      </div>
    </div>
  )
}