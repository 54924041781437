export async function getGuestId(): Promise<number> {
  let _guestId = await localStorage.getItem("guestId");

  if (!_guestId) {
    _guestId = Math.floor(
      Math.random() * (9223372036854775 - 1 + 1) + 1,
    ).toString();
    await localStorage.setItem("guestId", _guestId);
  }
  return Number(_guestId);
}
