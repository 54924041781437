import { cn } from "utils";

type IBoardEmptyProps = {
  image?: string;
  title?: string;
  titleClassName?: string;
  imgClassName?: string;
  className?: string;
  desc?: string;
}

export const  BoardEmpty = (props: IBoardEmptyProps) => {
  const { image, title, desc, titleClassName, className, imgClassName } = props;
  return (
    <div className={cn("flex flex-col h-dvh justify-center items-center", className)}>
      {image && <osp-image src={image} class={cn("w-[198px] mb-[26px]", imgClassName)} />}
      <p className={cn("text-[36px] text-[#FFF345] font-bold font-SFProDisplay text-center leading-[48px]", titleClassName)}>{title}</p>
      <p className="text-base font-SFProDisplay text-[#FEFEFE] text-center mt-[14px] max-w-[245px]">
        {desc}
      </p>
    </div>
  )
}