
import React, { useContext } from 'react';
import { useAuthToken } from '@/client';
import { TaskContext } from '@/utils/global';
import { useToast } from './useToast';
import { sendMessage } from 'utils';
import { MsgKeyMessage, envMap } from '@/consts';

const somonHost = envMap.somonHostUrl;
export const useSocial = () => {
  const { state: { userInfo } } = useContext(TaskContext);
  const Toast = useToast();
  // @ts-ignore
  const mutationAuthToken = useAuthToken();

  const toLink = (linkUrl: string) => {
    if (!linkUrl) {
      return;
    }
    const messageData = {
      action: 'navigate',
      router: linkUrl,
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const toConnectSocial = (connectUrl: string, platform: string) => {
    if (connectUrl) {
      let url = connectUrl;
      if (window?.ReactNativeWebView) {
        if (platform === 'discord') {
          const currentUrl = new URL(window.location.href);
          url = `${connectUrl}&redirect_uri=${currentUrl.origin}/auth`;
        } else {
          url = connectUrl + '&redirect_uri=somon://task';
        }
       
        toLink(url); 
      } else {
        url = `${connectUrl}&redirect_uri=${somonHost}/task`;
        const messageData = {
          action: 'auth',
          router: url,
        };
        const message = JSON.stringify(messageData);
        sendMessage(message);
      }
    }
  };

  const getAcessToken = (auth: { authCode: string, platform: string}, callback?: () => void) => {
    if (auth) {
      const url = new URL(window.location.href);
      let redirectUri = window?.ReactNativeWebView ? `somon://task` : `${somonHost}/task`;
      if (window?.ReactNativeWebView && auth.platform === 'discord') {
        redirectUri = `${url.origin}/auth`;
      }
      const params = {
        app: userInfo?.loginType ?? 'google',
        platform: auth.platform,
        code: auth.authCode,
        redirectUri
      }
      return new Promise<string>((resolve, reject) => {
        // @ts-ignore
        mutationAuthToken.mutate(params, {
          onSuccess: async (response) => {
            callback && callback();
            const responseData = response.data;
            // @ts-ignore
            const { success, code, obj, msgKey } = responseData;
            if (code === '7003') {
              Toast.show(MsgKeyMessage['auth.no.same.login']);
              return resolve('failed');
            }
            if (code === '7004') {
              const messageData = {
                action: 'popup',
                router: 'switch-twitter-account',
                data: msgKey
              };
              const message = JSON.stringify(messageData);
              sendMessage(message);
              return resolve('failed');
            }
            if (!success) {
              const message = MsgKeyMessage?.[msgKey] ?? 'Authorization failed. Please try again.';
              Toast.show(message);
              return resolve('failed');;
            };
            resolve('success');
          },
          onError: (error) => {
            callback && callback();
            Toast.show('system.error');
            console.error(error);
            resolve('failed');
          }
        });
      });
    }
  };

  return {
    getAcessToken,
    toLink,
    toConnectSocial
  }
}
