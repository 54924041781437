import { useEffect, useState } from 'react';

type CountUpPropsType = {
  start: number
  end: number
  duration: number
};

export const useCountUp = () => {
  let timer: any;
  const [nextValue, setNextValue] = useState();

  useEffect (() => {
    return () => {
      timer && clearInterval(timer);
    };
  },[]);

  const transition = (params: CountUpPropsType) => {
    const {start, end, duration} = params;
    let next = start;
    const p = (end - start)/duration;
    let startTime;
    function animate(timeStamp) {
      if (startTime == undefined) {
        startTime = timeStamp;
      }
      const distance = (timeStamp - startTime) * p;
      next += distance;
      if (next < end) {
        const newValue = next.toFixed(0) ?? 0;
        setNextValue(newValue); 
        startTime = timeStamp;
        requestAnimationFrame(animate);
      } else {
        const endValue = end.toFixed(0);
        setNextValue(endValue); 
      }
    }
    requestAnimationFrame(animate);
  };

  return {
    nextValue,
    transition
  };
};
