import React, { ReactNode } from 'react';
import { cn } from 'utils'

export type EmptyProps = {
  className?: string;
  emptyText?: ReactNode
}

export const Empty = ({
  className,
  emptyText
}: EmptyProps) => {
  return (
    <div
      className={cn('flex flex-row items-center justify-center text-[#6D6D6D] text-sm leading-[21px] font-SFProDisplay font-normal mt-[215px]', className)}
    >
      {emptyText || 'no data yet'}
    </div>
  )
}
