import { sendMessage } from 'utils';
import { envMap } from '@/consts'
import { getTwitterShareUrl, getSUrl } from 'short-link'

interface shareParamsProps {
  season: string;
  inviteCode: string;
  handle: string;
  avatar: string;
  mcount: number;
  earned: number;
  points: string;
  badge: number;
  pfp: number;
  topranked: string;
  ownedTribeSize?: string;
}

export const onShareSettle = async (shareParams: shareParamsProps, idToken: string, shareText?: string) => {
  const { inviteCode, handle, avatar, season, mcount, earned, points, badge, pfp, topranked } = shareParams;
  const landingPageUrl = envMap.landingUrl;

  const authorization = idToken || '';
  const shortLinkApiUrl = import.meta.env.VITE_PUBLIC_SHORT_LINK
  const link = `${landingPageUrl}/settlement`
  const params = {
    handle,
    avatar,
    season,
    mcount,
    earned,
    points,
    badge,
    pfp,
    topranked,
    inviteCode
  }
  const router = await getTwitterShareUrl({
    link,
    params,
    text: encodeURIComponent(shareText || ""),
    authorization,
    shortLinkApiUrl
  })


  const messageData = {
    action: 'navigate',
    router
  };

  const message = JSON.stringify(messageData);
  sendMessage(message);
}



export const onShareOptions = async (shareParams: shareParamsProps,idToken: string, shareText?: string) => {
  const landingPageUrl = envMap.landingUrl;
  const originInviteLink = `${landingPageUrl}/settlement`;
  const shortLinkApiUrl = import.meta.env.VITE_PUBLIC_SHORT_LINK;
  const authorization = idToken || '';
  const inviteLink = await getSUrl({
    authorization, 
    link: originInviteLink, 
    params: shareParams, 
    shortLinkApiUrl
  });
  const shareOptions = {
    message: `${shareText} ${inviteLink}`,
    title: "Join Somon", // IOS
    subject: shareText, // IOS
  };

  return {
    shareOptions,
    inviteLink
  };
}
