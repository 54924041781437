import React from 'react';

export const NewTag = ({ isShow } : {isShow: boolean}) => {
  if (!isShow) return null
  return (
    <div className="
      absolute top-0 right-0 z-[5]
      rounded rounded-br-lg rounded-tl-lg rounded-tr-lg w-[33px] h-[19px]
      flex flex-row justify-center items-center 
      bg-gradient-to-r from-Content-Accent1 to-Content-Accent2 
      text-[10px] font-SFProDisplay font-bold leading-[15px] text-black
    "
    >
      New
    </div>
  )
}