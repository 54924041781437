import { useQuery } from "@tanstack/react-query"
import { useContext } from "react"
import { TaskContext } from "../utils/global"

export const useCustomerMe = () => {
  const {taskClient, state: { userInfo }} = useContext(TaskContext)
  const {data = {}, refetch, isFetching} = useQuery({
    queryKey: ['/v2/customer/me', userInfo?.idToken],
    queryFn: async () => {
      const res = await taskClient.v2.me({
        businessType: "asset"
      })
      return res?.data?.obj
    },
    placeholderData: {},
  })
  return {
    data,
    refetch,
    isFetching
  }
}