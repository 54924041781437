import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { GetV2TasksDetailQueryResponse, GetV2TasksDetailQueryParams } from "../models/GetV2TasksDetail";
import { config } from "../config";

export const getTasksDetailQueryKey = (params?: GetV2TasksDetailQueryParams) =>     [{url:`/v2/tasks/detail`},...(params ? [params] : [])] as const;
export function getV2TasksDetailQueryOptions <TData = GetV2TasksDetailQueryResponse, TError = unknown>(params?: GetV2TasksDetailQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
  const queryKey = getTasksDetailQueryKey(params);

  return {
    queryKey,
    queryFn: () => {
      
      return client<TData, TError>({
        method: "get",
        url: `/v2/tasks/detail`,
        headers: { ...options.headers },
        params,
        ...options,
        baseURL: config.api.baseUrl
      }).then(res => {
        const data = res.data?.obj
        return data;
      });
    },
  };
}

/**
 * @summary 任务详情
 * @link /v2/tasks/detail
 */

export function useGetTasksDetail <TData = GetV2TasksDetailQueryResponse, TError = unknown>(params?: GetV2TasksDetailQueryParams, options: {
          query?: Partial<UseQueryOptions<TData, TError>>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getTasksDetailQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getV2TasksDetailQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions, queryKey
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
}

