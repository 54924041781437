import axios from 'axios'

import type { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { userLocalStore } from '@/utils/global';
import { envMap } from '@/consts'
import { NoBugClient } from "nobug-react-js";

declare const AXIOS_BASE: string;

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
  url?: string
  method: 'get' | 'put' | 'patch' | 'post' | 'delete'
  params?: unknown
  data?: TData
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
  signal?: AbortSignal
  headers?: AxiosRequestConfig['headers']
}
/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
  data: TData
  status: number
  statusText: string
  headers?: AxiosResponse['headers']
}

export const axiosInstance = axios.create({
  baseURL: typeof AXIOS_BASE !== 'undefined' ? AXIOS_BASE : undefined,
  timeout: 10000,
  // withCredentials: true,
  headers: {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    saas_id: envMap.saasId,
  }
});

export const axiosClient = async <TData, TError = unknown, TVariables = unknown>(config: RequestConfig<TVariables>): Promise<ResponseConfig<TData>> => {
  const userStr = userLocalStore.get() ?? '';
  const _config = {
    headers: {
      idToken: JSON.parse(userStr)?.idToken
    }
  }
 // Add a response interceptor
  axiosInstance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if(response.status !== 200) {
        NoBugClient.notify(`[API Error]-[${JSON.stringify(response?.data)}]-${config?.url || ""}`, "request");
      }
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      NoBugClient.notify(`[API Error]-[catch error]-[${error}]-${config?.url || ""}`, "request");
      return Promise.reject(error);
    });


  
  const promise = axiosInstance.request<TVariables, ResponseConfig<TData>>({ ...config, ..._config }).catch((e: AxiosError<TError>) => {
    throw e
  })

  return promise
}

export default axiosClient
