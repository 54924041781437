import { type ReactNode } from 'react';
import { cn } from 'utils';

type IDetailCardProps = {
  title: string;
  count?: string;
  icon?: string;
  footer?: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
  iconClassName?: string;
  countClassName?: string;
}

export const DetailCard = (props: IDetailCardProps) => {
  const { title, count, icon, footer, containerClassName, contentClassName, iconClassName, countClassName } = props;
  return (
    <div className={cn("bg-gradient-to-br from-[#8C8625]/50 to-[#486B1E]/50 p-[2px] rounded-2xl mb-[14px]", containerClassName)}>
      <div className={cn("bg-Backgroun-Tertiary rounded-2xl h-full p-4 flex flex-col", contentClassName)}>
        <div className="flex justify-between items-center flex-1">
          <div className="text-Content-Primary text-xl font-SFProDisplay font-bold">{title}</div>
          <div className="flex items-center">
            {icon && <osp-image src={icon} class={cn("w-5 h-5 mr-[6px]", iconClassName)} />}
            {count && <div className={cn("text-Content-Accent2 text-4xl font-Oswald", countClassName)}>{count}</div>}
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}