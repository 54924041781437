// @ts-nocheck
import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { ResponseConfig } from "../axiosHooks";
import type { AuthTokenMutationResponse, AuthTokenQueryParams, AuthTokenHeaderParams } from "../../models/AuthToken";
import { config } from "../config";

export function authTokenMutationOptions <TData = AuthTokenMutationResponse, TError = unknown>(params: AuthTokenQueryParams, headers?: AuthTokenHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = authTokenQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
     
      return client<TData, TError, void>({
        method: "post",
        url: `${config.api.customer.accessToken}`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: config.api.baseUrl
      });
    },
  };
};
      
/**
 * @description 三方认证登录
 * @summary get twitter/discord token
 * @link /customer/oauth/social/token
 */

export function useAuthToken <TData = AuthTokenMutationResponse, TError = unknown>(params: AuthTokenQueryParams, headers?: AuthTokenHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...authTokenMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
};
