import { envMap } from "@/consts"

// decodeURIComponent解析%会报错
function decodeURIComponentSafe(s:string) {
  if (!s) {
      return s;
  }
  return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
}

export const handleOpenLink = (linkUrl: string, inviteCode: string) => {
  const somonHost = envMap.somonHostUrl
  const inviteLink = ` ${somonHost}?inviteCode=${inviteCode} `

  // 判断是否包含text属性，如果包含，则进行替换，否则直接采用原链接地址
  const url = new URL(linkUrl);
  const urlText = url.searchParams.get("text") || "";
  if (!urlText) {
    return linkUrl
  }

  const text = decodeURIComponentSafe(urlText).replace("${link}", inviteLink);
  const formatLink = `${url.origin}${url.pathname}?text=${encodeURIComponent(text)}`;
  return formatLink
}
