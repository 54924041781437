import { useQuery } from "@tanstack/react-query";
import { useToast } from '@/hooks';
import { useClientProvider } from "client";

export const useGetBadgeType = () => {
  const { customerApi } = useClientProvider()
  const Toast = useToast()

  const { data } = useQuery({
    queryKey: ['GET_BADGE_TYPE'],
    async queryFn() {
      const { data, error } = await customerApi.badges.badgeType();
      if (error) {
        Toast.show("System error");
      }
      return data?.obj?.type || [];
    },
  });

  return {
    data
  };
};
