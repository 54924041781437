
export const ROUTER_PATH = {
  TASK_CENTER: '/task-center',
  POINTS_HISTORY: '/points-history',
  TWITTER_FEED: '/twitter-feed',
  LEADER_BOARD: '/leader-board',
  MY_BADGES: '/my-badges',
  CLAIM_BADGES: '/claim-badges',
  AUTH: '/auth',
  ACHIEVEMENT: '/achievement',
  LEADER_BOARD_HISTORY: '/leader-board/history',
  OWO_SWAP:'/owo_swap',
  OWO_SWAP_SUCCESS: '/owo_swap_success'
};
