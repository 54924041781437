// @ts-nocheck
// @ts-ignore
/* eslint-disable */
// http 请求封装
interface HttpClientProps {
  baseURL?: string;
  headers?: any;
}

interface FetchOptions {
  parseResponse?: boolean;
  method?: string;
  body?: object | string;
}

class HttpClient {
  _baseURL: string;
  _headers?: any;

  constructor(options: HttpClientProps = {}) {
    this._baseURL = options.baseURL || "";
    this._headers = options.headers || {};
  }

  async _fetchRawRes(endpoint, options = {}) {
    const res = await fetch(this._baseURL + endpoint, {
      ...options,
      headers: this._headers,
    });

    if (!res.ok) throw new Error(res.statusText);
    return res;
  }

  async _fetchJSON(endpoint, options: FetchOptions = {}) {
    const res = await this._fetchRawRes(endpoint, options);

    if (options.parseResponse !== false && res.status !== 204)
      return res.json();

    return undefined;
  }
  async _fetchText(endpoint, options: FetchOptions = {}) {
    const res = await this._fetchRawRes(endpoint, options);

    if (options.parseResponse !== false && res.status !== 204)
      return res.text();

    return undefined;
  }
  setHeader(key, value) {
    this._headers[key] = value;
    return this;
  }

  getHeader(key) {
    return this._headers[key];
  }

  setBasicAuth(username, password) {
    this._headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
    return this;
  }

  setBearerAuth(token) {
    this._headers.Authorization = `Bearer ${token}`;
    return this;
  }

  setJwtAuth(token) {
    this._headers.Authorization = `${token}`;
    return this;
  }

  bodyStringify(body) {
    return body ? JSON.stringify(body) : undefined;
  }

  get(endpoint, options = {}, resType = "json") {
    return this[resType === "json" ? "_fetchJSON" : "_fetchText"](endpoint, {
      ...options,
      method: "GET",
    });
  }

  post(endpoint, body, options = {}, resType = "json") {
    return this[resType === "json" ? "_fetchJSON" : "_fetchText"](endpoint, {
      ...options,
      body: this.bodyStringify(body),
      method: "POST",
    });
  }

  put(endpoint, body, options = {}, resType = "json") {
    return this[resType === "json" ? "_fetchJSON" : "_fetchText"](endpoint, {
      ...options,
      body: this.bodyStringify(body),
      method: "PUT",
    });
  }

  patch(endpoint, operations, options = {}, resType = "json") {
    return this[resType === "json" ? "_fetchJSON" : "_fetchText"](endpoint, {
      parseResponse: false,
      ...options,
      body: JSON.stringify(operations),
      method: "PATCH",
    });
  }

  delete(endpoint, options = {}, resType = "json") {
    return this[resType === "json" ? "_fetchJSON" : "_fetchText"](endpoint, {
      parseResponse: false,
      ...options,
      method: "DELETE",
    });
  }
}

export { HttpClient };
