import type { QueryKey, UseQueryResult, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { GetV2TasksProgressQueryResponse, GetV2TasksProgressQueryParams } from "../models/GetV2TasksProgress";
import { config } from "../config";

export const getTasksProgressQueryKey = (params: GetV2TasksProgressQueryParams) =>     [{url: `/v2/tasks/early/pop`},...(params ? [params] : [])] as const;
export function getV2TasksProgressQueryOptions <TData = GetV2TasksProgressQueryResponse, TError = unknown>(params: GetV2TasksProgressQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
  const queryKey = getTasksProgressQueryKey(params);

  return {
    queryKey,
    queryFn: () => {
      
      return client<TData, TError>({
        method: "get",
        url: `/v2/tasks/early/pop`,
        headers: { ...options.headers },
        ...options,
        baseURL: config.api.baseUrl
      }).then(res => {
        const result = res.data?.obj;
        console.log('result', result)
        return result;
      });
    },
  };
}

/**
 * @link /v2/tasks/early/pop
 */

export function useGetTasksProgress <TData = GetV2TasksProgressQueryResponse, TError = unknown>(params: GetV2TasksProgressQueryParams, options: {
          query?: Partial<UseQueryOptions<TData, TError>>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getTasksProgressQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getV2TasksProgressQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions, queryKey
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
}


