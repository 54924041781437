
import { useQuery } from '@tanstack/react-query';
import { useClientProvider } from 'client';
import { toDecimal } from 'utils'
import { ethers } from "ethers";

export const chgType = {
  UP: "UP",
  DOWN: "DOWN",
}
type IGetTransUSDInfoProps = {
  symbol: string;
  balance: string;
  priceList?: any;
}
// 获取币种 涨跌幅 和 余额转USD换算
export const getTransUSDInfo = ({ symbol: curSymbol, balance, priceList }: IGetTransUSDInfoProps) => {
  for (const item of priceList) {
    const { symbol, latest, change, quote } = item;
    const formatChange = toDecimal(change, 2);
    if(curSymbol === symbol && quote === "USD") {
      const transBalance = Number(latest) * Number(balance);
      return {
        transBalance: toDecimal(transBalance, 2),
        status: Number(formatChange) < 0 ? chgType.DOWN : chgType.UP,
        change: `${Number(formatChange) === 0 ? '0.00' : formatChange}%`
      }
    }
  }
}

export const getTokenList = async (client: any): Promise<any> => {
  // 获取token配置
  const { data, error } = await client.configs.listChains();
  if(error) {
    return { error };
  }
  const listPriceRes = await client.configs.listPrices();
  const tokenList = [] as any;
  for (const item of data?.rows) {
    const { supportedTokens, icon, name, explorerUrl } = item;
    for (const tokenItem of supportedTokens) {
      if(!tokenItem.supportedAccountType.includes("osp")) break;
      const { address, decimals, symbol } = tokenItem;
      // 获取token余额信息
      const balance = await client.asset.getBalance(address);
      const formatBalance = (balance?.data as any)?.hex ? ethers.utils.formatUnits((balance?.data as any)?.hex, decimals) : '0';
      const decimalBalance = toDecimal(formatBalance, 8) as string;
      const usdData = getTransUSDInfo({ symbol, balance: decimalBalance, priceList: listPriceRes?.data?.rows || [] }) || {};
      tokenList.push({
        ...tokenItem,
        ...usdData,
        balance: decimalBalance,
        chainIcon: icon,
        chainName: name,
        explorerUrl,
      })
    }
  }
  console.log('line57', tokenList);
  return {
    data: tokenList
  };
}

export const useTokenList = () => {
  const { ospClient, isOspInitDone } = useClientProvider();
  const { data: tokenList } = useQuery({
    queryKey: ['GET_CONFIGS_CHAINS'],
    enabled: isOspInitDone,
    queryFn: async () => {
      const { data, error } = await getTokenList(ospClient);
      return data;
    },
  });

  return {
    tokenList: tokenList || []
  }
}