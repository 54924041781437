import { useEffect, useState } from 'react';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { startNoBug } from "nobug-react-js"

import { BrowserRouter, Routes, Route } from "react-router-dom";
import TaskCenter from "./pages/TaskCenter";
import PointsHistory from "./pages/PointsHistory";
import TwitterFeed from "./pages/TwitterFeed";
import LeaderBoard from "./pages/LeaderBoard";
import MyBadges from './pages/MyBadges';
import LeaderBoardHistory from './pages/LeaderBoardHistory';
import Auth from './pages/Auth';
import Achievement from './pages/Achievement';
import { ROUTER_PATH } from './consts';
import './App.css';
import { TaskContext, useGlobalData, IActionEum, userLocalStore } from './utils/global'
import { sendMessage, setPostOrigin } from "utils";
import { ClientProvider,Environment } from 'client'
import { envMap } from './consts'
import { initFirebase } from 'firebase';
import { getErrorBoundary } from 'bugsnag';
import { Task } from 'api';
import { config } from './client/config';
import ClaimBadges from './pages/ClaimBadges';
import OwOSwap from './pages/OwOSwap';
import OwOSwapSuccess from './pages/OwOSwapSuccess';


window.osp.ipfsGateway = import.meta.env.VITE_PUBLIC_IPFS_GATEWAY;

declare const AXIOS_BASE: string;

setPostOrigin(envMap.somonHostUrl);

startNoBug({
  appName: 'task',
  blackList:[
    "image Error",
    "Failed to login with openlogin",
    "Failed to fetch",
    "Already connected",
    "Already connecting",
    "API Timeout",
    "timeout",
    "Request expired. Please try again",
    "ShortUrl",
    "FirebaseError",
    "Notification",
    "{}",
    "Network Error",
    "Unexpected EOF",
  ]
})



const ErrorBoundary = getErrorBoundary('53bf65979e6ad0be1e5d825ed26cdc93',import.meta.env.VITE_PUBLIC_ENV)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  }
})
const somonHost = envMap.somonHostUrl;

const getTaskApi = () => new Task({
  baseUrl: config.api.baseUrl,
  baseApiParams: { secure: true,headers:{
    "saas_id": envMap.saasId || "monster",
  }},
  securityWorker: (data: any) => {
    data = data || {}
    const userStr = userLocalStore.get() ?? '';
    console.log("userStr:", JSON.parse(userStr));
    data.headers = {
      idToken: JSON.parse(userStr)?.idToken,
      Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    }
    return data;
  },
})

const taskClient = getTaskApi()


function App () {
  const { state, dispatch } = useGlobalData();
  const { userInfo } = state;
  const [isReady, setIsReady] = useState(false);
  const [isFirebaseReady, setIsFirebaseReady] = useState(false);
  const [isPC, setIsPC] = useState(false);
  const init = async () => {
    // @ts-ignore
    await initFirebase(import.meta.env.VITE_PUBLIC_ENV as Environment);
    setIsFirebaseReady(true);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    async function handleEvent (e: { origin: string; data: any; }) {
      console.log('RN meaasage', e)
      if(window?.ReactNativeWebView) {
        if(e?.data?.initData) {
          console.log('RN meaasage lalalala', e)          
          saveData(e?.data?.initData);
        }
      }
      if (e.origin === somonHost && typeof e.data === "string") {
        try {
          JSON.parse(e.data);
        }catch(err) {
          eval(e.data?.replace('window.ethereum',"window.ethereum_osp = window.ethereum"));
          saveData(window?.userInfo);
        }
      }
    };

    if (window?.ReactNativeWebView) {
      saveData(window?.userInfo);
    } else {
      const messageData = {
        action: 'post',
        messageType: 'initData'
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
    }
    window.addEventListener("message", handleEvent);
    return () =>
      window.removeEventListener("message", handleEvent, false);
  }, []);

  const saveData = (initUserInfo: any) => {
    let userData = userInfo;
    if (!initUserInfo) return
    if (userData && Object.keys(userData).length === 0) {
      const userStr = userLocalStore.get() ?? '';
      try {
        userData = userStr.length ? JSON.parse(userStr) : undefined;
      } catch (error) {
        userData = undefined
      }
    }
    setIsPC(initUserInfo?.isPC)
    userData = {
      ...userData,
      ...initUserInfo
    }
    localStorage.setItem('sdk-token', userData?.accessToken || '');
    localStorage.setItem('somon-jwt', userData?.somonToken ||'');
    setIsReady(true);
    dispatch({
      type: IActionEum.SET_USERINFO,
      payload: {
        ...userData,
      }
    });
  }

  return (
    <ErrorBoundary>
      <TaskContext.Provider
        value={{
          state,
          dispatch,
          taskClient,
          isFirebaseReady
        }}
      >
        <ClientProvider
          ospEnv={import.meta.env.VITE_PUBLIC_ENV as Environment}
          somonEnv={envMap.customerApiUrl}
          appId={envMap.appId}
          saasId={envMap.saasId}
          isReady={isReady}
          isPC={isPC}
        >
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                <Route path={ROUTER_PATH.TASK_CENTER} element={<TaskCenter />} />
                <Route path={ROUTER_PATH.POINTS_HISTORY} element={<PointsHistory />} />
                <Route path={`${ROUTER_PATH.TWITTER_FEED}/:taskId`} element={<TwitterFeed />} />
                <Route path={ROUTER_PATH.LEADER_BOARD} element={<LeaderBoard />} />
                <Route path={ROUTER_PATH.MY_BADGES} element={<MyBadges />} />
                <Route path={ROUTER_PATH.CLAIM_BADGES} element={<ClaimBadges />} />
                <Route path={ROUTER_PATH.OWO_SWAP} element={<OwOSwap />} />
                <Route path={ROUTER_PATH.OWO_SWAP_SUCCESS} element={<OwOSwapSuccess />} />
                <Route path={ROUTER_PATH.AUTH} element={<Auth />} />
                <Route path={ROUTER_PATH.ACHIEVEMENT} element={<Achievement />} />
                <Route path={`${ROUTER_PATH.LEADER_BOARD_HISTORY}`} element={<LeaderBoardHistory />} />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </ClientProvider>
      </TaskContext.Provider>
    </ErrorBoundary>
  )
}

export default App
