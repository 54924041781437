import React, { useState } from 'react';
import { Popup } from 'react-vant';
// @ts-ignore
import CloseIcon from "/src/assets/close.svg?react";
import { EarlyBirdItem } from './EarlyBirdItem';
import { useGetTasksDetail } from '@/client';
import { useClientProvider } from 'client';
import { cn } from 'utils';
import { logEventAnalytics } from "firebase";

type PropsType = {
  earlyBirdVisible: boolean,
  isVerify: boolean,
  taskId: string,
  inviteCode?: string,
}

export const EarlyBird = (props: PropsType) => {
  const { earlyBirdVisible, isVerify, taskId, inviteCode } = props;
  const { data: taskDetail, refetch: refetchDetail } = useGetTasksDetail({ taskId: taskId });
  const [visible, setVisible] = useState(earlyBirdVisible);
  const { isPC } = useClientProvider();

  const refresh = () => {
    setTimeout(() => {
      refetchDetail();
    }, 2000);
  };

  const onClose = () => {
    logEventAnalytics("click_weeklybadge_close");
    setVisible(false);
  };

  return (
    <Popup className="overflow-x-hidden max-w-[880px]" visible={visible} position={isPC ? 'center' : 'bottom'} style={!isPC ? { height: '100%' } : { borderRadius: '16px' }} onClose={onClose}>
      <div className={cn('h-fit relative pb-[140px] overflow-hidden', {
        'w-[408px] py-6 px-2 rounded-2xl bg-Backgroun-Tertiary': isPC
      })}>
        <div 
          className={cn("fixed top-[70px]  rounded-full flex flex-col justify-center items-center w-[46px] h-[46px] z-10", {
            'left-4 bg-[#FEFEFE]/20 backdrop-blur-[100px]': !isPC,
            'right-2 top-2': isPC,
          })}
          onClick={onClose}
        >
          <CloseIcon className='text-[#FEFEFE]' />
        </div>
        <div className="px-4 pt-[70px] pb-4 flex flex-row items-center w-full h-[132px] relative">
          <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/early-bg.svg`} class="fixed w-[158px] h-[89px] left-0 top-0" />
          <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/early-bird-monster.svg`} class="fixed w-[122px] h-[122px] right-[-20px] top-[41px]" />
        </div>
        <div className="w-full h-fit px-4">
          <div className="flex flex-col w-full h-fit">
            <span className="inline text-left align-top text-[44px] font-Oswald font-bold tracking-[-1.76px] leading-[53px] text-[#FEFEFE]">
              {`WEEKLY BADGE `}
              <span className='text-[#FFF345]'>
                EXTRAVAGANZA
              </span>
            </span>
            <span className="text-left align-top text-base font-SFProDisplay font-normal text-[#C6C6C6] mt-2">
              To earn the following badges, tweet on X, click "Verify," and claim them in "My Badge."
            </span>
          </div>
          {taskDetail?.subTasks?.map((item, index) => {
            return (
              <EarlyBirdItem
                key={`${index}_${item.taskId}`}
                item={item}       
                refreshData={refresh}
                isVerify={isVerify}
                inviteCode={inviteCode}
              />
            )
          })}
        </div>
      </div>
    </Popup>
  )
}