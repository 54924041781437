import { dayjs } from './dayjs';

interface time {
  week: number;
  hour: number;
  minute: number;
  second: number;
}

interface currentProps {
  startTime: time;
  endTime: time;
}

export const IsBetweenCurrent = (props: currentProps) => {
  const {startTime:start ,endTime: end } = props;
  const startTime = dayjs().utc().set('day', start.week).set('hour', start.hour).set('minute', start.minute).set('second', start.second).valueOf();
  const endTime = dayjs().utc().set('day', end.week).set('hour', end.hour).set('minute', end.minute).set('second', end.second).valueOf();
  const currentTime = dayjs().utc().valueOf();
  return currentTime >= startTime && currentTime <= endTime;
}