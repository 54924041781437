// @ts-nocheck
import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { ResponseConfig } from "../axiosHooks";
import type { PostV2TasksDotaskMutationResponse, PostV2TasksDotaskQueryParams } from "../../models/PostV2TasksDotask";
import { config } from "../config";

export function postTasksDotaskMutationOptions <TData = PostV2TasksDotaskMutationResponse, TError = unknown>(params: PostV2TasksDotaskQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = postV2TasksDotaskQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `${config.api.task.doTask}`,
        headers: { ...options.headers },
        params,
        ...options,
        baseURL: config.api.baseUrl
      });
    },
  };
}

/**
 * @summary 做任务
 * @link /v2/tasks/dotask
 */

export function usePostTasksDotask <TData = PostV2TasksDotaskMutationResponse, TError = unknown>(params: PostV2TasksDotaskQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...postTasksDotaskMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
