import { useQuery } from "@tanstack/react-query";
import { useToast } from '@/hooks';
import { useClientProvider } from "client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { transform } from "osp-proxy";
import { queryKeyMap } from "@/consts";
import { TaskContext } from "@/utils/global";
export const useSeasonConfig = () => {
  const Toast = useToast();
  const { state: { userInfo } } = useContext(TaskContext);
  const { customerApi } = useClientProvider()
  const [currentSeasonId, setCurrentSeasonId] = useState(2);
  
  const { data: seasonConfig, isFetching } = useQuery({
    queryKey: [queryKeyMap.GET_SEASON_CONFIG, userInfo?.idToken],
    enabled: !!userInfo?.idToken,
    async queryFn() {
      const {data, error} = await customerApi.seasons.seasons();
      if (error) {
        Toast.show("System error");
      }
      return transform(data?.obj || []) || [];
    },
    select(data) {
      const config = data && data.length > 1 ? data?.sort((a: any, b: any) => b.id - a.id) : data;
      return config;
    },
  });

  useEffect(() => {
    const currentSeason = seasonConfig?.find((item:any) => item.current);
    setCurrentSeasonId(currentSeason?.id || 0);
  }, [seasonConfig])

  const getSeasonInfo = useCallback((id: number) => {
    const seasonInfo = seasonConfig?.find((item:any) => id === item.id);
    return seasonInfo || {};
  }, [seasonConfig]);

  const isCalculating = useMemo(() => {
    const currentSeason = getSeasonInfo(currentSeasonId);
    if(!!seasonConfig?.length && !!currentSeason?.id) {
      return currentSeason?.previousSettleStatus === 1;
    }
    return true;
  }, [seasonConfig, currentSeasonId]);

  return {
    seasonConfig,
    currentSeasonId,
    getSeasonInfo,
    isFetching,
    isCalculating,
  }
}