"use client"
import React from "react";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'


export { Bugsnag };
export const getErrorBoundary = (apiKey: string,releaseStage: string)=>{
  if(process.env.NODE_ENV !== 'development'){
    Bugsnag.start({
      apiKey,
      plugins: [new BugsnagPluginReact()],
      releaseStage
    })
    BugsnagPerformance.start({ apiKey })
    const bugsnagReactPlugin = Bugsnag.getPlugin('react')
    const Error = bugsnagReactPlugin?.createErrorBoundary(React) || React.Fragment
    return Error
  }
  return Empty
}

const Empty=({ children }:{children: React.ReactNode})=>{
  return (
    <>
      {children}
    </>
  )
}
