import { ClaimedItem } from './ClaimedItem'
import { Empty } from '../Empty';
import { ClaimedSkeleton } from './ClaimedSkeleton'
import { useGetClaimedList } from './hooks'
import { BadgeDetailsProps } from './type'

export const ClaimedList = () => {
  
  const { claimedList, isEmpty, isFetching } = useGetClaimedList()

  if (isFetching) {
    return <ClaimedSkeleton />
  }

  if (isEmpty) {
    return <Empty emptyText='No claimed badges'/>
  }
 
  return (

      <div className='px-4 flex flex-col gap-8 pb-4'>
        {claimedList.map(groupItem => {
          if (groupItem.dataList?.length === 0) return null
          return (
            <div  key={groupItem.title}>
              <div className='text-base font-SFProDisplay font-bold leading-6 text-[#fdfdfd] mb-6'>
                {groupItem.title}
              </div>
              <div className='grid grid-cols-3 gap-x-[30.5px] gap-y-6'>
                {
                  groupItem.dataList.map((item: BadgeDetailsProps, index: number) => {
                    return (
                      <ClaimedItem
                        key={`${index}-${item.id}`}
                        itemData={item}
                        isNew={index === 0}
                      />
                  )})
                }
              </div>
            </div>
          )
        })}
      </div>
  )
}