import { useContext, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Cell, Input, hooks } from 'react-vant';
import { BadgeDetailsProps } from './type'
import { Button } from 'react-vant';
import { UnclaimedItem } from './UnClaimedItem';
import { UnclaimedSkeleton } from './UnClaimedSkeleton';
import { Empty } from '../Empty';
import { useSwapOwO, useRefState } from './hooks';

import { useQueryClient } from "@tanstack/react-query";
import { getLatestBadgeInSelected } from '../MyBadges/utils'
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '../../consts';
import { getValue, logEventAnalytics } from "firebase";
// @ts-ignore
import Gas from "/src/assets/currency-coin-dollar.svg?react";
// @ts-ignore
import BaseChain from "/src/assets/base.svg?react";
// @ts-ignore
import OwOCoin from "/src/assets/coin_owo.svg?react";

// @ts-ignore
import ETHCoin from "/src/assets/eth.svg?react";
// @ts-ignore
import WalletIcon from "/src/assets/wallet.svg?react";
// @ts-ignore
import RefreshIcon from "/src/assets/redo.svg?react";
// @ts-ignore
import UpIcon from "/src/assets/angle-up.svg?react";
// @ts-ignore
import DownIcon from "/src/assets/angle-down.svg?react";
// @ts-ignore
import ExchangeIcon from "/src/assets/exchange.svg?react";
// @ts-ignore
import Spinner from "/src/assets/spinner.svg?react";

import { ConfirmModal } from './ConfirmModal'
import { TaskContext } from '@/utils/global';
import { cn, sendMessage } from 'utils';
import { toFixedTrunc, isValidNum, formatNum, limitFixedNum , safeAmount , trimEndZero} from './util'
import { Bugsnag } from 'bugsnag';
// const AAaddress = '0x034156F6957Ba2B5130eb28b615DFB1032d5ed6a'
// const gasAddress = '0x7FB5980e38187F0FEAEEc9786D78dCA046EE7Cde'
// const gasOwOToETHFee = 2000
// const gasETHToOwOFee = 500
// const maxSlippage = 500
// const minOwOSwapNum = 10
// const defaultConfig = {
//   "gasAddress": "0x7FB5980e38187F0FEAEEc9786D78dCA046EE7Cde",
//   "gasOwOToETHFee": 2000,
//   "gasETHToOwOFee": 500,
//   "maxSlippage": 500,
//   "minOwOSwapNum": 10
// }




const useRefStateForm = (initialValue ) => {
  const [state, setState] = hooks.useSetState(initialValue)
  const stateRef = useRef(state)
  useEffect(
    () => { stateRef.current = state },
    [state]
  )
  return [state, stateRef, setState]
}


export const SwapForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state: { userInfo }, isFirebaseReady } = useContext(TaskContext);
  console.log('userInfo', userInfo)
  // userInfo.ospAddress = '0x2c73a17d99d8535c2b9494b2a720526aaddf6027'

  const onGasEstimateSuccess = (gasData, IUserOps) => {
    setGasData(gasData)
    setIUserOps(IUserOps)
  }

  const {swapper, TransPath, swap, isSwaping, setIsSwaping, swapOwOConfig, swapOwOConfigRef, calGasFee } = useSwapOwO({
    onGasEstimateSuccess
  })
  const [isConfirming,setIsConfirming]= useState(false)
  const [isUp, setIsUp] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isSellOwO, isSellOwORef, setIsSellOwO] = useRefState(true)
  const [isCurrentFromInput, setIsCurrentFromInput] = useState(false)
  const [isCurrentToInput, setIsCurrentToInput] = useState(false)
  const [isConfirmVisble, setIsConfirmVisble] = useState(false)
  const [gasData, gasDataRef, setGasData] = useRefState('')
  const [IUserOps, IUserOpsRef, setIUserOps] = useRefState(null)

  const [keywordHeight, keywordHeightRef, setKeywordHeight ] = useRefState(0)



  const [oneOwOSwapData, oneOwOSwapDataRef,  setOneOwOSwapData] = useRefState({
    balance0: 0, // eth
    balance1: 0, // owo
    owoPerETH: 0,
    usdPerETH: 0,
    swapAmountOutMin: 0,
    amountOutWithPortion: 0
  })
 
   const [oneETHSwapData, oneETHSwapDataRef, setOneETHSwapData] = useRefState({
    balance0: 0, // eth
    balance1: 0, // owo
    owoPerETH: 0,
    usdPerETH: 0,
    swapAmountOutMin: 0,
    amountOutWithPortion: 0
  })


  const [formState, formStateRef, updateFormState] = useRefStateForm({
    fromAmount: '',
    toAmount: '',
  })



  const fromUSDPrice = useMemo(() => {
    let _price
    if(isSellOwO){
      if(isValidNum(formState.fromAmount)){
        _price = formState.fromAmount * oneOwOSwapData.amountOut * oneOwOSwapData.usdPerETH
      } else {
        _price = 0
      }
    } else {
      if(isValidNum(formState.fromAmount)){
        _price = formState.fromAmount * oneOwOSwapData.usdPerETH
      } else {
        _price = 0
      }
    }
    return _price;
  }, [formState.fromAmount, isSellOwO, oneOwOSwapData.amountOut, oneOwOSwapData.usdPerETH]);


  const toUSDPrice = useMemo(() => {
    let _price
    if(isSellOwO){
      if(isValidNum(formState.toAmount)){
        _price = formState.toAmount * oneOwOSwapData.usdPerETH
      } else {
        _price = 0
      }
    } else {
      if(isValidNum(formState.toAmount)){
        _price = formState.toAmount * oneOwOSwapData.amountOut * oneOwOSwapData.usdPerETH
      } else {
        _price = 0
      }
    }
    return _price;
  }, [formState.toAmount, isSellOwO, oneOwOSwapData.amountOutWithPortion, oneETHSwapData.amountOutWithPortion, oneOwOSwapData.usdPerETH]);

  const isBalanceOK = useMemo(() => {
    let isOK = false
    if(isSellOwO){
      if(isValidNum(formState.fromAmount)){
        isOK = !(parseFloat(formState.fromAmount) > oneOwOSwapData.balance1)
      }
    } else {
      if(isValidNum(formState.fromAmount)){
        isOK = !(parseFloat(formState.fromAmount) > oneOwOSwapData.balance0)
      }
    }
    return isOK;
  }, [formState.fromAmount, isSellOwO, oneOwOSwapData.balance0, oneOwOSwapData.balance1]);

  const isValidOwONum = useMemo(() => {
    if (!isValidNum(formState.fromAmount)) return true

    let isOK = false
    if(isSellOwO){
      if(isValidNum(formState.fromAmount)){
        isOK = !(parseFloat(formState.fromAmount) < swapOwOConfig.minOwOSwapNum)
      }
    } else {
      if(isValidNum(formState.fromAmount)){
        isOK = !(parseFloat(formState.fromAmount) < swapOwOConfig.minETHSwapNum)
      }
    }
    return isOK;
  }, [isCurrentFromInput, formState.fromAmount, formState.toAmount, isSellOwO, swapOwOConfig.minOwOSwapNum]);


  const getToAmount = (fromAmount, fromIsOwO) => {
    let toAmount = ''
    if(fromIsOwO){
      if(isValidNum(fromAmount)){
        toAmount = fromAmount * oneOwOSwapData.amountOutWithPortion 
      }
    } else {
      if(isValidNum(fromAmount)){
        toAmount = fromAmount * oneETHSwapData.amountOutWithPortion
      }
    }

    return safeAmount(toAmount);
  }

  const getFromAmount = (toAmount, fromIsOwO) => {
    let fromAmount = ''
    if(fromIsOwO){
      if(isValidNum(toAmount)){
        fromAmount = toAmount * oneETHSwapData.amountOutWithPortion
      }
    } else {
      if(isValidNum(toAmount)){
        fromAmount = toAmount * oneOwOSwapData.amountOutWithPortion
      }
    }
    return safeAmount(fromAmount);


  }


  const handleSelect = (id: string) => {
    const newValue = selectedBadge.includes(id) ? selectedBadge.filter(el => el !== id) : [...selectedBadge, id]
    setSelectedBadge(newValue);
  }



  const goWallet = () => {
    const messageData = {
      action: 'back',
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const getLatestAllData = async (transPath, amountIn, AAaddress)=>{
    const portionBips = isSellOwO ? swapOwOConfig.gasOwOToETHFee :  swapOwOConfig.gasETHToOwOFee
    let allData = await swapper.priceDetect(transPath, AAaddress, amountIn, portionBips);
    return allData

  }
  const setOneOwOData = async ({AAaddress})=>{
    const data1 = await getLatestAllData(TransPath.OWO_TO_ETH, 1, AAaddress)
    setOneOwOSwapData(data1)
    console.log('data1', data1)
    return data1

  }

  const setOneETHData = async ({AAaddress})=>{
    const data2 = await getLatestAllData(TransPath.ETH_TO_OWO, 1, AAaddress)
    console.log('data2', data2)
    setOneETHSwapData(data2)
  }

  // console.log('swapOwOConfig', swapOwOConfig)



  // useEffect(()=>{
  //   const wHeight = window.innerHeight
  //   visualViewport.addEventListener('resize', () => {
  //     if(wHeight - event.target.height) {
  //       setKeywordHeight(wHeight - event.target.height)
  //     }
  //   });
  // },[])

  useEffect(()=>{

    if(swapper && userInfo.ospAddress && swapOwOConfig.gasAddress){
      // if(isSellOwO){
        setOneOwOData({AAaddress: userInfo.ospAddress})
      // } else {
        setOneETHData({AAaddress: userInfo.ospAddress})
      // }

    }
  },[swapper, userInfo.ospAddress, isSellOwO, swapOwOConfig.gasAddress])

  useEffect(()=>{
    if(swapper && userInfo.ospAddress && swapOwOConfig.gasAddress){
      window.addEventListener("message", onSuccess);
      document.addEventListener("message",onSuccess)
      return () =>{
          document.removeEventListener("message",onSuccess,false)
          window.removeEventListener("message", onSuccess,false);
      }
    }
  },[swapper, userInfo.ospAddress, swapOwOConfig.gasAddress])


  const toSuccessPage = useCallback(async ()=>{    
    const oldOneOwOSwapData = oneOwOSwapDataRef.current
    const newOneOwOSwapData = await setOneOwOData({AAaddress: userInfo.ospAddress})
    const params = {
      isSellOwO:isSellOwORef.current,
      fromAmount: formStateRef.current.fromAmount,
      toAmount: isSellOwORef.current ? 
          newOneOwOSwapData.balance0 - oldOneOwOSwapData.balance0 : 
          newOneOwOSwapData.balance1 - oldOneOwOSwapData.balance1,
      oneOwOSwapETH:formatNum(oldOneOwOSwapData?.amountOutWithPortion, 8),
      chainGasFee: gasDataRef.current,
      portionBips:isSellOwORef.current ? swapOwOConfigRef.current.gasOwOToETHFee : swapOwOConfigRef.current.gasETHToOwOFee
    }
    const searchParams = new URLSearchParams(params).toString()

    navigate(`${ROUTER_PATH.OWO_SWAP_SUCCESS}/?${searchParams}`)
  })


  const onSuccess = useCallback(async (msg: any) => {
    try {

      const message=JSON.parse(msg.data)
      if(message?.code !== 'SWAP_OWO') {
        return
      }

      if(message?.payload?.isOnchain) {
        setIsConfirming(true)
        return
      }
      if(message?.payload?.isOnchainCancel) {
        setIsSwaping(false)
        return
      }




      if(message?.payload?.success){
        setIsConfirming(false)
        setIsConfirmVisble(false)
        setIsSwaping(false)
        toSuccessPage()
      }
    } catch (error) {
      Bugsnag.notify(new Error(JSON.stringify(error))) 
    }
  })

  return (
    <>
      <div className={`px-4 pt-4 overflow-scroll h-[calc(100dvh-78px)] mt-[78px] no-scrollbar gap-4 flex flex-col`}>
        <div className={cn(" w-full relative flex-col justify-start items-start gap-2 inline-flex")}>
          <div className={cn("self-stretch  p-3 bg-[#111111] rounded-2xl flex-col justify-start items-center gap-1 flex border border-solid border-transparent", {
            'border-Content-Accent1':isCurrentFromInput,
            'border-Content-Negative' : !isValidOwONum
          })}>
            <div className="self-stretch justify-between items-start inline-flex">
              <div className="justify-start items-center gap-3 flex">
                <div className="text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]">From</div>
              </div>
            </div>
            <div className="self-stretch justify-between items-start inline-flex ">
              <div className="flex-col justify-start items-start gap-1 inline-flex flex-1 mr-3">
                <Input
                  value={formState.fromAmount}
                  onChange={async(text) => {
                    const validText = limitFixedNum(text, isSellOwO ? 2 : 8)
                    setOneOwOData({AAaddress: userInfo.ospAddress})
                  // } else {
                    setOneETHData({AAaddress: userInfo.ospAddress})
                    updateFormState({ fromAmount: isValidNum(validText) ? validText : '', toAmount: limitFixedNum(getToAmount(validText, isSellOwO), isSellOwO ? 8 : 2) })
                  }}
                  onFocus={text => {
                    setIsCurrentFromInput(true)
                  }}
                  onBlur={text => {
                    setIsCurrentFromInput(false)
                  }}
                  placeholder='0.00'
                  type='number'
                  className={
                    cn("text-left text-Content-Primary text-2xl font-bold font-SFProDisplay leading-9", {
                      "text-Content-Disabled" :!formState.fromAmount
                    })

                  }
                />


                <div className={cn("text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]", {
                  "text-Content-Disabled" : !formState.fromAmount,
                  'text-Content-Negative' : !isValidOwONum
                })}>
                {
                  isValidOwONum
                  ?
                  `$${formatNum(fromUSDPrice, 2)}`
                  :
                  (
                    isSellOwO
                    ?
                    `At least ${formatNum(swapOwOConfig.minOwOSwapNum, 2)} OwO`
                    :
                    `At least ${trimEndZero(formatNum(swapOwOConfig.minETHSwapNum, 8))} ETH`
                  )
                }
                </div>


              </div>
              <div className="flex-col justify-start items-end gap-1 inline-flex">
                <div className="justify-start items-center gap-1 inline-flex">
                  <div className="justify-start items-center gap-1 flex">
                    <div className="justify-start items-center gap-3 flex">
                      <div className="w-8 h-8 relative">
                        
                        {
                          isSellOwO
                          ?
                          <OwOCoin className="w-8 h-8" />
                          :
                          <ETHCoin className="w-8 h-8" />

                        }
                        <BaseChain className="w-3 h-3 absolute bottom-0 right-0" />

                      </div>
                      <div className="flex-col justify-center items-start inline-flex">
                        <div className="text-[#fdfdfd] text-2xl font-bold font-SFProDisplay leading-9">{isSellOwO ? "OwO" : "ETH"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-start items-center gap-3 inline-flex">
                  <div className="justify-start items-center gap-[5px] flex">
                    <WalletIcon className="w-3 h-3" />
                    <div className="text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]">{trimEndZero(formatNum(isSellOwO ? oneOwOSwapData?.balance1 : oneOwOSwapData?.balance0, isSellOwO ? 2 : 8))}
                    </div>
                  </div>
     
                  <div onClick={()=>{
                    const validText = limitFixedNum(isSellOwO ? oneOwOSwapData.balance1 : oneOwOSwapData.balance0, isSellOwO ? 2 : 8)
                    
                    updateFormState({ fromAmount: isValidNum(validText) ? validText : '', toAmount: limitFixedNum(getToAmount(validText, isSellOwO), isSellOwO ? 8 : 2) })

                  }} className="text-right text-[#fff345] text-sm font-normal font-SFProDisplay leading-[21px] cursor-pointer">Max
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="absolute left-[calc(50%_-_20px)] top-[calc(50%_-_20px)]  h-10 p-2 bg-[#252525] rounded-[40px] justify-start items-center gap-2.5 inline-flex">
            <ExchangeIcon className="w-6 h-6 relative rotate-180 cursor-pointer" onClick={async ()=>{
                  setIsSellOwO(!isSellOwO);
                  if(!isSellOwO){
                    setOneETHData({AAaddress: userInfo.ospAddress})
                  } else {
                    setOneOwOData({AAaddress: userInfo.ospAddress})
                  }
                  updateFormState({
                    fromAmount:'',
                    toAmount:''
                  })
                }}  
            />
          </div>
          <div className={cn("self-stretch  p-3 bg-[#111111] rounded-2xl flex-col justify-start items-center gap-1 flex border border-solid border-transparent", {
            'border border-solid border-Content-Accent1':isCurrentToInput
          })}>
            <div className="self-stretch justify-between items-start inline-flex">
              <div className="justify-start items-center gap-3 flex">
                <div className="text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]">To</div>
              </div>
            </div>
            <div className="cursor-not-allowed self-stretch justify-between items-start inline-flex">
              <div className="flex-col justify-start items-start gap-1 inline-flex flex-1 mr-3">
                {}
                <span

                  className={
                    cn("text-left text-Content-Primary text-2xl font-bold font-SFProDisplay leading-9", {
                      "text-Content-Disabled" :!formState.toAmount
                    })

                  }
                >
                  {trimEndZero(formState.toAmount) || '0.00'}
                </span>


                <div className={cn("text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]", {
                  "text-Content-Disabled" :!formState.toAmount
                })}>
                  ${formatNum(toUSDPrice, 2)}
                </div>

              </div>
              <div className="flex-col justify-start items-end gap-1 inline-flex">
                <div className="justify-start items-center gap-1 inline-flex">
                  <div className="justify-start items-center gap-1 flex">
                    <div className="justify-start items-center gap-3 flex">
                      <div className="w-8 h-8 relative">
                        {
                          isSellOwO
                          ?
                          <ETHCoin className="w-8 h-8" />
                          :
                          <OwOCoin className="w-8 h-8" />
                        }

                        <BaseChain className="w-3 h-3 absolute bottom-0 right-0" />

                      </div>
                      <div className="flex-col justify-center items-start inline-flex">
                        <div className="text-[#fdfdfd] text-2xl font-bold font-SFProDisplay leading-9">{isSellOwO ? "ETH" : "OwO"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-start items-center gap-3 inline-flex">
                  <div className="justify-start items-center gap-[5px] flex">
                    <WalletIcon className="w-3 h-3" />
                    <div className="text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]">{trimEndZero(formatNum(isSellOwO ? oneOwOSwapData?.balance0 : oneOwOSwapData?.balance1, isSellOwO ? 8 : 2))}</div>
{/*                    {
                      !isSellOwO
                      &&
                      <div onClick={()=>{

                        const validText = limitFixedNum(oneOwOSwapData.balance1, 2)

                        updateFormState({ toAmount: isValidNum(validText) ? validText : '', fromAmount: limitFixedNum(getFromAmount(validText, isSellOwO), 8) })
                      }} className="text-right text-[#fff345] text-sm font-normal font-SFProDisplay leading-[21px] cursor-pointer">Max
                      </div>
                    }*/}

                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

        <div className={cn("w-full  p-3 rounded-lg border border-[#292929] flex-col justify-start items-start gap-3 inline-flex duration-[0.3s] h-[112px] overflow-hidden", {
          "h-[48px] ":!isUp
        })}>
          <div className="self-stretch justify-between items-center inline-flex">
            <div className="justify-start items-center gap-0.5 flex">
                <span className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">1 </span>
                <span className="text-[#fdfdfd] text-sm font-bold font-SFProDisplay leading-[21px]">OwO</span>
                <span className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]"> = {trimEndZero(formatNum(oneOwOSwapData?.amountOutWithPortion, 8))}</span>
                <span className="text-[#fdfdfd] text-sm font-bold font-SFProDisplay leading-[21px]"> ETH</span>
              <div className="px-1 justify-start items-centerflex">
                <RefreshIcon className={cn("w-4 h-4 cursor-pointer", {
                  "rotate-[360deg] duration-[1s]" : isRefreshing
                })} onClick={()=>{
                  setIsRefreshing(true)
                  setOneOwOData({AAaddress: userInfo.ospAddress})
                  setOneETHData({AAaddress: userInfo.ospAddress})
                  setTimeout(()=>{
                    setIsRefreshing(false)
                  },1000)
                }}/>
              </div>
            </div>
            <UpIcon className={cn("w-6 h-6 cursor-pointer duration-[0.3s]", {
              "rotate-[180deg] " : !isUp
            })} onClick={()=>setIsUp(!isUp)}/>

          </div>
            
          <div className={cn("self-stretch flex-col justify-start items-start gap-3 flex ")}>
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Processing fee</div>
              <div className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">{(isSellOwO ? swapOwOConfig.gasOwOToETHFee : swapOwOConfig.gasETHToOwOFee) / 100}%</div>
            </div>
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Max slippage</div>
              <div className="justify-start items-center gap-0.5 flex">
                <div className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">{swapOwOConfig.maxSlippage / 100}%</div>
              </div>
            </div>


          </div>



        </div>

      </div>
      <div className='sticky bottom-0 h-[104px] pb-[45px]  w-full bg-[#0A0A0A] flex flex-row justify-center items-end px-4'>
        <Button
          type='primary'
          block
          round
          color='#FFF345'
          loadingText={''}
          className='h-14 text-[#0A0A0A]'
          disabled={ isSwaping || !formState.toAmount || !formState.fromAmount || !isBalanceOK  || isConfirming || !isValidOwONum}
          onClick={async() => {
            const portionBips = isSellOwO ? swapOwOConfig.gasOwOToETHFee : swapOwOConfig.gasETHToOwOFee
            await calGasFee(isSellOwO ? TransPath.OWO_TO_ETH : TransPath.ETH_TO_OWO, userInfo.ospAddress, parseFloat( formState.fromAmount), parseFloat(formState.toAmount), portionBips)
            setIsConfirmVisble(true)
          }}
        >
          {
            isSwaping
            ?
            <Spinner className="fill-[#4D4D4D] w-5 h-5 animate-spin" />
            :
            <span
              className={cn('text-[#0A0A0A] text-base font-SFProDisplay font-bold leading-6',{
                "text-Content-Disabled cursor-not-allowed": !formState.toAmount || !formState.fromAmount || !isBalanceOK || !isValidOwONum
              })}
              >   
                {
                  !formState.toAmount || !formState.fromAmount
                  ?
                  `Enter an amount`:
                  (
                    !isBalanceOK
                    ?
                    `Insufficient ${isSellOwO ? 'OWO' : 'ETH'} balance`
                    :
                    `Swap`
                  )
                  
                }
            </span>

          }

        </Button>
      </div>
      <ConfirmModal 
        isSellOwO={isSellOwO}  
        visible={isConfirmVisble} 
        formState={formState}
        fromUSDPrice={formatNum(fromUSDPrice, 2)}
        toUSDPrice={formatNum(toUSDPrice, 2)}
        oneOwOSwapAmunt={trimEndZero(formatNum(oneOwOSwapData?.amountOutWithPortion, 8))}
        onCancel={()=>{
          setIsConfirmVisble(false)
          setIsSwaping(false)
        }}
        isSwaping={isSwaping}
        portionBips={isSellOwO ? swapOwOConfig.gasOwOToETHFee : swapOwOConfig.gasETHToOwOFee}
        maxSlippage={swapOwOConfig.maxSlippage}
        sender={userInfo.ospAddress}
        swap={swap}
        gasData={gasData}
        IUserOps={IUserOps}
        TransPath={TransPath}
      />
    </>
  )
}