import { cn } from 'utils';
import { Button } from 'react-vant';
import { sendMessage } from 'utils';
import { useContext } from "react";
import { TaskContext } from '@/utils/global';
import { useToast } from '@/hooks';

export const ClaimBadgesTips = ({ className }: { className?: string}) => {
  const Toast = useToast();
  const { state: { userInfo } } = useContext(TaskContext);
  const { appVersion, platformOS, inviteCode } = userInfo || {};

  const goClaim = () => {
    if(platformOS === 'web' || !!appVersion) {
      const messageData = {
        action: 'navigate',
        router: 'ClaimBadges'
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
    } else {
      // V1.3.0 my badge 拆分后需要强更，但是强更在V1.2.0才有。先给用户个提醒。
      Toast.show("You need to upgrade to the latest version.");
    }
  };

  return (
    <div className={cn("w-[100%] p-4", className)}>
      <div className='bg-Primitives-Yellow-900 w-[100%] min-h-[52px] rounded-2xl px-3 flex flex-row justify-between items-center'>
      <div className='text-Content-Accent1 text-sm font-SFProDisplay'>You have badges pending to be claimed</div>
      <Button
        type='primary'
        plain
        round
        color='#FFF345'
        className='!bg-Backgroun-Primary h-9 w-[68px] !text-Content-Primary text-xs font-SFProDisplay font-bold'
        style={{
          '--rv-button-border-width': '2px'
        }}
        onClick={goClaim}
      >
        Claim
      </Button>
      </div>
    </div>
  )
}