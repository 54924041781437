import React, { useCallback, useState } from 'react';

import { cn } from 'utils';

import { InfiniteScroll } from '../infiniteScroll';
import { Spin, SPIN_STATUS } from '../Spin';

import { ScrollLoadingProps } from './';

export const ScrollLoading: React.FC<ScrollLoadingProps> = (props) => {
  const [loading, setLoading] = useState(SPIN_STATUS.SUCCESS);
  const {
    invisibleHeight,
    fetchData,
    className,
    renderLoading,
    renderError,
    renderEmpty,
    status,
    loadingClassName,
    spinClassName,
    renderNoMore,
    noMoreDesc,
    noMoreShow,
    children
  } = props;

  const loadData = useCallback(async () => {
    try {
      setLoading(SPIN_STATUS.LOADING);
      await fetchData();
      setLoading(SPIN_STATUS.SUCCESS);
    } catch (error) {
      setLoading(SPIN_STATUS.SUCCESS);
    }
  }, [fetchData]);

  const isHidden =
    (status && status === SPIN_STATUS.SUCCESS) ||
    (!status && loading === SPIN_STATUS.SUCCESS);
  const isTipShow = status && status === SPIN_STATUS.SUCCESS && noMoreShow;
  return (
    <>
      <Spin
        className={cn('w-full h-12', {
          'hidden': status !== 'REFRESH',
          
        })}
        loadingClassName={cn('flex items-center justify-center', loadingClassName)}
        status={SPIN_STATUS.LOADING}
        renderLoading={
          renderLoading || 'loading...'
        }
      />
      <InfiniteScroll
        invisibleHeight={invisibleHeight}
        fetchData={loadData}
        className={className}
      >
        {children}
      </InfiniteScroll>
      {status !== 'REFRESH' && <Spin
        className={cn('w-full h-12', spinClassName, {
          'hidden': isHidden
        })}
        loadingClassName={cn('flex items-center justify-center', loadingClassName)}
        status={status || loading}
        renderError={renderError}
        renderEmpty={renderEmpty}
        renderLoading={
          renderLoading || 'loading...'
        }
      />}
      {isTipShow && (
        <>
          {!renderNoMore ? (
            <div className=' text-sm color-[#5F626D] text-center py-6 px-0'>
              {noMoreDesc || 'no more content ～'}
            </div>
          ) : (
            <>{renderNoMore}</>
          )}
        </>
      )}
    </>
  );
};
