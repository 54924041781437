import bigDecimal from "js-big-decimal";

/**保留几位小数，直接截断，不做四舍五入 */
export const toDecimal = (price: string | number, decimals: number) => {
  const reg = new RegExp(`^[-+]?\\d+(?:\\.\\d{0,${decimals}})?`);
  const amount = price.toString().match(reg);
  if (amount) {
    return bigDecimal.stripTrailingZero(amount[0]);
  }
  return bigDecimal.stripTrailingZero(amount);
};