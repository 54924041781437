import { cn, sendMessage } from "utils"
import { LeaderItemProps } from './LeaderItem';
import { LadderBgList } from './const';
import { IUserInfo } from '@/utils/global';
import { BADGE_LIST } from "@/consts";
import { useClientProvider } from "client";

export const LadderItem = (props: LeaderItemProps & {userInfo: IUserInfo}) => {
  const { level = '', rank = 0, point = 0, userInfo } = props;
  const showPoint = Number(Number(point).toFixed(0)).toLocaleString();
  const { isPC } = useClientProvider();
  const toProfile = () => {
    const messageData = {
      action: 'navigate',
      router: 'Profile',
      params: {
        handle: userInfo?.handle
      }
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  }
  return (
    <div className={cn(`flex justify-between p-2 items-center w-full bg-Content-Accent1 rounded-2xl sticky left-4 bg-cover bg-center`, {
      'w-[calc(100%-32px)]': !isPC 
    })} style={{
      backgroundImage: LadderBgList[rank] ? `url(${LadderBgList[rank]})` : null as any,
      bottom: isPC ? '50px' : '100px',
    }}>
      <div className="flex items-center">
        <span className={cn("px-2 bg-Backgroun-Tertiary rounded-full text-xs text-Content-Primary font-SFProDisplay font-bold text-center leading-[22px]")}>{rank > 200 ? "200+" : rank}</span>
        <osp-image src={userInfo?.avatar} class="w-12 h-12 box-content rounded-full mx-3 border-2 border-Backgroun-Tertiary cursor-pointer" onClick={toProfile}/>
        <div>
          <span className="text-base text-Content-Inverse-Primary font-SFProDisplay font-bold cursor-pointer line-clamp-1 " onClick={toProfile}>{userInfo?.handle}</span>
          <div className="flex items-center">
            <osp-image src={BADGE_LIST[level]?.icon} class="w-[18px] h-[18px]"/>
            <span className="ml-[2px] text-xs font-SFProDisplay font-bold text-Content-Inverse-Primary">{BADGE_LIST[level]?.name}</span>
          </div>
        </div>
      </div>
      <div className="flex items-center ml-8">
      <span className="text-base text-Content-Inverse-Primary font-SFProDisplay mr-1 font-bold">
        {showPoint}
      </span>
      <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/points-icon.svg`} class="w-4 h-4"/>
      </div>
    </div>
  )
}