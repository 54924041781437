import { Skeleton } from "react-vant";

export const LeaderBoardSkeleton = () => {
  return (
    <div>
      <ul className="flex pt-6 pb-[28px] no-scrollbar gap-x-[2px] px-4 overflow-hidden">
        {new Array(6).fill(null).map((_i, index) => {
          return (
            <Skeleton rowWidth={64} rowHeight={76} row={1} style={{"--rv-border-radius-max": "32px"}} key={`${index}`} className="px-2"/>
          )
        })}
      </ul>
      <div className="flex justify-between items-center mb-[30px] px-4">
        <div className="gap-y-[6px] flex flex-col">
          <Skeleton rowWidth={152} rowHeight={20} row={1} style={{"--rv-border-radius-max": "4px",}} className="px-0"/>
          <Skeleton rowWidth={100} rowHeight={12} row={1} style={{"--rv-border-radius-max": "4px",}} className="px-0"/>
          <Skeleton rowWidth={123} rowHeight={12} row={1} style={{"--rv-border-radius-max": "4px",}} className="px-0"/>
        </div>
        <Skeleton rowWidth={96} rowHeight={32} row={1} style={{"--rv-border-radius-max": "24px"}} className="px-0"/>
      </div>
      <div className="bg-Backgroun-Secondary rounded-t-lg px-4">
        {new Array(7).fill(null).map((_i, index) => {
          return (
            <div key={`${index}`} className="flex justify-between items-center p-2">
              <div className="flex items-center">
                <Skeleton avatar avatarSize={22} row={0} style={{"--rv-padding-md": "0"}} className="px-0 pr-3"/>
                <Skeleton avatar avatarSize={48} row={0} style={{"--rv-padding-md": "0"}} className="px-0 pr-3"/>
                <Skeleton row={1} rowWidth={152} rowHeight={20} style={{"--rv-border-radius-max": "4px"}} className="px-0"/>
              </div>
              <Skeleton row={1} rowWidth={58} rowHeight={20} style={{"--rv-border-radius-max": "4px"}} className="px-0"/>
            </div>
          )
        })}
      </div>
    </div>
  )
}