// @ts-nocheck
import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { ResponseConfig } from "../axiosHooks";
import type { PostV2TasksVerifyMutationResponse, PostV2TasksVerifyQueryParams } from "../../models/PostV2TasksVerify";
import { config } from "../config";



export function postTasksVerifyMutationOptions<TData = PostV2TasksVerifyMutationResponse, TError = unknown>(params: PostV2TasksVerifyQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = postTasksVerifyQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {

      return client<TData, TError, void>({
        method: "post",
        url: `${config.api.task.verify}`,
        headers: { ...options.headers },
        params,
        ...options,
        baseURL: config.api.baseUrl
      });
    },
  };
}

/**
 * @summary 做任务
 * @link /v2/tasks/verify
 */

export function usePostTasksVerify<TData = PostV2TasksVerifyMutationResponse, TError = unknown>(params: PostV2TasksVerifyQueryParams, options: {
  mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
  client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
} = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...postTasksVerifyMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
