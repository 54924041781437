import {
  Environment,
  OspClient,
  RelationEnum,
  ReactionKindEnum,
  AddOpenReactionParams,
  wrapperMetadata,
  AddActivityResult,
} from "@open-social-protocol/osp-client";

import { Bugsnag } from "bugsnag";

import { PromiseType } from "./type";
import { fetchFn, getGuestId, IFetchFnRes, SnakeToCamel } from "./utils";
import { ethers } from "ethers";

interface IConfig {
  env: Environment;
  loginFn: () => void;
  appId: string;
  chainId?: string;
  callback?: (params: any) => void;
}

export type { Environment } from "@open-social-protocol/osp-client";
export type { PromiseType } from "./type";

export class ProxyOsp {
  osp!: OspClient;
  private config: IConfig;
  static instance: any;
  private ospInitState: "TODO" | "DOING" | "DONE";
  private userInfo: any;
  openloginAdapterAuth: any;
  constructor(config: IConfig) {
    this.config = config;
    this.ospInitState = "TODO";
  }
  async initOsp() {
    if (this.osp) return this.osp;
    if (typeof window === "undefined") return null;
    if (!window.ethereum) return null;
    const initToken = localStorage.getItem("sdk-token");
    if (!initToken) return null;
    const provider = new ethers.providers.Web3Provider(
      window?.ethereum_osp || window?.ethereum,
      0x13881,
    );
    const signer = provider?.getSigner();
    this.ospInitState = "DOING";
    try {
      const guestId = await getGuestId();
      this.osp = await OspClient.create({
        ...this.config,
        app_id: this.config.appId,
        chain_id: Number(this.config.chainId),
        guest_id_marketing: `${guestId}`,
        login_options: {
          accessToken: initToken,
          signer,
        },
      });
      this.ospInitState = "DONE";
      return this.osp;
    } catch (error) {
      console.log(
        "window?.ethereum?.isMetaMask",
        window?.ethereum?.isMetaMask,
        window?.ReactNativeWebView,
      );
      if (
        typeof Bugsnag !== "undefined" &&
        typeof Bugsnag.notify !== "undefined"
      ) {
        Bugsnag.notify(error);
      }
      this.ospInitState = "TODO";
      return;
    }
  }

  getOsp(): Promise<OspClient | null | undefined> {
    return new Promise(async (resolve, reject) => {
      if (this.ospInitState === "DONE") {
        return resolve(this.osp);
      }
      if (this.ospInitState === "TODO") {
        const osp = await this.initOsp();
        return resolve(osp);
      }
      if (this.ospInitState === "DOING") {
        const timer = setInterval(async () => {
          if (this.ospInitState === "DONE") {
            resolve(this.osp);
            clearInterval(timer);
          }
        }, 2000);
      }
    });
  }

  getConfig = async () => {
    const osp = await this.getOsp();
    return osp?.config;
  };

  get authentication() {
    type ILoginRes = PromiseType<
      ReturnType<typeof this.osp.authentication.login>
    >;

    type ILogoutParameters = Parameters<typeof this.osp.authentication.logout>;
    type ILogoutRes = PromiseType<
      ReturnType<typeof this.osp.authentication.logout>
    >;

    type IIsloginParameters = Parameters<
      typeof this.osp.authentication.isLogin
    >;
    type IIsloginRes = PromiseType<
      ReturnType<typeof this.osp.authentication.isLogin>
    >;

    type IGetAccountAddressParameters = Parameters<
      typeof this.osp.authentication.getAccountAddress
    >;
    type IGetAccountAddressRes = PromiseType<
      ReturnType<typeof this.osp.authentication.getAccountAddress>
    >;

    type IGetEoaAddressParameters = Parameters<
      typeof this.osp.authentication.getEoaAddress
    >;
    type IGetEoaAddressRes = PromiseType<
      ReturnType<typeof this.osp.authentication.getEoaAddress>
    >;

    type IGetRequestHeaderParameters = Parameters<
      typeof this.osp.authentication.getRequestHeader
    >;
    type IGetRequestHeaderRes = PromiseType<
      ReturnType<typeof this.osp.authentication.getRequestHeader>
    >;

    type IGetAuthInfoRes = any;

    type ISetOwnerParameters = Parameters<
      typeof this.osp.authentication.setOwner
    >;
    type ISetOwnerRes = PromiseType<
      ReturnType<typeof this.osp.authentication.setOwner>
    >;

    type IIdTokenParameters = Parameters<
      typeof this.osp.authentication.idToken
    >;
    type IidTokenRes = PromiseType<
      ReturnType<typeof this.osp.authentication.idToken>
    >;

    return {
      /** 通过 Web3Auth 授权获取 用户 EOA 地址 签名 如果是twitter还会返回idToken 如果是钱包登录 idToken 为空 */
      _login: async (params: IGetAuthInfoRes, options?: any) => {
        try {
          const { error } = await fetchFn<ILoginRes>(
            [
              params,
              {
                ...options,
                "OS-Device-Id": new Date().getTime(),
              },
            ],
            ["authentication", "login"],
            this,
          );
          if (error) return { error };
          return {};
        } catch (error) {
          return { error };
        }
      },
      login: async (params: IGetAuthInfoRes) => {
        const result = await this.authentication._login(params);
        if (result.error) return result;
        return await this.profile.get();
      },
      logout: async (...args: ILogoutParameters) => {
        const result = await fetchFn<ILogoutRes>(
          args,
          ["authentication", "logout"],
          this,
        );
        this.userInfo = null;
        return result;
      },
      isLogin: async (...args: IIsloginParameters) =>
        await fetchFn<IIsloginRes>(args, ["authentication", "isLogin"], this),
      getAccountAddress: async (...args: IGetAccountAddressParameters) =>
        await fetchFn<IGetAccountAddressRes>(
          args,
          ["authentication", "getAccountAddress"],
          this,
        ),
      getEoaAddress: async (...args: IGetEoaAddressParameters) =>
        await fetchFn<IGetEoaAddressRes>(
          args,
          ["authentication", "getEoaAddress"],
          this,
        ),
      getRequestHeader: async (...args: IGetRequestHeaderParameters) =>
        await fetchFn<IGetRequestHeaderRes>(
          args,
          ["authentication", "getRequestHeader"],
          this,
        ),
      setOwner: async (...args: ISetOwnerParameters) =>
        await fetchFn<ISetOwnerRes>(args, ["authentication", "setOwner"], this),
      idToken: async (...args: IIdTokenParameters) =>
        await fetchFn<IidTokenRes>(args, ["authentication", "idToken"], this),
    };
  }

  get profile() {
    type ICreateParameters = Parameters<typeof this.osp.profile.create>;
    type ICreateRes = PromiseType<ReturnType<typeof this.osp.profile.create>>;
    type IcreateAndSetOwnerParameters = Parameters<
      typeof this.osp.profile.createAndSetOwner
    >;
    type IcreateAndSetOwnerRes = PromiseType<
      ReturnType<typeof this.osp.profile.createAndSetOwner>
    >;

    type IGetByIdParameters = Parameters<typeof this.osp.profile.getById>;
    type IGetByIdRes = PromiseType<ReturnType<typeof this.osp.profile.getById>>;

    type IGetByHandleParameters = Parameters<
      typeof this.osp.profile.getByHandle
    >;
    type IGetByHandleRes = PromiseType<
      ReturnType<typeof this.osp.profile.getByHandle>
    >;

    type IUpdateParameters = Parameters<typeof this.osp.profile.update>;
    type IUpdateRes = PromiseType<ReturnType<typeof this.osp.profile.update>>;

    type IGetProfilesParameters = Parameters<
      typeof this.osp.profile.getProfiles
    >;
    type IGetProfilesRes = PromiseType<
      ReturnType<typeof this.osp.profile.getProfiles>
    >;

    type IGetParameters = Parameters<typeof this.osp.profile.get>;
    type IGetRes = PromiseType<ReturnType<typeof this.osp.profile.get>>;
    return {
      create: async (...args: ICreateParameters) => {
        return await fetchFn<ICreateRes>(args, ["profile", "create"], this);
      },
      createAndSetOwner: async (...args: IcreateAndSetOwnerParameters) => {
        return await fetchFn<IcreateAndSetOwnerRes>(
          args,
          ["profile", "createAndSetOwner"],
          this,
        );
      },
      getById: async (...args: IGetByIdParameters) =>
        await fetchFn<IGetByIdRes>(args, ["profile", "getById"], this),
      getByHandle: async (...args: IGetByHandleParameters) =>
        await fetchFn<IGetByHandleRes>(args, ["profile", "getByHandle"], this),
      update: async (...args: IUpdateParameters) =>
        await fetchFn<IUpdateRes>(args, ["profile", "update"], this),
      getProfiles: async (...args: IGetProfilesParameters) =>
        await fetchFn<IGetProfilesRes>(args, ["profile", "getProfiles"], this),
      get: async (...args: IGetParameters) => {
        const result = await fetchFn<IGetRes & { address: string }>(
          args,
          ["profile", "get"],
          this,
        );
        if (result.error) return result;
        if (result.data) {
          this.userInfo = result.data;
        }
        return result;
      },
    };
  }

  get community() {
    type ICheckSlotNFTParameters = Parameters<
      typeof this.osp.community.checkSlotNFT
    >;
    type ICheckSlotNFTRes = PromiseType<
      ReturnType<typeof this.osp.community.checkSlotNFT>
    >;

    type ICreateParameters = Parameters<typeof this.osp.community.create>;
    type ICreateRes = PromiseType<ReturnType<typeof this.osp.community.create>>;

    type ICreateAndBuyAppsParameters = Parameters<
      typeof this.osp.community.createAndBuyApps
    >;
    type ICreateAndBuyAppsRes = PromiseType<
      ReturnType<typeof this.osp.community.createAndBuyApps>
    >;

    type ISetJoinModuleParameters = Parameters<
      typeof this.osp.community.setJoinCondition
    >;
    type ISetJoinModuleRes = PromiseType<
      ReturnType<typeof this.osp.community.setJoinCondition>
    >;

    type IUpdateParameters = Parameters<typeof this.osp.community.update>;
    type IUpdateRes = PromiseType<ReturnType<typeof this.osp.community.update>>;

    type IGetByIdParameters = Parameters<typeof this.osp.community.getById>;
    type IGetByIdRes = PromiseType<
      ReturnType<typeof this.osp.community.getById>
    >;

    type IGetByHandleParameters = Parameters<
      typeof this.osp.community.getByHandle
    >;
    type IGetByHandleRes = PromiseType<
      ReturnType<typeof this.osp.community.getByHandle>
    >;

    type IGetCommunitiesParameters = Parameters<
      typeof this.osp.community.getCommunities
    >;
    type IGetCommunitiesRes = PromiseType<
      ReturnType<typeof this.osp.community.getCommunities>
    >;

    type IGetCommunityTagsParameters = Parameters<
      typeof this.osp.community.getCommunityTags
    >;
    type IGetCommunityTagsRes = PromiseType<
      ReturnType<typeof this.osp.community.getCommunityTags>
    >;
    return {
      checkSlotNFT: async (...args: ICheckSlotNFTParameters) => {
        return await fetchFn<ICheckSlotNFTRes>(
          args,
          ["community", "checkSlotNFT"],
          this,
        );
      },
      create: async (...args: ICreateParameters) => {
        return await fetchFn<ICreateRes>(args, ["community", "create"], this);
      },
      createAndBuyApps: async (...args: ICreateAndBuyAppsParameters) => {
        return await fetchFn<ICreateAndBuyAppsRes>(
          args,
          ["community", "createAndBuyApps"],
          this,
        );
      },
      setJoinCondition: async (...args: ISetJoinModuleParameters) => {
        return await fetchFn<ISetJoinModuleRes>(
          args,
          ["community", "setJoinCondition"],
          this,
        );
      },
      update: async (...args: IUpdateParameters) => {
        const [id, values] = args;
        const formatValues = {
          ...values,
          rules: values?.rules?.filter((rule) => !!rule),
        };
        return await fetchFn<IUpdateRes>(
          [id, formatValues],
          ["community", "update"],
          this,
        );
      },
      getById: async (...args: IGetByIdParameters) => {
        return await fetchFn<IGetByIdRes>(args, ["community", "getById"], this);
      },
      getByHandle: async (...args: IGetByHandleParameters) => {
        return await fetchFn<IGetByHandleRes>(
          args,
          ["community", "getByHandle"],
          this,
        );
      },
      getCommunities: async (...args: IGetCommunitiesParameters) =>
        await fetchFn<IGetCommunitiesRes>(
          args,
          ["community", "getCommunities"],
          this,
        ),
      getCommunityTags: async (...args: IGetCommunityTagsParameters) => {
        return await fetchFn<IGetCommunityTagsRes>(
          args,
          ["community", "getCommunityTags"],
          this,
        );
      },
    };
  }

  get activity() {
    type IAddParameters = Parameters<typeof this.osp.activity.add>;
    type IAddRes = PromiseType<ReturnType<typeof this.osp.activity.add>>;

    type IAddMegaphoneOperationParameters = Parameters<
      typeof this.osp.activity._buildAddAndMegaphoneOperation
    >;
    type IAddMegaphoneOperationRes = PromiseType<
      ReturnType<typeof this.osp.activity._buildAddAndMegaphoneOperation>
    >;

    type ISendMegaphoneOperationParameters = Parameters<
      typeof this.osp.activity._sendAddOperation
    >;
    type ISendMegaphoneOperationRes = PromiseType<
      ReturnType<typeof this.osp.activity._sendAddOperation>
    >;

    type IAddActivityParameters = {
      isChain: boolean;
      communityId: string;
      metadata: Parameters<typeof wrapperMetadata>[0];
      referencedCondition: IAddParameters[0]["referencedCondition"];
    };

    // @ts-ignore
    type IAddAndMegaphoneParameters = Parameters<
      typeof this.osp.activity.addAndMegaphone
    >;
    // @ts-ignore
    type IAddAndMegaphoneRes = PromiseType<
      ReturnType<typeof this.osp.activity.addAndMegaphone>
    >;

    type IGetByIdParameters = Parameters<typeof this.osp.activity.getById>;
    type IGetByIdRes = PromiseType<
      ReturnType<typeof this.osp.activity.getById>
    >;

    type IGetActivityMetasParameters = Parameters<
      typeof this.osp.activity.getActivityMetas
    >;
    type IGetActivityMetasRes = PromiseType<
      ReturnType<typeof this.osp.activity.getActivityMetas>
    >;
    return {
      add: (...args: IAddParameters) => {
        return new Promise<IFetchFnRes<SnakeToCamel<AddActivityResult>, any>>(
          async (resolve) => {
            const res = await fetchFn<IAddRes>(args, ["activity", "add"], this);
            setTimeout(() => {
              resolve(res);
            }, 2000);
          },
        );
      },
      addActivity: async (params: IAddActivityParameters) => {
        const { isChain, communityId, metadata, referencedCondition } = params;
        const metaJson = wrapperMetadata(metadata);
        const content_uri = await this.IPFS.upload(metaJson);
        const query = {
          is_on_chain: isChain,
          community_id: communityId,
          content_uri,
          referencedCondition,
        };
        return this.activity.add(query);
      },
      // 链下帖子获取 gas & onchain
      buildMegaphoneOperation: async (
        ...args: IAddMegaphoneOperationParameters
      ) => {
        return await fetchFn<IAddMegaphoneOperationRes>(
          args,
          ["activity", "_buildAddAndMegaphoneOperation"],
          this,
        );
      },
      sendMegaphoneOperation: async (
        ...args: ISendMegaphoneOperationParameters
      ) => {
        return await fetchFn<ISendMegaphoneOperationRes>(
          args,
          ["activity", "_sendAddOperation"],
          this,
        );
      },
      // 一个接口改为两个接口计算gas费
      addMegaphone: async (...args: IAddAndMegaphoneParameters) => {
        const res = await fetchFn<IAddAndMegaphoneRes>(
          args,
          ["activity", "addAndMegaphone"],
          this,
        );
        return new Promise<typeof res>((resolve, reject) => {
          setTimeout(() => {
            resolve(res);
          }, 2000);
        });
      },
      getById: async (...args: IGetByIdParameters) => {
        return await fetchFn<IGetByIdRes>(args, ["activity", "getById"], this);
      },
      getActivityMetas: async (...args: IGetActivityMetasParameters) =>
        await fetchFn<IGetActivityMetasRes>(
          args,
          ["activity", "getActivityMetas"],
          this,
        ),
    };
  }

  get reaction() {
    type IAddOpenParameters = {
      isChain?: boolean;
      communityId?: string;
      viewId: string;
      vote?: AddOpenReactionParams["reaction"]["vote"];
    };
    type IAddOpenRes = PromiseType<
      ReturnType<typeof this.osp.reaction.addOpenReaction>
    >;

    type IAddCommentParameters = {
      isChain?: boolean;
      communityId?: string;
      viewId: string;
      metadata?: Parameters<typeof wrapperMetadata>[0];
      contentUri?: string;
    };
    type IAddCommentRes = PromiseType<
      ReturnType<typeof this.osp.reaction.addComment>
    >;

    type IAddMegaphoneParameters = Parameters<
      typeof this.osp.reaction.addMegaphone
    >;
    type IAddMegaphoneRes = PromiseType<
      ReturnType<typeof this.osp.reaction.addMegaphone>
    >;

    type IRemoveParameters = Parameters<typeof this.osp.reaction.remove>;
    type IRemoveRes = PromiseType<ReturnType<typeof this.osp.reaction.remove>>;

    type IGetDetailParameters = Parameters<typeof this.osp.reaction.getDetail>;
    type IGetDetailRes = PromiseType<
      ReturnType<typeof this.osp.reaction.getDetail>
    >;

    type IListParameters = Parameters<typeof this.osp.reaction.list>;
    type IListRes = PromiseType<ReturnType<typeof this.osp.reaction.list>>;

    type IAddMegaphoneOperationParameters = Parameters<
      typeof this.osp.reaction._buildAddMegaphoneOperation
    >;
    type IAddMegaphoneOperationRes = PromiseType<
      ReturnType<typeof this.osp.reaction._buildAddMegaphoneOperation>
    >;
    type ISendMegaphoneOperationParameters = Parameters<
      typeof this.osp.reaction._sendAddMegaphoneOperation
    >;
    type ISendMegaphoneOperationRes = PromiseType<
      ReturnType<typeof this.osp.reaction._sendAddMegaphoneOperation>
    >;

    type IListMegaphonesParameters = Parameters<
      typeof this.osp.reaction.listMegaphones
    >;
    type IListMegaphonesRes = PromiseType<
      ReturnType<typeof this.osp.reaction.listMegaphones>
    >;
    return {
      addMegaphone: async (...args: IAddMegaphoneParameters) => {
        const res = await fetchFn<IAddMegaphoneRes>(
          args,
          ["reaction", "addMegaphone"],
          this,
        );
        return new Promise<typeof res>((resolve, reject) => {
          setTimeout(() => {
            resolve(res);
          }, 2000);
        });
      },
      // 链上帖子获取 gas & onchain
      buildMegaphoneOperation: async (
        ...args: IAddMegaphoneOperationParameters
      ) => {
        return await fetchFn<IAddMegaphoneOperationRes>(
          args,
          ["reaction", "_buildAddMegaphoneOperation"],
          this,
        );
      },
      sendMegaphoneOperation: async (
        ...args: ISendMegaphoneOperationParameters
      ) => {
        return await fetchFn<ISendMegaphoneOperationRes>(
          args,
          ["reaction", "_sendAddMegaphoneOperation"],
          this,
        );
      },
      getMegaphoneOrder: async (...args: IListMegaphonesParameters) => {
        return await fetchFn<IListMegaphonesRes>(
          args,
          ["reaction", "listMegaphones"],
          this,
        );
      },
      addOpenReaction: async (params: IAddOpenParameters) => {
        // 点赞
        const { isChain, viewId, communityId, vote } = params;
        const query = {
          community_id: communityId,
          is_on_chain: isChain,
          referenced_view_id: viewId,
          reaction: {
            vote,
          },
        };
        const res = await fetchFn<IAddOpenRes>(
          [query],
          ["reaction", "addOpenReaction"],
          this,
        );
        return new Promise<typeof res>((resolve, reject) => {
          setTimeout(() => {
            resolve(res);
          }, 2000);
        });
      },
      remove: async (...args: IRemoveParameters) => {
        const res = await fetchFn<IRemoveRes>(
          args,
          ["reaction", "remove"],
          this,
        );
        return new Promise<typeof res>((resolve, reject) => {
          setTimeout(() => {
            resolve(res);
          }, 2000);
        });
      },
      getDetail: async (...args: IGetDetailParameters) => {
        return await fetchFn<IGetDetailRes>(
          args,
          ["reaction", "getDetail"],
          this,
        );
      },
      list: async (...args: IListParameters) => {
        console.log(args, "argsargs");
        return await fetchFn<IListRes>(args, ["reaction", "list"], this);
      },
    };
  }

  get IPFS() {
    const upload = async <T extends string | File>(
      data: T,
    ): Promise<string> => {
      const osp = await this.getOsp();
      const ipfs = await osp?.IPFS?.upload(data);
      if (ipfs === "ipfs://null") return '';
      return ipfs || "";
    };
    return {
      upload,
    };
  }

  get txClient() {
    type IsyncTxParameters = Parameters<
      typeof this.osp.txClient.syncTxConfirmed
    >;
    type IsyncTxRes = PromiseType<
      ReturnType<typeof this.osp.txClient.syncTxConfirmed>
    >;
    type IaddMiddlewareParameters = Parameters<
      typeof this.osp.txClient.addMiddleware
    >;
    type IaddMiddlewareRes = PromiseType<
      ReturnType<typeof this.osp.txClient.addMiddleware>
    >;

    type IEstimateOperationParameters = Parameters<
      typeof this.osp.txClient.estimateOperation
    >;
    type IEstimateOperationRes = PromiseType<
      ReturnType<typeof this.osp.txClient.estimateOperation>
    >;

    type IFillUserOpParameters = Parameters<
      typeof this.osp.txClient.fillUserOperation
    >;
    type IFillUserOpRes = PromiseType<
      ReturnType<typeof this.osp.txClient.fillUserOperation>
    >;

    type ISendAndGetReceipt = Parameters<
      typeof this.osp.txClient.sendAndGetReceipt
    >;
    type ISendAndGetReceiptOpRes = PromiseType<
      ReturnType<typeof this.osp.txClient.sendAndGetReceipt>
    >;
    return {
      syncTxConfirmed: async (...args: IsyncTxParameters) => {
        return await fetchFn<IsyncTxRes>(
          args,
          ["txClient", "syncTxConfirmed"],
          this,
        );
      },
      addMiddleware: async (...args: IaddMiddlewareParameters) => {
        return await fetchFn<IaddMiddlewareRes>(
          args,
          ["txClient", "addMiddleware"],
          this,
        );
      },
      estimateOperation: async (...args: IEstimateOperationParameters) => {
        return await fetchFn<IEstimateOperationRes>(
          args,
          ["txClient", "estimateOperation"],
          this,
        );
      },
      fillUserOperation: async (...args: IFillUserOpParameters) => {
        return await fetchFn<IFillUserOpRes>(
          args,
          ["txClient", "fillUserOperation"],
          this,
        );
      },
      sendAndGetReceipt: async (...args: ISendAndGetReceipt) => {
        return await fetchFn<ISendAndGetReceiptOpRes>(
          args,
          ["txClient", "sendAndGetReceipt"],
          this,
        );
      },
      ethersProvider: async () => {
        return this.osp.txClient.ethersProvider;
      },
    };
  }
  get NFT() {
    type IGetProfileSBTParameters = Parameters<
      typeof this.osp.NFT.getProfileSBT
    >;
    type IGetProfileSBTRes = PromiseType<
      ReturnType<typeof this.osp.NFT.getProfileSBT>
    >;

    type IGetCommunityNFTParameters = Parameters<
      typeof this.osp.NFT.getCommunityNFT
    >;
    type IGetCommunityNFTRes = PromiseType<
      ReturnType<typeof this.osp.NFT.getCommunityNFT>
    >;

    type IGetJoinNFTParameters = Parameters<typeof this.osp.NFT.getJoinNFT>;
    type IGetJoinNFTRes = PromiseType<
      ReturnType<typeof this.osp.NFT.getJoinNFT>
    >;

    type IGetFollowSBTParameters = Parameters<typeof this.osp.NFT.getFollowSBT>;
    type IGetFollowSBTRes = PromiseType<
      ReturnType<typeof this.osp.NFT.getFollowSBT>
    >;
    return {
      getProfileSBT: async (...args: IGetProfileSBTParameters) =>
        await fetchFn<IGetProfileSBTRes>(args, ["NFT", "getProfileSBT"], this),
      getCommunityNFT: async (...args: IGetCommunityNFTParameters) =>
        await fetchFn<IGetCommunityNFTRes>(
          args,
          ["NFT", "getCommunityNFT"],
          this,
        ),
      getJoinNFT: async (...args: IGetJoinNFTParameters) =>
        await fetchFn<IGetJoinNFTRes>(args, ["NFT", "getJoinNFT"], this),
      getFollowSBT: async (...args: IGetFollowSBTParameters) =>
        await fetchFn<IGetFollowSBTRes>(args, ["NFT", "getFollowSBT"], this),
    };
  }

  get asset() {
    type IGetAssetsParameters = Parameters<typeof this.osp.asset.getAssets>;
    type IGetAssetsRes = PromiseType<
      ReturnType<typeof this.osp.asset.getAssets>
    >;

    type IGetBalanceParameters = Parameters<typeof this.osp.asset.getBalance>;
    type IGetBalanceRes = PromiseType<
      ReturnType<typeof this.osp.asset.getBalance>
    >;
    return {
      getAssets: async (...args: IGetAssetsParameters) => {
        return fetchFn<IGetAssetsRes>(args, ["asset", "getAssets"], this);
      },
      getBalance: async (...args: IGetBalanceParameters) => {
        return fetchFn<IGetBalanceRes>(args, ["asset", "getBalance"], this);
      },
    };
  }

  get feed() {
    type IFeedsParameters = Parameters<typeof this.osp.feed.feeds>;
    type IFeedsRes = PromiseType<ReturnType<typeof this.osp.feed.feeds>>;

    type IGetFeedDetailRes = Parameters<typeof this.osp.feed.getFeedDetail>;
    type IGetPostDetailRes = PromiseType<
      ReturnType<typeof this.osp.feed.getFeedDetail>
    >;

    type IStoriesParameters = Parameters<typeof this.osp.feed.stories>;
    type IStoriesRes = PromiseType<ReturnType<typeof this.osp.feed.stories>>;
    return {
      feeds: async (...args: IFeedsParameters) => {
        return await fetchFn<IFeedsRes>(args, ["feed", "feeds"], this);
      },
      getPostDetail: async (...args: IGetFeedDetailRes) => {
        return await fetchFn<IGetPostDetailRes>(
          args,
          ["feed", "getFeedDetail"],
          this,
        );
      },
      getFeedDetail: async (id: string) => {
        const query = [
          id,
          {
            with_community: true,
            with_profile: true,
            with_own_reactions: true,
            with_recent_reactions: true,
            with_relations: [RelationEnum.FOLLOW],
            reaction_kinds_filter: [ReactionKindEnum.VOTE],
          },
        ];
        return await fetchFn<IGetPostDetailRes>(
          query,
          ["feed", "getFeedDetail"],
          this,
        );
      },
      stories: async (...args: IStoriesParameters) => {
        return await fetchFn<IStoriesRes>(args, ["feed", "stories"], this);
      },
    };
  }

  get account() {
    type IGetAccountParameters = Parameters<typeof this.osp.account.getAccount>;
    type IGetAccountRes = PromiseType<
      ReturnType<typeof this.osp.account.getAccount>
    >;

    type IListAccountTransactionsParameters = Parameters<
      typeof this.osp.account.listAccountTransactions
    >;
    type IListAccountTransactionsRes = PromiseType<
      ReturnType<typeof this.osp.account.listAccountTransactions>
    >;

    // type IGetAccountsParameters = Parameters<typeof this.osp.account.getAccounts>;
    // type IGetAccountsRes = PromiseType<ReturnType<typeof this.osp.account.getAccounts>>;

    type ISendTokenParameters = Parameters<typeof this.osp.account.sendToken>;
    type ISendTokenRes = PromiseType<
      ReturnType<typeof this.osp.account.sendToken>
    >;

    type ISendUserOperationParameters = Parameters<
      typeof this.osp.account.sendUserOperation
    >;
    type ISendUserOperationRes = PromiseType<
      ReturnType<typeof this.osp.account.sendUserOperation>
    >;
    type IListAuthorizedEoaAddressByOspParameters = Parameters<
      typeof this.osp.account.listAuthorizedEoaAddressByOsp
    >;
    type IListAuthorizedEoaAddressByOspRes = PromiseType<
      ReturnType<typeof this.osp.account.listAuthorizedEoaAddressByOsp>
    >;

    type IGetOwnerByEoaAddressParameters = Parameters<
      typeof this.osp.account.getOwnerByEoaAddress
    >;
    type IGetOwnerByEoaAddressRes = PromiseType<
      ReturnType<typeof this.osp.account.getOwnerByEoaAddress>
    >;

    type IListAuthorizingOspAddressByEoaParameters = Parameters<
      typeof this.osp.account.listAuthorizingOspAddressByEoa
    >;
    type IListAuthorizingOspAddressByEoaRes = PromiseType<
      ReturnType<typeof this.osp.account.listAuthorizingOspAddressByEoa>
    >;
    return {
      getAccount: async (...args: IGetAccountParameters) => {
        return fetchFn<IGetAccountRes>(args, ["account", "getAccount"], this);
      },
      listAccountTransactions: async (
        ...args: IListAccountTransactionsParameters
      ) => {
        return fetchFn<IListAccountTransactionsRes>(
          args,
          ["account", "listAccountTransactions"],
          this,
        );
      },
      // getAccounts: async (...args: IGetAccountsParameters) => {
      //   return fetchFn<IGetAccountsRes>(args, ['account', 'getAccounts'], this);
      // },
      sendToken: async (...args: ISendTokenParameters) => {
        return fetchFn<ISendTokenRes>(args, ["account", "sendToken"], this);
      },
      sendUserOperation: async (...args: ISendUserOperationParameters) => {
        return fetchFn<ISendUserOperationRes>(
          args,
          ["account", "sendUserOperation"],
          this,
        );
      },
      listAuthorizedEoaAddressByOsp: async (
        ...args: IListAuthorizedEoaAddressByOspParameters
      ) => {
        return fetchFn<IListAuthorizedEoaAddressByOspRes>(
          args,
          ["account", "listAuthorizedEoaAddressByOsp"],
          this,
        );
      },
      getOwnerByEoaAddress: async (
        ...args: IGetOwnerByEoaAddressParameters
      ) => {
        return fetchFn<IGetOwnerByEoaAddressRes>(
          args,
          ["account", "getOwnerByEoaAddress"],
          this,
        );
      },
      listAuthorizingOspAddressByEoa: async (
        ...args: IListAuthorizingOspAddressByEoaParameters
      ) => {
        return fetchFn<IListAuthorizingOspAddressByEoaRes>(
          args,
          ["account", "listAuthorizingOspAddressByEoa"],
          this,
        );
      },
    };
  }
  get configs() {
    type IGetListChainsParameters = Parameters<
      typeof this.osp.configs.listChains
    >;
    type IGetListChainsRes = PromiseType<
      ReturnType<typeof this.osp.configs.listChains>
    >;
    type IGetListPricesParameters = Parameters<
      typeof this.osp.configs.listPrices
    >;
    type IGetListPricesRes = PromiseType<
      ReturnType<typeof this.osp.configs.listPrices>
    >;
    type IGetGetPriceParameters = Parameters<typeof this.osp.configs.getPrice>;
    type IGetGetPricesRes = PromiseType<
      ReturnType<typeof this.osp.configs.getPrice>
    >;
    return {
      listChains: async (...args: IGetListChainsParameters) => {
        return fetchFn<IGetListChainsRes>(
          args,
          ["configs", "listChains"],
          this,
        );
      },
      listPrices: async (...args: IGetListPricesParameters) => {
        return fetchFn<IGetListPricesRes>(
          args,
          ["configs", "listPrices"],
          this,
        );
      },
      getPrice: async (...args: IGetGetPriceParameters) => {
        return fetchFn<IGetGetPricesRes>(args, ["configs", "getPrice"], this);
      },
    };
  }

  get store() {
    type IGetAppInfosParameters = Parameters<typeof this.osp.store.getAppInfos>;
    type IGetAppInfosRes = PromiseType<
      ReturnType<typeof this.osp.store.getAppInfos>
    >;

    type IGetAppsByCommunityParameters = Parameters<
      typeof this.osp.store.getAppsByCommunity
    >;
    type IGetAppsByCommunityRes = PromiseType<
      ReturnType<typeof this.osp.store.getAppsByCommunity>
    >;

    type IGetAppInfoByIdsParameters = Parameters<
      typeof this.osp.store.getAppInfoByIds
    >;
    type IGetAppInfoByIdsRes = PromiseType<
      ReturnType<typeof this.osp.store.getAppInfoByIds>
    >;

    type IBuyAppsForCommunityParameters = Parameters<
      typeof this.osp.store.buyAppsForCommunity
    >;
    type IBuyAppsForCommunityRes = PromiseType<
      ReturnType<typeof this.osp.store.buyAppsForCommunity>
    >;

    return {
      getAppInfos: async (...args: IGetAppInfosParameters) => {
        return fetchFn<IGetAppInfosRes>(args, ["store", "getAppInfos"], this);
      },
      buyAppsForCommunity: async (...args: IBuyAppsForCommunityParameters) => {
        return fetchFn<IBuyAppsForCommunityRes>(
          args,
          ["store", "buyAppsForCommunity"],
          this,
        );
      },
      getAppsByCommunity: async (...args: IGetAppsByCommunityParameters) => {
        return fetchFn<IGetAppsByCommunityRes>(
          args,
          ["store", "getAppsByCommunity"],
          this,
        );
      },
      getAppInfoByIds: async (...args: IGetAppInfoByIdsParameters) => {
        return fetchFn<IGetAppInfoByIdsRes>(
          args,
          ["store", "getAppInfoByIds"],
          this,
        );
      },
    };
  }

  get search() {
    type ISearchDocParameters = Parameters<typeof this.osp.search.searchDoc>;
    type ISearchDocRes = PromiseType<
      ReturnType<typeof this.osp.search.searchDoc>
    >;
    return {
      searchDoc: async (...args: ISearchDocParameters) => {
        return await fetchFn<ISearchDocRes>(
          args,
          ["search", "searchDoc"],
          this,
        );
      },
    };
  }
  get report() {
    type IAddreportParameters = Parameters<typeof this.osp.report.addReport>;
    type IAddreportRes = PromiseType<
      ReturnType<typeof this.osp.report.addReport>
    >;
    return {
      addReport: async (...args: IAddreportParameters) => {
        return await fetchFn<IAddreportRes>(
          args,
          ["report", "addReport"],
          this,
        );
      },
    };
  }

  get request() {
    type IRequestParameters = Parameters<typeof this.osp.request>;
    type IRequestRes = Promise<{
      data: any;
      error: {
        code: string;
        data: string;
        name: string;
      };
    }>;

    return {
      ospRequest: async (...args: IRequestParameters): Promise<IRequestRes> => {
        let res = {} as any;
        const osp = await this.getOsp();
        try {
          res = await osp?.request(...args);
        } catch (err: any) {
          res = {
            error: {
              code: err.error.code,
              data: err.error.data,
              name: err.error.msg,
            },
          };
        }
        return res as Promise<IRequestRes>;
      },
    };
  }
  static getInstance(params: IConfig): ProxyOsp {
    if (!this.instance) {
      this.instance = new ProxyOsp(params);
    }
    return this.instance;
  }
}
