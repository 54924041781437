type IPointsFooterProps = {
  points: string;
  vipCount: number;
  pfpCount: number;
  vipIcon: string;
  previousVipCount: number;
  previousVipIcon: string;
  seasonId: number;
}

export const PointsFooter = (props: IPointsFooterProps) => {
  const { points, vipCount, pfpCount, vipIcon, previousVipCount, previousVipIcon, seasonId  } = props;
  return (
    <div className="mt-[18px] min-h-10 flex flex-col justify-end">
      {!!points && <>
      {(vipCount > 0 || pfpCount > 0) && (
        <div className="flex items-center leading-[22px]">
          {vipCount > 0 && <>
            <osp-image src={vipIcon} class="mr-1 w-[14px] h-[14px]" />
            <span className="text-Content-Accent1 text-xs font-SFProDisplay font-bold mr-1">{`${vipCount / 5 * 100}%`}</span>
          </>
          }
          {
          pfpCount > 0 && <>
            {vipCount > 0 && <span className="text-Content-Accent1 text-xs font-SFProDisplay font-bold mr-1">+</span>}
            <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/nft.svg`} class="mr-1 w-[14px] h-[14px]" />
            <span className="text-Content-Accent1 text-xs	font-SFProDisplay font-bold mr-1">{`${pfpCount * 100}%`}</span>
          </>
          }
          <span className="text-Content-Secondary text-xs font-SFProDisplay">Boosted</span>
        </div>
      )}
      {seasonId !== 1 && previousVipCount > 0 && (
        <div className="flex items-center leading-[18px]">
          <osp-image src={previousVipIcon} class="mr-1 w-[14px] h-[14px]" />
          <span className="text-Content-Accent1 text-xs	font-SFProDisplay font-bold">{`${previousVipCount * 2}%`}</span>
          <span className="text-Content-Secondary text-xs font-SFProDisplay">&nbsp; points from season {seasonId - 1}</span>
        </div>
      )}
      </>}
    </div>
  )
}