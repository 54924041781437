
import { Toast as vantToast } from 'react-vant';

export const useToast = () => {
  const Toast = {
    show: (message: any, type?: any) => {
      vantToast({ 
          type: type || "info", 
          message, 
          position: "top", 
          className: "toast z-20 font-bold !px-4 !pt-[54px] !pb-2 !min-h-[62px] h-fit !rounded-none",
          duration: 2000
        })
    }
  }
  return Toast;
}