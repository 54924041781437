import React from 'react';
import { cn } from 'utils';
import { SPIN_STATUS } from './constant';
import { type SpinProps } from './type';

export const Spin = (props: SpinProps) => {
  const { renderLoading, renderError, renderEmpty, status, children, className = '', loadingClassName } = props;
  const render = () => {
    return (
      <div className={cn('absolute top-0 left-0 right-0 bottom-0', loadingClassName)}>
        {status === SPIN_STATUS.LOADING && renderLoading}
        {status === SPIN_STATUS.ERROR && renderError}
        {status === SPIN_STATUS.EMPTY && renderEmpty}
      </div>
    )
  }
  const notSuccess = status !== SPIN_STATUS.SUCCESS;
  return (
    <div className={cn('relative', className, {
      ['overflow-hidden']: notSuccess
    })}>
      {notSuccess && render()}
      {children}
    </div>
  )
}
