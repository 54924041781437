import React from 'react';
import { cn } from 'utils';
// @ts-ignore
import CheckedLogo from "/src/assets/check-alt.svg?react";
import { fomateDesc } from '@/utils';

const ProgressBar = ({ item, index, totalLength }) => {
  return (
    <>
      {index
        ? <div
          className={cn("h-2 bg-[#252525]",
            { ['bg-[#FFF345]']: item?.status }
          )}
          style={{ width: `${1 / (totalLength - 1) * 100}%` }}
        />
        : null}
      {item?.status
        ? <div className='absolute bg-[#FFF345] w-4 h-4 rounded-full flex justify-center items-center'
          style={{
            left: `${index / (totalLength - 1) * 100}%`,
            marginLeft: -8 * index
          }}
        >
          <CheckedLogo className="w-3 h-3	text-[#0A0A0A]" />
        </div>
        : <div className="absolute w-4 h-4 rounded-full bg-[#252525]"
          style={{
            left: `${index / (totalLength - 1) * 100}%`,
            marginLeft: -8 * index
          }}
        />}
    </>
  )
}

export const TaskProgress = ({ subTask }) => {
  return (
    <div className="ml-[58px] mt-4 inline-flex flex-col justify-center flex-1 h-fit">
      <div className="inline-flex flex-row w-full h-4 items-center relative px-2">
        {subTask.map((item, index) => {
          return (
            <ProgressBar
              key={`${index}_${item?.taskId}`}
              item={item}
              index={index}
              totalLength={subTask?.length ?? 0}
            />
          )
        })}
      </div>

      <div className="inline-flex flex-row justify-between w-full h-fit mt-1.5 mb-3">
        {subTask.map((item, index) => {
          return (
            <div
              key={`${index}_${item?.taskId}`}
              className={cn("inline-flex flex-col items-start", {
                ["items-center pl-5"]: index !== 0 && index !== subTask?.length - 1,
                ["items-end pl-5"]: index === subTask?.length - 1,
              })}>
              <span className="text-left align-top text-xs leading-[17px] font-SFProDisplay font-bold text-[#FEFEFE]">{item?.title}&nbsp;
                <span className='text-[10px] leading-[15px] font-normal text-[#A1A1A1]'>USDC</span>
              </span>
              <div className="inline flex-row items-center mt-0.5 text-xs font-SFProDisplay font-normal leading-[18px] text-[#A1A1A1]"
                dangerouslySetInnerHTML={{ __html: fomateDesc(item?.desc) }} />
            </div>
          )
        })}
      </div>
    </div>
  )
}