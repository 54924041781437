import React, { useState, useLayoutEffect } from 'react';
// @ts-ignore
import NextLogo from "/src/assets/arrow-right.svg?react";
import { TaskItem } from './TaskItem';
import { sendMessage } from 'utils';
import { TASK_TITLE } from './consts';
import { TaskListResponse } from '@/client/models';
// @ts-ignore
import TwitterSvg from "/src/assets/twitter.svg?react";
// @ts-ignore
import TipLogo from "/src/assets/tip.svg?react";
import { cn } from 'utils';

type QuestDataType = {
  available: number;
  isVip: boolean;
  vip?: string;
  pfp?: string;
  twitterName?: string;
};

type PropsType = {
  data: TaskListResponse;
  type: string;
  isHidden: boolean;
  refreshData: ()=>void;
  isVerify: boolean;
  isClaimBadge?: boolean;
  className?: string;
  questData: QuestDataType;
  setStopLoading: (stopLoding: boolean)=>void;
  stopLoading: boolean;
};

export const TaskList = (props: PropsType) => {
  const { data, type, className, refreshData, isVerify, stopLoading, setStopLoading, isHidden, questData } = props;
  const { isVip, vip, pfp, twitterName } = questData;
  const [showBuyMember, setShowBuyMember] = useState(false);
  const [dataList, setDataList] = useState(data);

  useLayoutEffect(() => {
    let list = data;
    if(type === 'social' && !(isVip && vip === '100%' && pfp === '200%') ) {
      const unCompleteSocialTask = data?.find((item) => 
        item.status === 0
      );
      setShowBuyMember(!unCompleteSocialTask);
    }
    if (type === 'wallet') {
      list = data?.filter((item) => {
        if (isHidden || (item.code === "wallet_bind" && item.status === 1 )) {
          return item?.code !== 'wallet_bind';  
        } else {
          return item;
        }     
      });
    }
    setDataList(list);
  }, [data, questData]);

  const toBuyMember = () => {
    const messageData = {
      action: 'popup',
      router: 'Member'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  return (
    <div className={cn("w-full h-fit mt-6", className)}>
      <div className="flex justify-between items-center">
        <span className="text-left align-middle text-sm font-SFProDisplay font-normal leading-[21px] text-[#c5c5c5]">
          {TASK_TITLE?.[type]}
        </span>
        {twitterName && type === 'twitter' && <span className="text-[#a1a1a1] text-xs leading-[18px] font-SFProDisplay font-normal flex gap-1">
          <TwitterSvg className="w-[18px] h-[18px] rounded-full bg-[#252525] p-1 twitter-quests-right" />
          {twitterName}
        </span>}
      </div>
      <div className="flex flex-col w-full h-fit overflow-hidden">
        {/* 会员入口 */}
        {showBuyMember && <div className={`w-full h-[58px] bg-[url('/img/membership-bg.svg')] bg-cover mt-2 pl-3 pr-4 flex flex-row justify-between items-center rounded-xl`}>
          <div className='flex flex-row h-8 items-center'>
            <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/membership-icon.svg`} class="w-8 h-8" />
            <span className="text-left align-top text-base font-SFProDisplay font-semibold leading-[20px] text-[#0a0a0a] ml-1">
              Boost your points with membership!
            </span>
          </div>
          <div
            className="bg-[#fff345] rounded-xl inline-flex flex-row w-8 h-8 justify-center items-center ml-2 flex-shrink-0"
            onClick={toBuyMember}
          >
            <NextLogo className="text-Content-Inverse-Primary-BG" />
          </div>
        </div>}

        {dataList?.map((item, index) => {
          return (
            <TaskItem 
              item={item} 
              key={`${index}_${item.taskId}`}
              refreshData={refreshData}
              isVerify={isVerify}
              isClaimBadge={props?.isClaimBadge}
              stopLoading={stopLoading}
              setStopLoading={setStopLoading}
            />
          )
        })}
      </div>
    </div>
  )
};