import React from 'react';
import { Skeleton } from 'react-vant';

export const ClaimedSkeleton = () => {
  return (
    <div
      className="grid grid-cols-3 gap-x-[30.5px] gap-y-6 px-4"
      style={{
        // @ts-ignore
        '--rv-skeleton-avatar-size': '48px',
        '--rv-padding-md': '0px'
      }}
    >
      {new Array(9).fill(null).map((_i, index) => {
        return (
          <div
            key={index.toString()}
            className='flex flex-col justify-center items-center'
          >
            <Skeleton row={1} rowHeight={120} rowWidth={100} style={{'--rv-border-radius-max': '56px'}}/>
            <Skeleton row={1} rowHeight={20} rowWidth={94} className='mt-3' style={{'--rv-border-radius-max': '4px'}}/>
            <Skeleton row={1} rowHeight={14} rowWidth={50} className='mt-2' style={{'--rv-border-radius-max': '4px'}}/>
          </div>
        )
      })}
    </div>
  )
}