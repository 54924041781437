// @ts-nocheck
import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions, UseInfiniteQueryOptions, UseInfiniteQueryResult } from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { PointLedgersQueryResponse, PointLedgersPathParams, PointLedgersQueryParams } from "../../models/PointLedgers";
import { config } from "../config";

const LIMIT = 20;
export const pointLedgersQueryKey = (type: PointLedgersPathParams["type"], params: PointLedgersQueryParams) => [{ url: `${config.api.customer.points}`, params: { type: type } }, ...(params ? [params] : [])] as const;
export function pointLedgersQueryOptionsInfinite <TData = PointLedgersQueryResponse, TError = unknown>(type: PointLedgersPathParams["type"], params: PointLedgersQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseInfiniteQueryOptions<TData, TError> {
  const queryKey = pointLedgersQueryKey(type, params);

  return {
    queryKey,
    queryFn: (ctx) => {

      return client<TData, TError>({
        method: "get",
        url: `${config.api.customer.points.replace('{type}', type)}`,
        headers: { ...options.headers },
        ...options,
        params: {
          // ...params,
          ...ctx.pageParam,
          ...(options.params || {}),
        },
        baseURL: config.api.baseUrl
      }).then(res => {
        const data = res.data?.obj;
        return data;
      });
    },
    initialPageParam: {
      offset: 0,
      limit: LIMIT
    },
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      if (lastPage?.pointLedgers?.length < LIMIT) return
      return {
        offset: lastPageParam?.offset + lastPageParam?.limit,
        limit: LIMIT
      }
    },
    select: (data) => {
      const pages = data.pages.map(item => item.pointLedgers).flat();

      return {
        available: data.pages?.[0]?.available,
        originalAvailable: data?.pages?.[0]?.originalAvailable,
        pages
      };
    }
  };
}

/**
 * @description 积分流水-已领取|已使用
 * @summary 积分流水
 * @link v3/member/points
 */

export function usePointLedgersInfinite<TData = PointLedgersQueryResponse, TError = unknown>(type: PointLedgersPathParams["type"], params?: PointLedgersQueryParams, options: {
  query?: UseInfiniteQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseInfiniteQueryResult<TData, TError> & { 
          queryKey: QueryKey, 
          rows: any[],
          available: number, 
          originalAvailable: number,
        } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? pointLedgersQueryKey(type, params);

  const query = useInfiniteQuery<TData, TError>({
    ...pointLedgersQueryOptionsInfinite<TData, TError>(type, params, clientOptions),
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return {
    rows: query.data?.pages,
    available: query.data?.available || 0,
    originalAvailable: query.data?.originalAvailable || 0,
    fetchNextPage: query.fetchNextPage, 
    hasNextPage: query.hasNextPage, 
    isFetching: query.isFetching
  };
}

