import {BigNumber, BigNumberish, ethers} from "ethers";
import {BatchUserOp} from "@open-social-protocol/osp-client";


export const enum TransPath {
    ETH_TO_OWO,
    OWO_TO_ETH
}

export type DetectResult = {
    balance0: string; //ETH
    balance1: string; //OwO
    owoPerETH: string; //目前一个ETH -> OwO
    usdPerETH: string;
    amountOut: string; //不带滑点不收手续费实际可以得到的
    swapAmountOutMin: string; //带滑点最少可以收到 下一步需要传到swap方法中的
    amountOutWithPortion: string; //amountOut 扣除手续费的值
};


export class OwOSwapper {

    /**
     * constants for mainnet
     */
    private static readonly OWO = "0x5D559eA7bB2DAE4B694A079cb8328a2145Fd32f6"
    private static readonly WETH = "0x4200000000000000000000000000000000000006"
    private static readonly SWAP_ROUTER = '0x2626664c2603336E57B271c5C0b26F421741e481';
    private static readonly UNIVERSAL_ROUTER = '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD';
    private static readonly POOL = '0x09c149c856e6fb6e40aa39209142411b554b1a41';
    private static readonly ETH_USD_POOL = '0xd0b53D9277642d899DF5C87A3966A349A798F224';
    private static readonly MULTICALL3 = '0xcA11bde05977b3631167028862bE2a173976CA11';
    private static readonly PERMIT = '0x000000000022D473030F116dDEE9F6B43aC78BA3';
    private static readonly FEE_BIPS = 10000;

    protected poolContract: ethers.Contract;
    protected routerContract: ethers.Contract;
    protected multicall3Contract: ethers.Contract;
    protected permitContract: ethers.Contract;
    protected provider: ethers.providers.JsonRpcProvider;
    public readonly slippageTolerance: number;
    public readonly portionFeeRecipient: string;


    constructor(provider: ethers.providers.JsonRpcProvider, portionFeeRecipient: string, slippageTolerance: number = 500) {
        this.poolContract = new ethers.Contract(OwOSwapper.POOL, [
            'function slot0() view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)',
        ]);
        this.routerContract = new ethers.Contract(OwOSwapper.SWAP_ROUTER, [
            'function exactInputSingle(tuple(address tokenIn, address tokenOut, uint24 fee, address recipient, uint256 amountIn, uint256 amountOutMinimum, uint160 sqrtPriceLimitX96) params) payable returns (uint256 amountOut)',
            'function execute(bytes commands, bytes[] inputs) payable'
        ]);
        this.multicall3Contract = new ethers.Contract(OwOSwapper.MULTICALL3,
            [
                "function aggregate( tuple(address target, bytes callData)[] calls) payable returns (uint256 blockNumber, bytes[] returnData)",
                'function aggregate3Value(tuple(address target, bool allowFailure, uint256 value, bytes callData)[] calls) payable returns (tuple(bool success, bytes returnData)[] returnData)',
            ]);
        this.permitContract = new ethers.Contract(OwOSwapper.PERMIT, [
            'function approve(address token, address spender, uint160 amount, uint48 expiration)'
        ])
        this.provider = provider;
        this.slippageTolerance = slippageTolerance;
        this.portionFeeRecipient = portionFeeRecipient;
    }

    /**
     *
     * @param path  交易路径
     * @param amountIn 输入的数量 单位 ether
     * @param portionBips 手续费率 500  default  % 5 2000 20% 25 -> 0.25%
     * @param sender sender
     */
    public async priceDetect(path: TransPath, sender: string, amountIn: BigNumberish, portionBips: number = 25): Promise<DetectResult> {
        let stateDiff: any;
        let slot0Calldata = this.poolContract.interface.encodeFunctionData('slot0', []);
        let swapCalldata: string;
        switch (path) {
            case TransPath.ETH_TO_OWO:
                stateDiff = {
                    [OwOSwapper.WETH]: {
                        stateDiff: {
                            '0xf6725b0ad380a1a0a98c9912ac04af27f0fefd7db824c475cd0837c4ddf62c07': ethers.constants.MaxInt256.toHexString(),
                            '0x5c05f978c223f2d15a91dcd6be80ffaf5ddb5968e8f61a590b833b86ab163d2c': ethers.constants.MaxInt256.toHexString(),
                        },
                    },
                };
                swapCalldata = this.extractCalldata(
                    '0x0000000000000000000000000000000000000001',
                    amountIn,
                    OwOSwapper.WETH,
                    OwOSwapper.OWO,
                    OwOSwapper.FEE_BIPS);
                break;
            case TransPath.OWO_TO_ETH:
                stateDiff = {
                    [OwOSwapper.OWO]: {
                        stateDiff: {
                            '0xee4378be6a15d4c71cb07a5a47d8ddc4aba235142e05cb828bb7141206657e27': ethers.constants.MaxInt256.toHexString(),
                            '0xadd2b959c78a56148389ed701e9e39bad81bd9d854911e41ca46d49842284897': ethers.constants.MaxInt256.toHexString(),
                        },
                    },
                };
                swapCalldata = this.extractCalldata(
                    ethers.constants.AddressZero,
                    amountIn,
                    OwOSwapper.OWO,
                    OwOSwapper.WETH,
                    OwOSwapper.FEE_BIPS);
                break;
            default:
                throw new Error('Invalid path');
        }
        const detectCalldata = this.multicall3Contract.interface.encodeFunctionData(
            'aggregate',
            [
                [
                    {
                        target: OwOSwapper.MULTICALL3,
                        callData: ethers.utils.solidityPack(['bytes4', 'bytes'], ['0x4d2301cc', ethers.utils.defaultAbiCoder.encode(['address'], [sender])]),
                    },
                    {
                        target: OwOSwapper.OWO,
                        callData: ethers.utils.solidityPack(['bytes4', 'bytes'], ['0x70a08231', ethers.utils.defaultAbiCoder.encode(['address'], [sender])]),
                    },
                    {
                        target: OwOSwapper.POOL,
                        callData: slot0Calldata,
                    },
                    {
                        target: OwOSwapper.ETH_USD_POOL,
                        callData: slot0Calldata,
                    },
                    {
                        target: OwOSwapper.SWAP_ROUTER,
                        callData: swapCalldata,
                    },
                ],
            ],
        );
        const result = await this.provider.send('eth_call', [
            {
                from: '0x0000000000000000000000000000000000000001',
                to: OwOSwapper.MULTICALL3,
                data: detectCalldata,
            }, 'latest', stateDiff]);
        let decode = ethers.utils.defaultAbiCoder.decode(['uint256', 'bytes[]'], result);
        let balance0 = ethers.utils.formatUnits(decode[1][0]);
        let balance1 = ethers.utils.formatUnits(decode[1][1]);
        const owoPerETH = (Math.pow(parseInt(ethers.utils.hexDataSlice(decode[1][2], 0, 32).toString()) / Math.pow(2, 96), 2)).toFixed(10);
        const usdPerETH = (Math.pow(parseInt(ethers.utils.hexDataSlice(decode[1][3], 0, 32).toString()) / Math.pow(2, 96), 2) * Math.pow(10, 12)).toFixed(10);
        const amountOut = ethers.utils.formatUnits(decode[1][4]);
        return {
            balance0,
            balance1,
            owoPerETH,
            usdPerETH,
            amountOut,
            swapAmountOutMin: (Number(amountOut) * (10000 - this.slippageTolerance) / 10000).toFixed(10),
            amountOutWithPortion: (Number(amountOut) * (10000 - portionBips) / 10000).toFixed(10)
        }
    }


    public async userBalance(sender: string): Promise<[string, string]> {
        const detectCalldata = this.multicall3Contract.interface.encodeFunctionData(
            'aggregate',
            [
                [
                    {
                        target: OwOSwapper.MULTICALL3,
                        callData: ethers.utils.solidityPack(['bytes4', 'bytes'], ['0x4d2301cc', ethers.utils.defaultAbiCoder.encode(['address'], [sender])]),
                    },
                    {
                        target: OwOSwapper.OWO,
                        callData: ethers.utils.solidityPack(['bytes4', 'bytes'], ['0x70a08231', ethers.utils.defaultAbiCoder.encode(['address'], [sender])]),
                    }
                ],
            ],
        );
        const result = await this.provider.send('eth_call', [
            {
                from: '0x0000000000000000000000000000000000000001',
                to: OwOSwapper.MULTICALL3,
                data: detectCalldata,
            }, 'latest']
        );
        let decode = ethers.utils.defaultAbiCoder.decode(['uint256', 'bytes[]'], result);
        let balance0 = ethers.utils.formatUnits(decode[1][0]);
        let balance1 = ethers.utils.formatUnits(decode[1][1]);
        return [balance0, balance1];
    }


    public async swap(path: TransPath, sender: string, amountIn: BigNumberish, amountOutMin: BigNumberish, portionBips: number = 2000): Promise<BatchUserOp> {
        let swapCalldataList: string[] = [];
        let command: string;
        let ops: BatchUserOp = [];
        switch (path) {
            case TransPath.ETH_TO_OWO:
                command = '0x0b000604';
                swapCalldataList.push(
                    ethers.utils.defaultAbiCoder.encode(['address', 'uint256'], [
                        '0x0000000000000000000000000000000000000002',
                        ethers.utils.parseEther(amountIn.toString()),
                    ])
                )
                swapCalldataList.push(
                    ethers.utils.defaultAbiCoder.encode(
                        ['address', 'uint256', 'uint256', 'bytes', 'bool'],
                        [
                            '0x0000000000000000000000000000000000000002',
                            ethers.utils.parseEther(amountIn.toString()),
                            ethers.utils.parseEther(amountOutMin.toString()),
                            ethers.utils.solidityPack(['address', 'bytes3', 'address'], [OwOSwapper.WETH, ethers.utils.zeroPad(ethers.utils.hexlify(OwOSwapper.FEE_BIPS), 3), OwOSwapper.OWO]),
                            false,
                        ],
                    )
                )
                swapCalldataList.push(ethers.utils.defaultAbiCoder.encode(['address', 'address', 'uint256'], [OwOSwapper.OWO, this.portionFeeRecipient, portionBips]))
                swapCalldataList.push(ethers.utils.defaultAbiCoder.encode(['address', 'address', 'uint256'], [OwOSwapper.OWO, '0x0000000000000000000000000000000000000001', 0]))
                ops.push({
                    to: OwOSwapper.UNIVERSAL_ROUTER,
                    data: this.routerContract.interface.encodeFunctionData("execute", [command, swapCalldataList]),
                    value: ethers.utils.parseEther(amountIn.toString()).toHexString()
                })
                break;
            case TransPath.OWO_TO_ETH:
                command = '0x00060c';
                // check approve
                const result = await this.provider.send('eth_call', [
                    {
                        from: sender,
                        to: OwOSwapper.OWO,
                        data: ethers.utils.solidityPack(['bytes4', 'bytes'], ['0xdd62ed3e', ethers.utils.defaultAbiCoder.encode(['address', 'address'], [sender, OwOSwapper.PERMIT])]),
                    },
                    'latest',
                ]);
                if (BigNumber.from(result).sub(ethers.utils.parseEther(amountIn.toString())) < BigNumber.from(0)) {
                    ops.push({
                        to: OwOSwapper.OWO,
                        data: ethers.utils.solidityPack(['bytes4', 'bytes'], ['0x095ea7b3', ethers.utils.defaultAbiCoder.encode(['address', 'uint256'], [OwOSwapper.PERMIT, ethers.constants.MaxInt256])]),
                    })
                    ops.push({
                        to: OwOSwapper.PERMIT,
                        data: this.permitContract.interface.encodeFunctionData('approve', [
                            OwOSwapper.OWO,
                            OwOSwapper.UNIVERSAL_ROUTER,
                            '0xffffffffffffffffffffffffffffffffffffffff',
                            '0xffffffffffff'
                        ])
                    })
                }
                console.log(amountOutMin.toString())
                swapCalldataList.push(
                    ethers.utils.defaultAbiCoder.encode(
                        ['address', 'uint256', 'uint256', 'bytes', 'bool'],
                        [
                            '0x0000000000000000000000000000000000000002',
                            ethers.utils.parseEther(amountIn.toString()),
                            ethers.utils.parseEther(amountOutMin.toString()),
                            ethers.utils.solidityPack(['address', 'bytes3', 'address'], [OwOSwapper.OWO, ethers.utils.zeroPad(ethers.utils.hexlify(OwOSwapper.FEE_BIPS), 3), OwOSwapper.WETH]),
                            true,
                        ],
                    )
                )
                swapCalldataList.push(ethers.utils.defaultAbiCoder.encode(['address', 'address', 'uint256'], [OwOSwapper.WETH, this.portionFeeRecipient, portionBips]))
                swapCalldataList.push(ethers.utils.defaultAbiCoder.encode(['address', 'uint256'], ['0x0000000000000000000000000000000000000001', 0]))
                ops.push({
                    to: OwOSwapper.UNIVERSAL_ROUTER,
                    data: this.routerContract.interface.encodeFunctionData("execute", [command, swapCalldataList])
                })
                break;
            default:
                throw new Error('Invalid path');
        }
        return ops;
    }


    private extractCalldata(recipient: string, amountIn: BigNumberish, tokenIn: string, tokenOut: string, fee: number) {
        return this.routerContract.interface.encodeFunctionData('exactInputSingle', [
            {
                tokenIn: tokenIn,
                tokenOut: tokenOut,
                fee,
                recipient: recipient,
                amountIn: ethers.utils.parseEther(amountIn.toString()),
                amountOutMinimum: 0,
                sqrtPriceLimitX96: 0,
            },
        ]);
    }


}