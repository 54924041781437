// @ts-ignore
import Close from "/src/assets/close.svg?react";
import { UnclaimedList } from './UnClaimedList';
import { Header } from '../Header'

export const ClaimBadges = () => {

  return (
    <div className='badge'>
      <Header title="Claim badges" />
      <UnclaimedList />
    </div>
  )
}
