interface IFormatErrorRes<T = any, K = any> {
  data: T;
  error: {
    code: string;
    data: K;
    name: string;
  }
}
export const formatError = <T = any, K = any>(err: any): IFormatErrorRes<T, K> => {
  return {
    data: '' as unknown as T,
    error: {
      code: err.code,
      data: err.data,
      name: err.name
    }
  }
}