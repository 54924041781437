// @ts-nocheck
import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { GlobalLadderQueryResponse, GlobalLadderQueryParams, GlobalLadderHeaderParams } from "../../models/GlobalLadder";
import { config } from "../config";

export const globalLadderQueryKey = (params: GlobalLadderQueryParams) => [{url:`${config.api.customer.ladder}`},...(params ? [params] : [])] as const;
export function globalLadderQueryOptions <TData = GlobalLadderQueryResponse, TError = unknown>(params: GlobalLadderQueryParams, headers: GlobalLadderHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
  const queryKey = globalLadderQueryKey(params)
  return {
    queryKey,
    enabled: false,
    queryFn: () => {
      
      return client<TData, TError>({
        method: "get",
        url: `${config.api.customer.ladder}`,
        params: {
          season: 'season0',
          level: params.level,
          limit: params.limit
        },
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: config.api.baseUrl
      }).then(res => {
          return res.data.obj
        }
      );
    },
  };
}

/**
 * @description 获取积分排行榜
 * @summary 积分天梯排行榜
 * @link /v2/member/ladder
 */

export function useGlobalLadder <TData = GlobalLadderQueryResponse, TError = unknown>(params: GlobalLadderQueryParams, headers?: GlobalLadderHeaderParams, options?: {
          query?: Partial<UseQueryOptions<TData, TError>>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? globalLadderQueryKey(params);

  const query = useQuery<TData, TError>({
    ...globalLadderQueryOptions<TData, TError>(params, headers, clientOptions),
    ...queryOptions, queryKey
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
}
