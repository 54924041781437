
import React, { useContext } from "react";
import { cn, sendMessage } from "utils"
import { Overlay } from 'react-vant';
import { useClientProvider } from "client";
import { useToast } from "@/hooks";
import { TaskContext } from "@/utils/global";
type CLAIM_PROPS  = {
  visible: boolean;
  onCancel: () => void;
}

export const ClaimModal = (props: CLAIM_PROPS) => {
  const { visible, onCancel } = props;
  const { isPC } = useClientProvider();
  const Toast = useToast();
  const { state: { userInfo } } = useContext(TaskContext);
  const { appVersion, platformOS } = userInfo || {};
  
  const toClaimBadge = () => {
    onCancel();
    if(platformOS === 'web' || !!appVersion) {
      const messageData = {
        action: 'navigate',
        router: 'MyBadges',
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
      onCancel();
    } else {
      // V1.3.0 my badge 拆分后需要强更，但是强更在V1.2.0才有。先给用户个提醒。
      Toast.show("You need to upgrade to the latest version.");
    }
  }
  return (
    <Overlay visible={visible} onClick={onCancel} style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div className={cn("flex flex-col justify-center items-center h-dvh pb-20", {
        'w-[408px] bg-Backgroun-Tertiary h-auto rounded-2xl pb-6': isPC,
      })}>
        {isPC ? <osp-image src={'img/leaderboard/claimSuccessWeb.webp'} class="w-full" />  : <osp-image src={'img/leaderboard/claimSuccess.webp'} class="w-full" />}
        <p className={cn("text-Content-Accent1 font-Jost text-[40px] font-semibold text-center font-Oswald", {
          'mt-10': isPC
        })}>CONGRATULATION!</p>
        <p className="mt-[6px] text-Content-Primary text-base font-SFProDisplay text-center relative">You have got a new badge</p>
        <div
          className={cn("w-[calc(100%-64px)] rounded-[36px] bg-Content-Accent1  text-center py-4 text-base font-SFProDisplay font-bold mt-[102px]", {
            'mt-8 w-[calc(100%-48px)]': isPC
          })}
          onClick={toClaimBadge}
        >Claim now</div>
        <div className="mt-2 text-Content-Accent1 text-base font-SFProDisplay font-bold py-4" onClick={onCancel}>Not now</div>
      </div>
    </Overlay>
  )
}