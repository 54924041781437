import { cn } from "utils";

type IPfpFooterProps = {
  pfp: {
    image: string;
  }[];
  pfpCount?: string;
  containerClassName?: string;
  contentClassName?: string;
  imgClassName?: string;
  imgContainerClassName?: string;
}

export const PfpFooter = (props: IPfpFooterProps) => {
  const { pfp, pfpCount, containerClassName, contentClassName, imgContainerClassName, imgClassName } = props;
  return (
    <div className={cn("flex justify-between items-center mt-[26px]", containerClassName)}>
      <div className={cn("inline-flex bg-Button-white/5 rounded-3xl p-[2px] h-[28px]", contentClassName)}>
      {
        pfp.map((item, index) => {
          const zIndex = `z-${(pfp.length - index) * 1}`;
          return (
            <div className={cn(`box-border w-6 h-6 bg-Backgroun-Tertiary flex items-center justify-center rounded-full relative ${zIndex}`, {
              "ml-[-6px]": index !== 0,
            }, imgContainerClassName)} key={index}>
              <osp-image src={item?.image} class={cn("w-5 h-5 rounded-full object-cover", imgClassName)} />
            </div>
          )
        })
      }
      {pfpCount && <div className="text-Content-Accent2 flex items-center justify-center text-sm font-SFProDisplay font-bold ml-[2px] mr-1">×{pfpCount}</div>}
      </div>
    </div>
  )
}