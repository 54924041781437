import { Task } from 'api';
import { Dispatch } from 'react';

export type IUserInfo = {
  platformOS: string,
  appVersion?: string,
  idToken: string;
  loginType: string;
  accessToken: string;
  somonToken: string;
  avatar: string;
  handle: string;
  inviteCode: string;
  isHidden?: boolean;
  owoAmount?: string;
  discordName?: string;
}

export type IState = {
  userInfo?: IUserInfo;
}
export enum IActionEum {
  SET_USERINFO = 'SET_USERINFO',
  UPDATE_USERINFO = 'UPDATE_USERINFO',
  REMOVE_USERINFO = 'REMOVE_USERINFO',
}

export type IAction = {
    type: IActionEum;
    payload?: any;
}
export type IContext = {
  state: IState;
  dispatch: Dispatch<IAction>;
  taskClient: Task<any>;
  isFirebaseReady?: boolean;
}

export type IReduce = (state: IState, action: IAction) => Partial<IState>;
