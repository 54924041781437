import camelCase from 'lodash/camelCase';

interface Data {
  [key: string]: any;
}

type UnArray<T> = T extends Array<infer U> ? U : T;

type SnakeToCamelCase<T extends string> = T extends `${infer R}_${infer U}` ? `${R}${Capitalize<SnakeToCamelCase<U>>}` : T

export type SnakeToCamel<T extends object> = {
  [K in keyof T as (`${SnakeToCamelCase<string & K>}` | K)]: T[K] extends Array<any> ? SnakeToCamel<UnArray<T[K]>>[] : T[K] extends object ? SnakeToCamel<T[K]> : T[K]
}

export type TransformResult<T extends Data> = SnakeToCamel<T>;

/** 对接口返回数据进行深度格式化 将下划线格式字段名 转为 小驼峰 */
export const transform = <T extends Data>(data: T): TransformResult<T> => {
  /** 如果是 原始类型 或者 null直接返回 */
  if (typeof data !== 'object' || data === null) return data;
  if (Array.isArray(data)) {
    return data.map((item) => transform(item)) as unknown as TransformResult<T>;
  }
  return Object.keys(data).reduce((prev: any, curr) => {
    const value = transform(data[curr]);
    prev[curr] = value;
    if (curr.includes('_')) {
      prev[camelCase(curr)] = value;
    }
    return prev;
  }, {})
}
