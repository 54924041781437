import { useInfiniteQuery } from "@tanstack/react-query";
import { useToast } from '@/hooks';
import { useClientProvider } from "client";
import { BadgeRecordStatusEnum } from 'api/api/customer'
import { useContext, useEffect, useState } from "react";
import { useGetBadgeType } from "./useGetBadgeType";
import { ClaimedGroup } from "../type";
import { TaskContext } from "@/utils/global";

const LIMIT = 20;

export const useGetClaimedList = () => {
  const Toast = useToast();
  const { customerApi } = useClientProvider()
  const [claimedList, setClaimedList] = useState<ClaimedGroup[]>([])
  const { state: { userInfo } } = useContext(TaskContext);
  const { data: badgeType } = useGetBadgeType();
  const {
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery({
    enabled: !!badgeType?.length,
    queryKey: ['GET_CLAIMED_LIST', , userInfo?.idToken],
    async queryFn(ctx) {
    try {
      // @ts-ignore
      const res = await customerApi.badges.listBadgeRecords(ctx.pageParam);

      if(!res?.data.success) {
        Toast.show("System error");
        return Promise.reject(res?.data.msgKey)
      }
      return res.data.obj
    } catch (error) {
      Toast.show("System error");
      return Promise.reject(error)
    }
    },
    initialPageParam: {
      limit: LIMIT,
      status: [BadgeRecordStatusEnum.CLAIMED]
    },

    getNextPageParam(lastPage, allPages, lastPageParams, allPageParams) {
      if (!lastPage?.next_token) {
        return;
      }
      return {
        ...lastPageParams,
        nextToken: lastPage?.next_token,
      };
    },
    select(data) {
      const pages = data.pages.map((item) => item.rows).flat();
      return pages;
    },
  });

  if(hasNextPage) {
  fetchNextPage()
  }

  useEffect(() => {
    if (!hasNextPage && !!badgeType?.length) {
      let claimedList: ClaimedGroup[] = [];

      badgeType.map(badgeItem => {
        const childList = (data || []).filter(item => item.type === badgeItem)
        claimedList.push({
          title: badgeItem,
          dataList: childList
        })
      })
      setClaimedList(claimedList)
    }
  }, [hasNextPage, data, badgeType])

  return {
    claimedList,
    isFetching,
    isEmpty: !data?.length
  };
};
