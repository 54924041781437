import { LeaderItem, LeaderItemProps } from './LeaderItem';
import { Empty } from '../Empty';
import { IUserInfo } from "@/utils/global";
import { useClientProvider } from "client";
import { cn } from 'utils';

interface LeaderListProps {
  globalLadder: LeaderItemProps[];
  myLadder: LeaderItemProps;
  minimumScoreRank: number;
  /** 是否是排行榜历史记录 */
  isHistory?: boolean
}

export const LeaderList = (props:LeaderListProps & {userInfo: IUserInfo | undefined}) => {
  const { isPC } = useClientProvider();
  const { globalLadder, myLadder, userInfo, minimumScoreRank, isHistory=false } = props;
  return (
    <ul className={cn("p-4 flex flex-col gap-y-2 no-scrollbar rounded-2xl bg-Backgroun-Secondary", {
      'bg-Backgroun-Primary' : isPC
    })} style={{
      paddingBottom: isPC ? '88px' : '120px',
      minHeight: isPC ? 'calc(100vh - 316px)':'calc(100vh - 346px)'
    }}>
      {globalLadder && !!globalLadder.length ? globalLadder.map(item => {
        return (
          <LeaderItem key={item.rank} {...item} myLadder={myLadder} globalLadderLength={globalLadder.length} userInfo={userInfo} minimumScoreRank={minimumScoreRank} isHistory={isHistory}/>
        )
      }): <Empty emptyText={'No ranking yet'} className="mt-[100px]"/>}
    </ul>
  )
}