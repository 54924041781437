export function toFixedTrunc(x, n) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`
}


export const isValidNum = (num) => {
  return !isNaN(parseFloat(num))
}


export const formatNum = (num, precision) => {
  if(!isNaN(parseFloat(num))){
    return toFixedTrunc(num, precision)
  }
  return 0
}

export const limitFixedNum = (num, precision) => {
  if(num.toString().split(".")[1]?.length > precision){
    return toFixedTrunc(num, precision)
  }

  return num
}


export const safeAmount = (num) => {
  if(num.toString().includes('e')){
    return num.toLocaleString('fullwide', {useGrouping:false}) 
  }
  return num
  
}

export const trimEndZero = (num) => {
  return parseFloat(num)
}