interface BADGE_OBJ {
  [key: string]: any;
}

export const DEFAULT_BADGE:BADGE_OBJ = {
  icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge.webp`,
  color: '#6D6D6D'
};

export const LadderBgList:BADGE_OBJ = {
  "1": `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/ladderbg01.webp`,
  "2": `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/ladderbg02.webp`,
  "3": `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/ladderbg03.webp`
}