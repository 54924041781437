import React, { useEffect, useState } from 'react';
// @ts-ignore
import ClockIcon from "/src/assets/clock.svg?react";
// @ts-ignore
import CheckedIcon from "/src/assets/check-circle.svg?react";
// @ts-ignore
import Spinner from "/src/assets/spinner.svg?react";
import { BADGE_ICON } from './const';
import { useTask, useSocial } from '@/hooks';
import { sendMessage, dayjs } from 'utils';
import { logEventAnalytics } from "firebase";
import { handleOpenLink } from '@/utils/handleOpenLink';

type PropsType = {
  item: any,
  refreshData: () => void,
  isVerify: boolean,
  inviteCode?: string,
}

export const EarlyBirdItem = (props: PropsType) => {
  const { item, refreshData,  isVerify, inviteCode = '' } = props;
  const itemIcon = BADGE_ICON?.[item?.code] ?? BADGE_ICON.early_bird_1;
  const { toVerifyTask } = useTask();
  const { toLink, toConnectSocial } = useSocial();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const taskId = localStorage.getItem('taskId');
    if (taskId === item?.taskId && isVerify) {
      toVerify();
    };
  }, [isVerify]);

  const openLink = () => {
    logEventAnalytics("click_weeklybadge_post");
    const formatLink = handleOpenLink(item?.url, inviteCode);
    toLink(formatLink);
  }

  const stopLoding = () => {
    setIsLoading(false);
  };

  const toVerify = async () => {
    setIsLoading(true);
    logEventAnalytics("click_weeklybadge_verify");
    localStorage.removeItem('taskId');
    const res = await toVerifyTask(item?.taskId, stopLoding);
    if (res === 'connect') {
      toConnectSocial(item?.connectUrl);
      stopLoding();
      return;
    }
    if (res === 'success') {
      refreshData();
      stopLoding();
    }  
  };

  const toMyBadge = () => {
    const messageData = {
      action: 'navigate',
      router: 'MyBadges'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const formateStatus = () => {
    const current = dayjs();
    const start = dayjs(item?.startTime);
    // const end = dayjs(item?.endTime);
    // console.log('time', current, start, end);
    if (item.status === -1) {
      return (<div className="bg-[#fdfdfd14] rounded px-2 inline-flex flex-row justify-center items-center h-[22px] w-full">
        <span className="text-left align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#6d6d6d] ml-1 flex-shrink-0">
          Not qualified
        </span>
      </div>)
    };
    if (current.isBefore(start)) {
      return (
        <div className="bg-[#fdfdfd14] rounded px-2 inline-flex flex-row justify-center items-center h-[22px] w-full">
          <ClockIcon />
          <span className="text-left align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#6d6d6d] ml-1 flex-shrink-0">
            Get on {dayjs(start).format('MMM DD')}
          </span>
        </div>
      )
    };
    if (item?.rewardStatus) {
      return (
        <div className="bg-[#fdfdfd14] rounded px-2 inline-flex flex-row justify-center items-center h-[22px] w-full">
          <CheckedIcon />
          <span className="text-left align-top text-xs font-SFProDisplay font-bold whitespace-nowrap text-[#6d6d6d] ml-1 flex-shrink-0">
            Whitelisted
          </span>
        </div>
      )
    };
    return (
      item?.status
        ? <div
          className="bg-[#FEFEFE]/20 backdrop-blur-[100px] rounded-[36px] flex flex-col justify-center items-center h-[46px] ml-2 w-[70px]"
          onClick={toMyBadge}
        >
          <span className="text-left align-top text-sm font-SFProDisplay font-bold whitespace-nowrap text-[#FEFEFE] flex-shrink-0">
            Claim
          </span>
        </div>
        : <>
          <div
            className="bg-[#0A0A0A] border-2 border-solid border-[#fff345] rounded-[36px] flex flex-col justify-center items-center h-[46px] ml-2 w-[70px]"
            onClick={openLink}
          >
            <span className="text-left align-top text-sm font-SFProDisplay font-bold whitespace-nowrap text-[#FEFEFE] flex-shrink-0">
              Post
            </span>
          </div>
          <div
            className="bg-[#FEFEFE]/20 backdrop-blur-[100px] rounded-[36px] flex flex-col justify-center items-center h-[46px] ml-2 w-[70px]"
            onClick={toVerify}
          >
            {isLoading 
              ? <Spinner className="fill-[#6d6d6d] w-5 h-5 animate-spin" />
              : <span className="text-left align-top text-sm font-SFProDisplay font-bold whitespace-nowrap text-[#FEFEFE] flex-shrink-0">
                  Verify
                </span>
            }
          </div>
        </>
    )
  }

  return (
    <div className="flex flex-row justify-between items-center w-full h-fit mt-8 ">
      <div className="flex flex-row items-center w-fit">
        <osp-image src={itemIcon} class="w-[58px] h-[58px] flex-shrink-0" />
        <div className="flex flex-col ml-2 w-full justify-center">
          <span className="text-left align-top text-base font-SFProDisplay font-bold whitespace-nowrap text-[#FEFEFE] inline-flex">
            {item?.title}
          </span>
          <span className="text-left align-top text-sm font-SFProDisplay font-normal leading-[21px] text-[#A1A1A1] mt-1 inline-flex">
            {item?.desc}
          </span>
        </div>

      </div>
      <div className="flex flex-row items-center">
        {formateStatus()}
      </div>

    </div>
  );
};