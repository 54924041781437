import { cn } from "utils";

interface SeasonTabType {
  callback: (id: number) => void;
  currentId: number;
  seasonConfig: any;
}

export const SeasonTab = (props: SeasonTabType) => {
  const { callback, currentId, seasonConfig } = props;
  return (
    <ul className='overflow-x-scroll flex items-center py-3 px-4 no-scrollbar gap-x-2'>
      {seasonConfig && seasonConfig.map((item:any) => {
        return <li key={item.id} className={cn("shrink-0 cursor-pointer bg-Primitives-Green-600 text-Content-Inverse-Primary-BG text-SFProDisplay text-sm px-4 py-2 rounded-3xl", {
          'font-bold bg-Content-Primary': currentId === item.id
        })} onClick={() => callback(item.id)}>Season {item.id}</li>
      })}
    </ul>
  )
}