import React, { useEffect, useState } from 'react';
import { Popup } from 'react-vant';

export const Auth = () => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.search) {
      setVisible(true);
    }
  }, []);

  const onCancel = () => {
    setVisible(false);
  };

  const toSomon = () => {
    const url = new URL(window.location.href);
    window.open(`somon://task${url.search}`); 
  }

  return (
    <div className='w-full h-screen flex-1 pb-[200px] flex flex-row items-center justify-center '>
      <div className="w-[100px] h-[100px] rounded-full bg-white/20 flex flex-row items-center justify-center animate-pulse" onClick={toSomon} >
        <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/somon-logo.png`} class="w-[80px] h-[80px] rounded-full " />
      </div>
      <Popup
        closeOnClickOverlay={false}
        visible={visible}
        style={{ height: '25%' }}
        position='bottom'
        overlay={false}
      >
        <div className='w-full h-full rounded-2xl px-3 pb-5'>
          <div className='w-full h-full bg-white rounded-2xl p-6 flex flex-col justify-center'>
            <span className=' text-black font-semibold font-SFProDisplay text-[18px]'>
              This website wants to open SoMon
            </span>

            <div className='mt-6 flex flex-row justify-between gap-4'>
              <div
                className='w-full rounded-full border-0 h-12 bg-black/10 flex flex-row justify-center items-center'
                onClick={onCancel}
              >
                <span className=' text-black font-semibold font-SFProDisplay'>Restrict</span>
              </div>

              <div
                className='w-full rounded-full border-0 h-12 bg-blue-500 flex flex-row justify-center items-center'
                onClick={toSomon}
              >
                <span className=' text-white font-semibold font-SFProDisplay'>Allow</span>
              </div>
            </div>

          </div>
        </div>

      </Popup>
    </div>
  )
}