import { useContext } from 'react';
import { ClaimedItemProps } from './type'
import { NewTag } from './NewTag';
import { TaskContext } from '@/utils/global';
import { onShareBadge } from './utils'
import { logEventAnalytics } from 'firebase';

// @ts-ignore
import Share from "/src/assets/share.svg?react";

export const ClaimedItem = (props: ClaimedItemProps) => {
  const { itemData, isNew } = props;
  const { state: { userInfo } } = useContext(TaskContext);
  const { inviteCode = '', handle = '', avatar = '', idToken = '' } = userInfo || {};
  const { badge_id, image, name, description, remark } = itemData || {};

  let des = remark || description || '';

  const onShare = () => {
    logEventAnalytics('click_mybadge_share')
    onShareBadge({
      badge_id,
      inviteCode,
      handle,
      avatar
    }, idToken)
  }

  return (
    <div className="flex flex-col items-center relative">
      {isNew && <NewTag isShow={true} />}
      <div className='relative w-full h-0 pt-[100%]'>
        <osp-image src={image} class="w-full absolute top-0 left-0"/>
      </div>
      <div className='mt-3 flex flex-col items-center'>
        <div className='flex flex-row items-center'>
          <span className='text-base font-SFProDisplay font-bold leading-6 text-[#fdfdfd] break-all line-clamp-1'>
            {name}
          </span>
          {isNew && (
            <div
              className='ml-[2px] w-4 h-4'
              onClick={onShare}
            >
              <Share className='text-[#6D6D6D]'/>
            </div>
          )}
        </div>

        <span className='mt-[2px] text-sm font-SFProDisplay font-normal leading-[21px] text-[#a0a0a0] break-all line-clamp-2'>
          {des}
        </span>
      </div>
    </div>
  )
}