/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WebResult {
  code: string;
  msgKey: string;
  success: boolean;
}

export type Map = object;

export type WebResultNonceVO = {
  /** nonce随机数的值 */
  nonce?: string;
};

export type WebResultCustomerVO = WebResult & {
  obj?: CustomerVO;
};

export type WebResultCustomerVerifyVO = WebResult & {
  obj?: CustomerVerifyVO;
};

export interface CustomerVerifyVO {
  /** 验证通过后生成的id，用于后续二次验证使用 */
  tokenId?: string;
}

export interface CustomerVO {
  id?: string;
  /** 邮箱 */
  email?: string;
  /** 用户名 */
  userName?: string;
  /** 用户状态 */
  status?: number;
  /** kyc身份验证状态,目前废弃,使用kycAuthLevel字段 */
  idCertifiedStatus?: number;
  /** 是否认证邮箱 */
  isEmailCertified?: boolean;
  /** 是否绑定了邮箱 */
  isBindEmail?: boolean;
  /** 是否认证手机 */
  isPhoneCertified?: boolean;
  /** 是否绑定了手机号码 */
  isBindPhone?: boolean;
  /** 是否认证Google */
  isGoogleCertified?: boolean;
  /** 是否绑定Google */
  isBindGoogle?: boolean;
  /** 是否绑定web3 */
  isBindWeb3?: boolean;
  locale?: string;
  /** 手机号 */
  phone?: string;
  /** 注册类型 */
  registerType?: number;
  isMigration?: number;
  /** 是否存在密码 */
  noPassword?: boolean;
  /** 是否存在资金密码 */
  isExistAssetPassword?: boolean;
  /** 国家地区 */
  region?: string;
  /** kyc等级 */
  kycLevel?: string;
  /** vip等级 */
  vipLevel?: string;
  /**
   * vip到期时间
   * @format int64
   * @example 1663836874000
   */
  vipEndTime?: number;
  merchantId?: string;
  merchantName?: string;
  whatsAppId?: string;
  telegramId?: string;
  lineId?: string;
  middleName?: string;
  /** 头像 */
  avatar?: string;
  /** 性别 */
  gender?: number;
  /** 昵称 */
  nickName?: string;
  uniqueName?: string;
  jwtKey?: string;
  connectAddress?: string;
  created?: number;
  wsKey?: string;
  refreshToken?: string;
  /**
   * refreshToken 过期时间
   * @format int64
   * @example 1663836874000
   */
  refreshTokenExpireIn?: number;
  /** 三方public_key(web3auth) */
  publicKey?: string;
  /** 钱包账户地址 */
  walletAddress?: string;
  address?: WalletAddressVO;
  /** 是否是通过web3钱包注册 */
  registByWallet?: boolean;
}

export interface WalletAddressVO {
  web3auth?: Map;
}

/** contacts info */
export interface ContactsData {
  /** email */
  email?: string;
  /** discord */
  discord?: string;
}

/** contacts */
export enum ContactsTypeEnum {
  DISCORD = "DISCORD",
  EMAIL = "EMAIL",
  SUBSCRIBE = "SUBSCRIBE",
}

export type ContactsDataResponse = WebResult & {
  /** contacts info */
  data?: ContactsData;
};

export type WebResultCustomerInviteVO = WebResult & {
  obj?: CustomerInviteVO;
};

export interface CustomerInviteVO {
  /** 用户id */
  customerId?: string;
  /** 用户邀请码 */
  inviteCode?: string;
  /**
   * 用户邀请人数
   * @format int64
   */
  inviteCount?: number;
  /** 用户的邀请人id */
  inviterId?: string;
  /** 邀请码是否支持修改 */
  revisable?: boolean;
  /**
   * 邀请时间
   * @format int64
   */
  inviteTime?: number;
  /**
   * 用户注册时间
   * @format int64
   */
  registerTime?: number;
}

export interface Bird {
  /** @format int64 */
  date?: number;
  /**
   * early evening bird total
   * @format int64
   */
  total?: number;
  /** @format double */
  percent?: number;
}

export interface OwoRule {
  /** early evening bird date */
  bird_data?: Bird[];
  /** @format int64 */
  total_member_count?: number;
  /** @format double */
  member_percent?: number;
  /** @format int64 */
  total_invite_count?: number;
  /** @format double */
  invite_percent?: number;
  /** @format int64 */
  total_socialfi_count?: number;
  /** @format int64 */
  socialfi_count?: number;
  /** @format double */
  socialfi_percent?: number;
}

export type OwoRuleResponse = WebResult & {
  obj?: OwoRule;
};

export interface ClaimBadgeRequest {
  /** claim badge recordIds */
  ids?: string[];
}

export interface WalletOperation {
  /** target address */
  target_address?: string;
  /** call data */
  call_data?: string;
  /** value */
  value?: string;
}

export interface WalletOperationPagination {
  rows?: WalletOperation[];
}

export type WalletOperationPaginationResponse = WebResult & {
  obj: WalletOperationPagination;
};

/** badge type */
export enum BadgeTypeEnum {
  LEADER_BOARD = "LEADER_BOARD",
  EARLY_BIRD = "EARLY_BIRD",
  ADMIN = "ADMIN",
}

/** badge record status */
export enum BadgeRecordStatusEnum {
  UN_CLAIMED = "UN_CLAIMED",
  CLAIMED = "CLAIMED",
}

export type CategoryResponse = WebResult & {
  obj?: CategoryArray;
};

export type CategoryArray = Category[];

export interface Category {
  /** category name */
  name?: string;
  /** category */
  category?: string;
  tribes?: Tribe[];
}

/** tribe info */
export interface Tribe {
  /** tribe handle */
  handle?: string;
  /** tribe logo */
  logo?: string;
  /** id */
  id?: string;
}

export interface BadgeRecordsPaginationRequest {
  /** 排行榜 早鸟 运营 */
  type?: BadgeTypeEnum;
  /** 领取状态 待领取 已领取 */
  status?: BadgeRecordStatusEnum[];
  /**
   * request data count
   * @format int32
   * @min 1
   * @max 20
   */
  limit?: number;
  /** 翻页token */
  nextToken?: string;
}

export type BadgeRecordAddResponse = WebResult & {
  obj: boolean;
};

export interface BadgeRecordAdd {
  customer_id?: string;
  /** @format int64 */
  end_time?: number;
  /** @format int64 */
  badge_id?: number;
  remark?: string;
  business_id?: string;
  signature?: string;
}

export interface UserOperation {
  sender?: string;
  nonce?: string;
  init_code?: string;
  call_data?: string;
  call_gas_limit?: string;
  verification_gas_limit?: string;
  pre_verification_gas?: string;
  max_fee_per_gas?: string;
  max_priority_fee_per_gas?: string;
  paymaster_and_data?: string;
  signature?: string;
}

export type GasPolicyResponse = WebResult & {
  obj: GasPolicy;
};

export interface GasPolicy {
  data?: string;
}

export interface BadgeType {
  /** badge type */
  type?: string[];
}

export type BadgeTypeResponse = WebResult & {
  obj?: BadgeType;
};

export type WalletOperationResponse = WebResult & {
  obj: WalletOperation;
};

export interface SyncRequest {
  tx_hash?: string;
  /** operate hash */
  uo_hash?: string;
  topic?: string;
  chain_id?: string;
}

export type MintNftResponse = WebResult & {
  obj: MintNftResult;
};

export interface MintNftResult {
  /** nft type enum */
  white_type_enum?: NftStageEnum;
}

/** nft type enum */
export enum NftStageEnum {
  NO = "NO",
  GTD = "GTD",
  FCFS = "FCFS",
}

export interface Season {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  startTime?: number;
  /** @format int64 */
  endTime?: number;
  /** vip amount price */
  vipAmount?: string;
  vip_token_address?: string;
  vip_token_symbol?: string;
  /** @format int32 */
  vip_token_decimals?: number;
  owo_token_address?: string;
  owo_token_symbol?: string;
  /** @format int32 */
  owo_token_decimals?: number;
  current?: boolean;
  /** @format int32 */
  max_vip_count?: number;
}

export type SeasonArray = Season[];

export type SeasonArrayResponse = WebResult & {
  obj?: SeasonArray;
};

export interface SettleDetail {
  /**
   * season
   * @format int64
   */
  season?: number;
  /** customer id */
  customerId?: string;
  /** @format int64 */
  points?: number;
  /**
   * owo count
   * @format double
   */
  owoCount?: number;
  /** @format int64 */
  pfpCount?: number;
  /** @format int64 */
  vipCount?: number;
  /** json structure */
  pfp?: string;
  /**
   * 上个season会员个数
   * @format int64
   */
  previousVipCount?: number;
  badge?: Record<string, SeasonBadge>;
  /** 领取状态 待领取 已领取 */
  claimStatus?: BadgeRecordStatusEnum;
  /**
   * 结算状态0为未结算、1为结算中、2为已结算
   * @format int64
   */
  settleStatus?: number;
  /**
   * 过期claim时间
   * @format int64
   */
  endTime?: number;
  /**
   * top level
   * @format int32
   */
  topLeaderBoardLevel?: number;
  /**
   * 上个season状态
   * @format int64
   */
  previousSettleStatus?: number;
}

export type SettleDetailResponse = WebResult & {
  obj?: SettleDetail;
};

export interface SeasonBadge {
  /** badge image */
  image?: string;
  /** @format int64 */
  count?: number;
}

export type SettleAuthConnectResponse = WebResult & {
  obj?: string;
};

export type SettleAuthChallengeResponse = WebResult & {
  obj?: string;
};

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://api.dev.dipbit.xyz/v2";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Kweb API
 * @version 1.0
 * @license Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0)
 * @baseUrl http://api.dev.dipbit.xyz/v2
 *
 * The Kweb API v1.0
 */
export class Customer<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  customer = {
    /**
     * @description 三方认证登录
     *
     * @tags CustomerV2
     * @name OauthLogin
     * @summary 三方认证登录
     * @request POST:/customer/login/oauth
     */
    oauthLogin: (
      query: {
        /** 三方token */
        idToken: string;
        /** 三方publicKey */
        publicKey: string;
        /** 钱包类型(evm、sui), 默认值为 evm */
        walletType?: string;
        /** 三方认证来源(EXTERNAL_SOCIAL、EXTERNAL_WALLET) */
        thirdAuthSource: string;
        /** 三方平台来源(web3auth/osp) */
        platform?: string;
        /** 邀请码 */
        inviteCode?: string;
        /**
         * 是否自动注册(即账户未注册的情况下，注册后登录)，默认false
         * @default false
         */
        autoRegister?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultCustomerVO, any>({
        path: `/customer/login/oauth`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerV2
     * @name QuickLogin
     * @request POST:/customer/login/quick
     */
    quickLogin: (
      query?: {
        email?: string;
        mobile?: string;
        password?: string;
        /** @default "" */
        smsVerifyCode?: string;
        /** @default "" */
        emailVerifyCode?: string;
        /**
         * @format int32
         * @default 0
         */
        tokenType?: number;
        /**
         * @format int32
         * @default 0
         */
        source?: number;
        /** @default "" */
        token?: string;
        /** @default "web" */
        pTokenType?: string;
        /** @default "00000000-0000-0000-0000-000000000000" */
        deviceId?: string;
        /** @format int32 */
        smsType?: number;
        inviteCode?: string;
        /** @default false */
        autoRegister?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultCustomerVO, any>({
        path: `/customer/login/quick`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 用户信息获取
     *
     * @tags CustomerV2
     * @name Customer
     * @summary 用户信息获取
     * @request GET:/customer
     */
    customer: (params: RequestParams = {}) =>
      this.request<WebResultCustomerVO, any>({
        path: `/customer`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description 绑定/换绑邮箱
     *
     * @tags CustomerV2
     * @name EmailBind
     * @summary 绑定/换绑邮箱
     * @request POST:/customer/bind/email
     */
    emailBind: (
      query: {
        /** 绑定/换绑的邮箱 */
        email: string;
        /** 邮箱验证码 */
        verifyCode: string;
        /** 类型(8: 绑定, 11: 换绑) */
        type: number;
        /** 换绑时，旧邮箱生成的token */
        tokenId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultCustomerVO, any>({
        path: `/customer/bind/email`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 账户验证
     *
     * @tags CustomerV2
     * @name Verify
     * @summary 账户验证
     * @request POST:/customer/verify
     */
    verify: (
      query: {
        /** 邮箱 */
        email?: string;
        /** 手机号 */
        mobile?: string;
        /** 验证码 */
        verifyCode: string;
        /** 类型(8: 绑定, 11: 换绑) */
        type: number;
        /** 手机号验证类型 */
        smsType?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultCustomerVerifyVO, any>({
        path: `/customer/verify`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 绑定第三方
     *
     * @tags CustomerV2
     * @name BindOauth
     * @summary 绑定第三方
     * @request POST:/customer/bind/oauth
     */
    bindOauth: (
      query: {
        /** 三方token */
        idToken: string;
        /** 三方认证来源(EXTERNAL_SOCIAL、EXTERNAL_WALLET) */
        thirdAuthSource: string;
        /** 第三方平台，比如 web3auth, firebase, tencent_im */
        platform: string;
        /** 钱包类型(evm、sui) */
        walletType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResult, any>({
        path: `/customer/bind/oauth`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerV2
     * @name AuthChallenge
     * @summary 获取签名信息
     * @request POST:/customer/auth/challenge
     */
    authChallenge: (
      query: {
        /** 用户第三方钱包的公钥 */
        address: string;
        /** 链id */
        chainId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultNonceVO, any>({
        path: `/customer/auth/challenge`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 绑定wallet
     *
     * @tags CustomerV2
     * @name BindAuth
     * @summary 绑定wallet
     * @request POST:/customer/bind/auth
     */
    bindAuth: (
      query: {
        /** 用户第三方钱包的地址 */
        address: string;
        /** 签名 */
        signature: string;
        /** 三方认证来源(EXTERNAL_SOCIAL、EXTERNAL_WALLET) */
        thirdAuthSource: string;
        /** 第三方平台，比如 web3auth, firebase, tencent_im, particle */
        platform: string;
        /** 钱包类型(evm, sui, bsc, eth, zksync_era, linea, mantle, polygon) */
        walletType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultCustomerVO, any>({
        path: `/customer/bind/auth`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 通过wallet登陆
     *
     * @tags CustomerV2
     * @name LoginAuth
     * @summary 通过wallet登陆
     * @request POST:/customer/login/auth
     */
    loginAuth: (
      query: {
        /** 用户第三方钱包的公钥 */
        publicKey: string;
        /** 钱包类型(evm, sui, bsc, eth, zksync_era, linea, mantle, polygon) */
        walletType: string;
        /** 签名 */
        signature: string;
        /** 第三方平台，比如 web3auth, firebase, tencent_im, particle */
        platform: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebResultCustomerVO, any>({
        path: `/customer/login/auth`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description 用户邀请信息
     *
     * @tags InviteV2
     * @name CustomerInvite
     * @summary 用户邀请信息
     * @request GET:/customer/invite
     * @secure
     */
    customerInvite: (params: RequestParams = {}) =>
      this.request<WebResultCustomerInviteVO, any>({
        path: `/customer/invite`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  categories = {
    /**
     * @description tribe category config
     *
     * @tags Tribe
     * @name Category
     * @summary /tribe/category
     * @request GET:/categories
     * @secure
     */
    category: (params: RequestParams = {}) =>
      this.request<CategoryResponse, any>({
        path: `/categories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contacts = {
    /**
     * @description bind contacts
     *
     * @tags Contacts
     * @name BindContacts
     * @summary /contacts/{type}/bind
     * @request POST:/contacts/{type}/bind
     * @secure
     */
    bindContacts: (
      type: ContactsTypeEnum,
      query?: {
        /**
         * bind email or discord account
         * @example ""
         */
        account?: string;
        /**
         * email code or discord token
         * @example ""
         */
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactsDataResponse, any>({
        path: `/contacts/${type}/bind`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  owo = {
    /**
     * @description owo rule
     *
     * @tags OWO
     * @name OwoRule
     * @summary /owo/rule
     * @request GET:/owo/rule
     * @secure
     */
    owoRule: (params: RequestParams = {}) =>
      this.request<OwoRuleResponse, any>({
        path: `/owo/rule`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  wallets = {
    /**
     * @description claim badges
     *
     * @tags Wallet
     * @name ClaimBadges
     * @summary /wallets/claim/badges
     * @request POST:/wallets/claim/badges
     */
    claimBadges: (data: ClaimBadgeRequest, params: RequestParams = {}) =>
      this.request<WalletOperationResponse, any>({
        path: `/wallets/claim/badges`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description get policy of gas credit
     *
     * @tags Wallet
     * @name GasPolicy
     * @summary /wallets/gas/policy
     * @request POST:/wallets/gas/policy
     * @secure
     */
    gasPolicy: (data: UserOperation, params: RequestParams = {}) =>
      this.request<GasPolicyResponse, any>({
        path: `/wallets/gas/policy`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description claim token
     *
     * @tags Wallet
     * @name ClaimToken
     * @summary /wallets/claim/tokens/{token_address}
     * @request POST:/wallets/claim/tokens/{token_address}
     * @secure
     */
    claimToken: (tokenAddress: string, params: RequestParams = {}) =>
      this.request<WalletOperationResponse, any>({
        path: `/wallets/claim/tokens/${tokenAddress}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description claim token
     *
     * @tags Wallet
     * @name ClaimTokenList
     * @summary /wallets/claim/tokens
     * @request POST:/wallets/claim/tokens
     * @secure
     */
    claimTokenList: (
      query?: {
        /**
         * claim token
         * @example ""
         */
        token_address?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WalletOperationPaginationResponse, any>({
        path: `/wallets/claim/tokens`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  badges = {
    /**
     * @description badges
     *
     * @tags Badge
     * @name ListBadge
     * @summary /badges
     * @request GET:/badges
     * @secure
     */
    listBadge: (
      query?: {
        /**
         * query by type
         * @example ""
         */
        type?: BadgeTypeEnum[];
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/badges`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description list records
     *
     * @tags Badge
     * @name ListBadgeRecords
     * @summary /badges/records
     * @request GET:/badges/records
     * @secure
     */
    listBadgeRecords: (
      query?: {
        /** query by request */
        params?: BadgeRecordsPaginationRequest;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/badges/records`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description add badges records
     *
     * @tags Badge
     * @name AddRecords
     * @summary /badges/records
     * @request POST:/badges/records
     * @secure
     */
    addRecords: (data: BadgeRecordAdd, params: RequestParams = {}) =>
      this.request<BadgeRecordAddResponse, any>({
        path: `/badges/records`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description badge types
     *
     * @tags Badge
     * @name BadgeType
     * @summary /badges/type
     * @request GET:/badges/type
     * @secure
     */
    badgeType: (params: RequestParams = {}) =>
      this.request<BadgeTypeResponse, any>({
        path: `/badges/type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  tx = {
    /**
     * @description sync operate
     *
     * @tags Transaction
     * @name Sync
     * @summary /tx/sync
     * @request POST:/tx/sync
     * @secure
     */
    sync: (data: SyncRequest, params: RequestParams = {}) =>
      this.request<WebResult, void>({
        path: `/tx/sync`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  nft = {
    /**
     * @description check nft by address
     *
     * @tags NFT
     * @name NftCheckByAddress
     * @summary /nft/checker/{address}
     * @request GET:/nft/checker/{address}
     * @secure
     */
    nftCheckByAddress: (address: string, params: RequestParams = {}) =>
      this.request<MintNftResponse, any>({
        path: `/nft/checker/${address}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  seasons = {
    /**
     * @description Seasons
     *
     * @tags Season
     * @name Seasons
     * @summary /seasons
     * @request GET:/seasons
     * @secure
     */
    seasons: (params: RequestParams = {}) =>
      this.request<SeasonArrayResponse, any>({
        path: `/seasons`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description season
     *
     * @tags Season
     * @name Season
     * @summary /seasons/{season}/settle
     * @request GET:/seasons/{season}/settles/myself
     * @secure
     */
    season: (season: number, params: RequestParams = {}) =>
      this.request<SettleDetailResponse, any>({
        path: `/seasons/${season}/settles/myself`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description 鉴权获取链接绑定用户jwt信息
     *
     * @tags Season
     * @name AuthConnect
     * @summary /seasons/auth/connect
     * @request POST:/seasons/auth/connect
     * @secure
     */
    authConnect: (data: any, params: RequestParams = {}) =>
      this.request<SettleAuthConnectResponse, any>({
        path: `/seasons/auth/connect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 获取签名信息
     *
     * @tags Season
     * @name AuthChallenge
     * @summary /seasons/auth/challenge
     * @request POST:/seasons/auth/challenge
     * @secure
     */
    authChallenge: (
      query: {
        /**
         * 钱包地址
         * @example ""
         */
        address: string;
        /**
         * chainId
         * @example ""
         */
        chainId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SettleAuthChallengeResponse, any>({
        path: `/seasons/auth/challenge`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
