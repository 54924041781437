// @ts-nocheck
import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "../axiosHooks";
import type { GetV2TasksListQueryResponse, GetV2TasksListHeaderParams } from "../../models/GetV2TasksList";
import { config } from "../config";

export const getTasksListQueryKey = (params: any) => [{url:`${config.api.task.list}`}, ...(params ? [params] : [])] as const;
export function getTasksListQueryOptions <TData = GetV2TasksListQueryResponse, TError = unknown>(params?: any, headers?: GetV2TasksListHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
  const queryKey = getTasksListQueryKey(params);

  const {
    idToken,
    ...queryParams
  } = params;

  return {
    queryKey,
    queryFn: () => {
      
      return client<TData, TError>({
        method: "get",
        url: `${config.api.task.list}`,
        headers: { ...headers, ...options.headers },
        params: queryParams,
        ...options,
        baseURL: config.api.baseUrl
      }).then(res => {
        const data = res.data?.obj;
        // const result = Object.groupBy(data, ({ domain }) => domain);
        const result = data.reduce((acc, item) => {
          if (acc[item.domain]) {
            acc[item.domain].push(item);
          } else {
            acc[item.domain] = [item];
          }
          return acc;
        }, {});
        return result
      });
    },
  };
}

/**
 * @summary 获取任务列表
 * @link /v2/tasks/list
 */

export function useGetTasksList <TData = GetV2TasksListQueryResponse, TError = unknown>(params: any, headers?: GetV2TasksListHeaderParams, options: {
  query?: UseQueryOptions<TData, TError>,
  client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
} = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
const { query: queryOptions, client: clientOptions = {} } = options ?? {};
const queryKey = queryOptions?.queryKey ?? getTasksListQueryKey(params);

const query = useQuery<TData, TError>({
...getTasksListQueryOptions<TData, TError>(params, headers, clientOptions),
...queryOptions
}) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

query.queryKey = queryKey as QueryKey;
return query;
}