import { cn, sendMessage } from 'utils';

// @ts-ignore
import JumpSvg from "/src/assets/jump.svg?react";
// @ts-ignore
import GlideSvg from "/src/assets/glide.svg?react";
import { IUserInfo } from '@/utils/global';

export interface LeaderItemProps {
  level: string;
  nickName: string;
  icon: string;
  customerId: string;
  rank: number;
  point: number;
  minimumScoreRank: number;
  /** 是否是排行榜历史记录 */
  isHistory?: boolean;
}

type GetLastTenProps = {
  level: string;
  rank: number;
  minimumScoreRank: number;
  globalLadderLength: number;
}

const getLastTen = ({level, rank, minimumScoreRank, globalLadderLength}: GetLastTenProps) => {
  if (Number(level) === 1) {
    // 青铜不降级
    return false
  }else if (Number(level) < 6) {
    /** 后10名, 总人数大于30人时，取后10名，大于20小于30时取20以后降级 */
    return ((!(globalLadderLength < 30) && rank === (globalLadderLength-10)) || globalLadderLength > 20 && globalLadderLength < 30 && rank === 20);
  } else {
    // 王者，不满5000分会降级 或 处于后10名
    return rank === minimumScoreRank;
  }
}

export const LeaderItem = (props: LeaderItemProps & {globalLadderLength: number, myLadder: LeaderItemProps, userInfo: IUserInfo | undefined}) => {
  const { level, nickName,  icon, rank, customerId, point, globalLadderLength, myLadder, userInfo, minimumScoreRank, isHistory=false } = props;
  const showPoint = Number(Number(point).toFixed(0)).toLocaleString();
  const toProfile = () => {
    const messageData = {
      action: 'navigate',
      router: 'Profile',
      params: {
        handle: customerId === myLadder.customerId ? userInfo?.handle : nickName
      }
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  }

  /** 除王者排行榜中，前十名中满足最低分数的用户后面显示升级线 */
  const TopTen = Number(level) < 6 && (rank === minimumScoreRank);

  const LastTen = getLastTen({
    level,
    rank,
    minimumScoreRank,
    globalLadderLength
  });

  return (
    <>
    <li className={cn("flex justify-between p-2 items-center rounded-2xl", {
      'bg-Backgroun-Tertiary': rank === myLadder?.rank && level === myLadder?.level
    })}>
      <div className="flex items-center">
        <span className={cn("w-[22px] h-[22px] bg-Backgroun-Tertiary rounded-full text-xs text-Content-Primary font-SFProDisplay font-bold text-center leading-[22px]", {
          'bg-gradient-to-b from-[#FFF345] to-[#C2B92C] text-Content-Inverse-Primary': rank === 1,
          'bg-gradient-to-b from-[#E2E2E2] to-[#9E9E9E] text-Content-Inverse-Primary': rank === 2,
          'bg-gradient-to-b from-[#E8BC79] to-[#8F7137] text-Content-Inverse-Primary': rank === 3,
        })}>{rank}</span>
        <osp-image src={customerId === myLadder?.customerId ? (userInfo?.avatar as string) : icon || 'https://osp-temp.oss-accelerate.aliyuncs.com/public/somon/avatar/avatar1.webp'} class={cn("w-12 h-12 box-content rounded-full mx-3 border-2 border-Content_Inverse_Secondary cursor-pointer", {
          'border-Content-Accent1': rank === 1,
          'border-Content-Secondary': rank === 2,
          'border-Primitives-Orange-700': rank === 3,
        })} onClick={toProfile}/>
        <span className="text-base text-Content-Primary font-SFProDisplay font-bold cursor-pointer line-clamp-1 " onClick={toProfile}>{customerId === myLadder?.customerId ? userInfo?.handle : nickName}</span>
      </div>
      <div className="flex items-center">
      {myLadder.level === level && (
        <>
          <span className="text-base text-Content-Primary font-SFProDisplay mr-1 ml-8">
            {showPoint}
          </span>
          <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/points-icon.svg`} class="w-4 h-4"/>
        </>
      ) }
      </div>
    </li>
    {(TopTen || LastTen) && !isHistory && <div className="flex items-center justify-between">
      <div className={cn("flex-1 h-[1px]", {
        'bg-Content-Accent2': TopTen,
        'bg-Primitives-Red': LastTen
      })}></div>
      <div className="flex items-center px-2">
        {TopTen ? <JumpSvg/> : <GlideSvg/>}
       <span className={cn("text-smfont-SFProDisplay font-bold ml-2", {
        'text-Content-Accent2': TopTen,
        'text-Primitives-Red': LastTen
       })}> {TopTen ? 'Jump zone' : 'Glide zone'}</span>
      </div>
      <div className={cn("flex-1 h-[1px]", {
        'bg-Content-Accent2': TopTen,
        'bg-Primitives-Red': LastTen
      })}></div>
    </div>}
    </>
  )
}