// import { Bugsnag } from "bugsnag";
import { sendMessage } from "utils";

export const handleAccountInsufficient = (error: any) => {
  // 调用 somon 里的 handleError 统一处理方法
  // Bugsnag.notify(new Error(JSON.stringify(error)));
  sendMessage(JSON.stringify({
    action: 'sdkError',
    payload: error,
  }));
};