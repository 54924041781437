import React, { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader'
import { cn } from 'utils';

export const FeedItem = (props) => {
  const { item, index } = props;
  const iframeRef = useRef<any>(null)
  const [loading, setLoading] = useState(true)

  const id = `${index}-${item}`
  const src = `https://twitframe.com/show?url=${item}&theme=dark`

  const onClick = () => {
    window.open(item);
  }

  const handleLoad = () => {
    const childFrameObj: any = document.getElementById(id)
    childFrameObj?.contentWindow?.postMessage({ element: id, query: "height" }, "https://twitframe.com");
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])

  useEffect(() => {
    iframeRef?.current?.addEventListener('load', handleLoad);
    return () => iframeRef?.current?.addEventListener('load', handleLoad);
  }, [])

  return (
    <div className='relative'>
      {
        loading && (
          <div className='absolute w-full h-[334px] flex flex-row justify-center items-center p-3'>
            <div className='absolute bg-[#121212] w-[calc(100%-24px)] h-[calc(100%-24px)] p-4 rounded-2xl'>
              <ContentLoader
                style={{ width: '100%', height: '100%' }}
                backgroundColor="rgb(37, 37, 37, 1)"
                foregroundColor="rgb(37, 37, 37, 0.6)"
              >
                <circle cx="24" cy="32" r="23" />
                <rect x="58" y="22" rx="4" ry="4" width="119" height="20" />
                <rect x="0" y="80" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="116" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="152" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="188" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="224" rx="4" ry="4" width="80%" height="20" />
              </ContentLoader>
            </div>
          </div>
        )
      }
      <div className={cn('relative min-h-[250px] h-fit', {
          'opacity-0 h-[334px]': loading
        })} onClick={onClick}>
          <div className='absolute top-[-1px] h-4 w-full bg-[#0A0A0A]' />
          <iframe
            ref={iframeRef}
            className='pointer-events-none'
            id={id}
            name={item}
            height={250}
            width={'100%'}
            src={src}
          />
      </div>
    </div>
  )
}