import { TaskContext } from "@/utils/global";
import { getValue } from "firebase";
import { useContext, useEffect, useMemo, useState } from "react";

type IFirebaseSeasonConfig = {
  cardIcon: {
    icon1: string
    icon2: string;
    icon3: string;
  },
  cardBg: string;
  footerBgColor: string;
  darkMemberIcon: string;
  lightMemberIcon: string;
  shareText: string;
}

export const useFirebaseSeasonConfig = () => {
  const [firebaseSeasonConfig, setFirebaseSeasonConfig] = useState({} as any);
  const { isFirebaseReady } = useContext(TaskContext);

  const initSeasonConfig = async () => {
    const seasonConfigString = (await getValue("season_config")).asString();
    let value = {};

    try {
      value = JSON.parse(seasonConfigString || "") || {};
    } catch (err) {
      console.log(err, 'line15')
    }
    setFirebaseSeasonConfig(value);
    return value;
  };
  
  const curFirebaseSeasonConfig: IFirebaseSeasonConfig = useMemo(() => {
    const seasonIds = Object.keys(firebaseSeasonConfig).map((num) => Number(num)) || [];
    const curSeasonId = Math.max(...seasonIds);
    return firebaseSeasonConfig[curSeasonId] || {};
  }, [firebaseSeasonConfig]);

  useEffect(() => {
    isFirebaseReady && initSeasonConfig();
  }, [isFirebaseReady]);

  return { firebaseSeasonConfig, curFirebaseSeasonConfig };
};
