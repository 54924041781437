import { useInfiniteQuery } from "@tanstack/react-query";
import { useToast } from '@/hooks';
import { useClientProvider } from "client";
import { BadgeRecordStatusEnum } from 'api/api/customer'
import { useContext } from "react";
import { TaskContext } from "@/utils/global";

const LIMIT = 20;

export const useGetUnclaimedList = (isGetAll: boolean) => {
  const Toast = useToast();
  const { customerApi } = useClientProvider()
  const { state: { userInfo } } = useContext(TaskContext);
  const {
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: ['GET_UNCLAIMED_LIST', userInfo?.idToken],
    enabled: !!userInfo?.idToken,
    async queryFn(ctx) {
    try {
      // @ts-ignore
      const res = await customerApi.badges.listBadgeRecords(ctx.pageParam);

      if(!res?.data.success) {
        Toast.show("System error");
        return Promise.reject(res?.data.msgKey)
      }
      return res.data.obj
    } catch (error) {
      Toast.show("System error");
      return Promise.reject(error)
    }
    },
    initialPageParam: {
      limit: LIMIT,
      status: [BadgeRecordStatusEnum.UN_CLAIMED]
    },

    getNextPageParam(lastPage, allPages, lastPageParams, allPageParams) {
      if (!lastPage?.next_token) {
        return;
      }
      return {
        ...lastPageParams,
        nextToken: lastPage?.next_token,
      };
    },
    select(data) {
      const pages = data.pages.map((item) => item.rows).flat();
      return pages;
    },
  });

  if(hasNextPage && isGetAll) {
    fetchNextPage()
  }

  return {
    unclaimedList: data || [],
    isFetching,
    refetchUnclaimedList: refetch
  };
};
