import { useClientProvider } from "client";
import { BigNumber, utils } from "ethers";
import { Bugsnag } from "bugsnag";
import { handleAccountInsufficient } from "@/utils";

export const useGetGas = () => {
  const { ospClient } = useClientProvider()
  const getGasFee = async (IUserOps: any) => {
    const { data: gasData, error: gasError } = await ospClient.txClient.estimateOperation(IUserOps);
    if(!!gasError) {
      // 只是获取gasFee报错，不校验gas够不够
      handleAccountInsufficient(gasError);
      return { error: gasError };
    }
    try {
      const gas = BigNumber.from(gasData.callGasLimit)
      .add(gasData.verificationGasLimit)
      .add(gasData.preVerificationGas)
      .mul(gasData.maxFeePerGas);
      const formatGas = utils.formatUnits(gas);
      return { data: formatGas, error: null };
    }catch(e: any){
      if(typeof Bugsnag !== 'undefined' && typeof Bugsnag.notify !== 'undefined'){
        Bugsnag.notify(e)
        Bugsnag.notify(JSON.stringify(IUserOps))
      }
      return { data: null, error: e };
    }
  };

  return {
    getGasFee
  }
}