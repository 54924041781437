import { useState, useContext, useEffect } from 'react';
// @ts-ignore
import Close from "/src/assets/close.svg?react";
import { ClaimedList } from './ClaimedList';
import { ClaimedSuccess } from './ClaimedSuccess';
import { Header } from '../Header'
import { TaskContext } from '@/utils/global';
import { onShareBadge } from './utils'
import { logEventAnalytics } from 'firebase';
import { ClaimBadgesTips } from '../ClaimBadgesTips';
import { useGetUnclaimedList } from '../ClaimBadges/hooks/useGetUnclaimedList';
import { cn } from 'utils';
import { useSearchParams } from 'react-router-dom';

export const MyBadges = () => {
  const [searchParams] = useSearchParams();
  const [successVisible, setSuccessVisible] = useState<boolean>(false)
  const { state: { userInfo } } = useContext(TaskContext);
  const { inviteCode = '', handle = '', avatar = '', idToken = '' } = userInfo || {};

  const { unclaimedList } = useGetUnclaimedList(false)

  const shareBadgeId = searchParams.get('shareBadgeId');

  const onShare = () => {
    logEventAnalytics('click_successfullyClaimed_share')
    if (shareBadgeId) {
      onShareBadge({
        badge_id: shareBadgeId,
        inviteCode,
        handle,
        avatar
      }, idToken)
    }
  }

  useEffect(() => {
    if (shareBadgeId) {
      setSuccessVisible(true)
    }
  }, [shareBadgeId])

  return (
    <div className='badge'>
      <Header title="My badges"/>
      <div className={cn('overflow-scroll h-[calc(100dvh-78px)] mt-[78px] no-scrollbar')}>
        {!!unclaimedList.length &&  <ClaimBadgesTips />}
        <ClaimedList />
      </div>
      <ClaimedSuccess
        visible={successVisible}
        setVisible={setSuccessVisible}
        onShare={onShare}
      />
    </div>
  )
}
