import { envMap } from '@/consts'

export const config = {
  api: {
    baseUrl: envMap.questsApiUrl,
    customer: {
      points: '/v3/member/points/ledgers/{type}',
      accessToken: '/v2/customer/oauth/social/token',
      ladder: '/v2/member/ladder',
      ladderHistory: '/v2/member/ladder/history'
    },
    task: {
      list: '/v2/tasks/list',
      verify: '/v2/tasks/verify',
      doTask: '/v2/tasks/dotask',
      twitterTweets: '/v2/tasks/twitter/tweets/{taskId}'
    },
  }
}