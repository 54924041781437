import { useEffect, useRef } from "react";
import { DEFAULT_BADGE } from './const';
import { cn } from 'utils';
import { BADGE_LIST } from "@/consts";
interface BadgeListProps {
  currentLevel: string;
}

export const BadgeList = (props: BadgeListProps) => {
  const {currentLevel} = props;
  const BadgeRef = useRef<any>(null);
  useEffect(() => {
    // 76 和 80 指的徽章两种状态的宽度， 2为两徽章之间的间距，16为整体左右的padding
    const mainWidth = 76 * (Number(currentLevel)) + 80 + 2*5 + 16*2; // 计算初始进入页面，展示徽章所在位置的距离

    if(mainWidth >  BadgeRef.current.offsetWidth) {
      BadgeRef?.current?.scrollTo(mainWidth - BadgeRef.current.offsetWidth ,0)
    }
  }, [currentLevel])
  return (
    <ul className="overflow-x-scroll flex items-center pt-6 pb-4 px-4 no-scrollbar gap-x-[2px]" ref={BadgeRef}>
      {new Array(6).fill(null).map((_i, index) => {
        const icon = (index+1) > Number(currentLevel) ? DEFAULT_BADGE.icon : BADGE_LIST[index + 1]?.icon;
        return (
          <li key={index} className={cn("shrink-0 opacity-60", {
            'opacity-100': Number(currentLevel) === index+1
          })}>
            <osp-image src={icon} class={cn("w-[76px] h-[76px]", {
              'w-[100px] h-[100px] opacity-100': Number(currentLevel) === index+1
            })}/>
          </li>
        )
      })}
    </ul>
  )
}