import { useContext, useMemo, useState, useEffect } from "react";
import { useGetGas, useToast } from '@/hooks';
import { useClientProvider } from "client/ClientProvider";
import { handleAccountInsufficient } from "@/utils";
import { useTokenList } from 'hooks';
import { sendMessage } from "utils";
import { Bugsnag } from 'bugsnag';
import { TaskContext } from "@/utils/global";
import { compare } from "compare-versions";
import { OwOSwapper, TransPath } from "../lib/OwOSwapper"
import {ethers} from "ethers";
import { getValue } from "firebase";
import { useRefState } from './useRefState'


export const useSwapOwO = ({onGasEstimateSuccess}) => {

  const [swapper, setSwapper] = useState(null) 
  const [swapOwOConfig, swapOwOConfigRef, setSwapOwOConfig] = useRefState({})

  const { customerApi, ospClient } = useClientProvider()
  const Toast = useToast();
  const { tokenList } = useTokenList();
  const { getGasFee } = useGetGas();
  // 上链的查询数据结构（预估gas、领取）
  const [userOps, setUserOps] = useState<any>()
  // 页面上的 Claim 按钮 loading 状态
  const [isSwaping, setIsSwaping] = useState(false);
  // 预估gas的展示弹窗
  const [isShowGas, setIsShowGas] = useState(false);
  const { state: { userInfo }, isFirebaseReady } = useContext(TaskContext);
  const { appVersion, platformOS } = userInfo || {};
  // gas费 原生币
  const symbol = useMemo(() => {
    const token = tokenList.find((item: any) => item.type === 'native');
    return token?.symbol || "";
  }, [tokenList]);

  useEffect(() => {
    const getConfig = async () => {
      let value = {};

      try {
        const swap_owo_config = await getValue("swap_owo_config");
        value = JSON.parse(swap_owo_config.asString() || "") || [];
      } catch (err) {
        console.log(err, 'line56')
      }
      setSwapOwOConfig(value);
    }
    isFirebaseReady && getConfig();
  }, [isFirebaseReady]);

  useEffect(()=>{
    if(swapOwOConfig.gasAddress && swapOwOConfig.maxSlippage) {
      const owoInstance = new OwOSwapper(new ethers.providers.JsonRpcProvider("https://mainnet.base.org"), swapOwOConfig.gasAddress, swapOwOConfig.maxSlippage)
      console.log('owoInstance', owoInstance)
      setSwapper(owoInstance)
    }

  },[swapOwOConfig])


  const getUserOps = async (path, sender, amountIn, amountOutMin, portionBips) => {
    let ops = await swapper.swap(path, sender, amountIn, amountOutMin, portionBips);
    console.log(`price: ${JSON.stringify(ops)}`);
    return ops

  }
  const calGasFee = async (path, sender, amountIn, amountOutMin, portionBips) => {
    setIsSwaping(true);
    const IUserOps = await getUserOps(path, sender, amountIn, amountOutMin, portionBips)
      // @ts-ignore
    const { data: gasData, error: gasError } = await getGasFee(IUserOps);
    console.log('gasData',gasData, IUserOps, gasError)

    try {
      if (!gasError) {
        setIsSwaping(false);
        onGasEstimateSuccess(gasData, IUserOps)
      }
    } catch (error) {
      setIsSwaping(false);
      Toast.show("System error");
      return Promise.reject(error)
    }

  }



  const toogleTransitionLoading = (isShow: boolean) => {
    // 调用somon _Layout 里的公用弹窗 <TransitionLoadingNew />
    const messageData = {
      action: 'popup',
      router: 'toogleTransitionLoading',
      params: {
        isShow
      }
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  }

  const swap = async (IUserOps, gasData) => {
    setIsSwaping(true)
    sendMessage(JSON.stringify({
      action: 'SWAP_OWO',
      payload: {
        userOps: IUserOps,
        gas: gasData,
      },
    }));
  };

  return {
    swapper,
    TransPath,
    swap,
    isSwaping,
    setIsSwaping,
    swapOwOConfig,
    swapOwOConfigRef,
    isShowGas,
    setIsShowGas,
    symbol,
    toogleTransitionLoading,
    calGasFee
  };
};
