
import React, { useContext } from "react";
import { cn, sendMessage } from "utils"
import { Overlay, Button } from 'react-vant';
import { useClientProvider } from "client";
import { useToast } from "@/hooks";
import { useSwapOwO } from "./hooks/useSwapOwO"
import { TaskContext } from "@/utils/global";
// @ts-ignore
import BaseChain from "/src/assets/base.svg?react";
// @ts-ignore
import OwOCoin from "/src/assets/coin_owo.svg?react";

// @ts-ignore
import ETHCoin from "/src/assets/eth.svg?react";
// @ts-ignore
import Spinner from "/src/assets/spinner.svg?react";
// @ts-ignore
import ArrowDownIcon from "/src/assets/arrow-down.svg?react";
import { formatNum, trimEndZero} from './util'

type CLAIM_PROPS  = {
  visible: boolean;
  onCancel: () => void;
}

export const ConfirmModal = (props: CLAIM_PROPS) => {
  const { 
    visible, onCancel, isSellOwO , isSwaping, formState, fromUSDPrice, toUSDPrice, oneOwOSwapAmunt, portionBips, maxSlippage, IUserOps, swap, gasData } = props;
  const { isPC } = useClientProvider();

  const Toast = useToast();
  const { state: { userInfo } } = useContext(TaskContext);

  return (
    <Overlay visible={visible} onClick={onCancel} style={{
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    }}>
      <div className={cn("modal-container w-full pt-8 pb-[45px] bg-[#111111] rounded-tl-2xl rounded-tr-2xl flex-col justify-start items-center gap-2 inline-flex bg-Backgroun-Secondary px-4")}>

        <div className="w-full flex-col justify-start items-start gap-8 flex">
          <div className="self-stretch h-9 flex-col justify-start items-start gap-8 flex">
            <div className="self-stretch h-9 flex-col justify-start items-start gap-6 flex">
              <div className=" text-[#fdfdfd] text-2xl font-bold font-SFProDisplay leading-9">Confirm transaction</div>
            </div>
          </div>

          <div className="self-stretch h-[279px] flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch h-[146px] p-3 bg-[#111111] rounded-2xl border border-[#292929] flex-col justify-start items-center flex">
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <div className="w-10 h-10 relative">
                    {
                      isSellOwO
                      ?
                      <OwOCoin className="w-10 h-10" />
                      :
                      <ETHCoin className="w-10 h-10" />
                    }

                    <BaseChain className="w-5 h-5 absolute bottom-[-4px] right-[-4px]" />

                  </div>
                  <div className="flex-col justify-center items-start inline-flex">
                    <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{isSellOwO ? 'OwO' : 'ETH'}</div>
                    <div className="text-[#a0a0a0] text-xs font-normal font-SFProDisplay leading-[18px]">Base</div>
                  </div>
                </div>
                <div className="w-[43px] flex-col justify-start items-end gap-1 inline-flex">
                  <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{formState.fromAmount}</div>
                  <div className="text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]">${fromUSDPrice}</div>
                </div>
              </div>
              <div className="self-stretch pl-2 justify-start items-center gap-2 inline-flex">
                <ArrowDownIcon className="w-6 h-6 relative" />
                <div className="h-[1px] w-full bg-[#292929]"/>
              </div>
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <div className="w-10 h-10 relative">
                    {
                      isSellOwO
                      ?
                      <ETHCoin className="w-10 h-10" />
                      :
                      <OwOCoin className="w-10 h-10" />                      
                    }

                    <BaseChain className="w-5 h-5 absolute bottom-[-4px] right-[-4px]" />
                  </div>
                  <div className="flex-col justify-center items-start inline-flex">
                    <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{isSellOwO ? 'ETH' : 'OwO'}</div>
                    <div className="text-[#a0a0a0] text-xs font-normal font-SFProDisplay leading-[18px]">Base</div>
                  </div>
                </div>
                <div className="flex-col justify-start items-end gap-1 inline-flex">
                  <div className="text-[#fdfdfd] text-base font-bold font-SFProDisplay leading-normal">{formState.toAmount}</div>
                  <div className="justify-start items-start gap-1 inline-flex">
                    <div className="text-[#c5c5c5] text-sm font-normal font-SFProDisplay leading-[21px]">${toUSDPrice}</div>
                  </div>
                </div>
              </div>
            </div>




            <div className="h-[117px] flex-col justify-start items-start gap-[11px] flex w-full">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Rate</div>
                <div className="justify-start items-center gap-0.5 flex">
                  <div className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">1 OwO = {trimEndZero(oneOwOSwapAmunt)} ETH</div>
                </div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Gas</div>
                <div className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">{trimEndZero(formatNum(gasData, 8))} ETH</div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Processing fee</div>
                <div className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">{portionBips / 100}%</div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#a0a0a0] text-sm font-normal font-SFProDisplay leading-[21px]">Max slippage</div>
                <div className="text-[#fdfdfd] text-sm font-normal font-SFProDisplay leading-[21px]">{maxSlippage / 100}%</div>
              </div>
            </div>
          </div>
          <Button 
            type='primary'
            block
            round
            color='#FFF345'
            disabled={isSwaping}
            className='h-14 text-[#0A0A0A]'
            onClick={()=>{
              //To-Do
              // 1 Confirm Swap  2 getUserOp 3 getGasFee 4 sendMessageToParent 5 Parent Gas Consume Confirm 6 parentReturnMessage 7 show Swap Success Screen
              swap(IUserOps, gasData)
            }}>
            {

              isSwaping
              ?
              <Spinner className="fill-[#4D4D4D] w-5 h-5 animate-spin" />
              :
              <span
                className="text-[#0A0A0A] text-base font-SFProDisplay font-bold leading-6"
              >Confirm
              </span>

            }



          </Button>

        </div>



      </div>
    </Overlay>
  )
}