// @ts-nocheck
import type { QueryKey, UseQueryResult, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "../axiosHooks";
import { config } from "../config";

export const getTwitterTweetsQueryKey = () => [{url:`${config.api.task.twitterTweets}`}] as const;

export function getTwitterTweetsQueryOptions (taskId: string, options): UseQueryOptions<TData, TError> {
  const queryKey = getTwitterTweetsQueryKey();
  return {
    queryKey,
    enabled: true,
    queryFn: () => {
      return client<TData, TError>({
        method: "get",
        url: `${config.api.task.twitterTweets.replace('{taskId}', taskId)}`,
        headers: { ...options.headers },
        ...options,
        baseURL: config.api.baseUrl
      }).then(res => {
        const data = res.data?.obj;
        return data
      });
    },
  };
}

/**
 * @summary 获取twitter tweets
 * @link /v2/tasks/twitter/tweets/{taskId}
 */

export function useGetTwitterTweets (taskId: string, options?): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
const { query: queryOptions, client: clientOptions = {} } = options ?? {};
const queryKey = queryOptions?.queryKey ?? getTwitterTweetsQueryKey();

const query = useQuery<TData, TError>({
...getTwitterTweetsQueryOptions<TData, TError>(taskId, clientOptions),
...queryOptions
}) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

query.queryKey = queryKey as QueryKey;
return query;
}
