import React, { useRef, useEffect } from 'react';

import lottie, { AnimationItem } from 'lottie-web';
import SomonLoading from './somon_monster_loading.json';
import { Overlay } from 'react-vant';

interface LottieProps {
  visible: boolean;
  mainStyle ?: React.CSSProperties
}

export const SomonLottie: React.FC<LottieProps> = (props) => {
  const { visible, mainStyle = {} } = props;
  const lottieRef = useRef<HTMLDivElement | null>(null);
  const lottieInstance = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if(lottieRef.current){
      lottieInstance.current = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: SomonLoading,
      });
    }

    return () => {
      lottieInstance?.current?.destroy();
    }
  }, []);

  return (
    <Overlay visible={visible}
      style={{...{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "--rv-overlay-background-color": "rgba(0,0,0,0.2)"
      }, ...mainStyle}}>
      <div className="w-full h-full  flex flex-row items-center justify-center">
        <div ref={lottieRef} className="w-[300px] h-fit"></div>
      </div>
    </Overlay>
  );
};