// @ts-ignore
import LeftLogo from "/src/assets/angle-left-b.svg?react";
// @ts-ignore
import Share from "/src/assets/share.svg?react";
// @ts-ignore
import CheckedLogo from "/src/assets/check-alt.svg?react";
// @ts-ignore
import Gas from "/src/assets/gas.svg?react";
// @ts-ignore
import LoadingIcon from "/src/assets/loading.svg?react";
import { formatPrettyValue, sendMessage, compare, dayjs, cn, copyText } from 'utils';
import { DetailCard } from "./DetailCard";
import { SeasonTab } from './SeasonTab';
import { useSeasonConfig, useToast } from "@/hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { onShareOptions, onShareSettle } from "./utils";
import { TaskContext } from "@/utils/global";
import { useClaimOwO, useAchievementDetail } from "./hooks";
import { AirdropHeader } from "./AirdropHeader";

import { logEventAnalytics } from "firebase";
import { claimStatusEum, defaultCount, settleStatusEum, tabAnalyticsType } from "./const";
import { Calculating } from "./Calculating";
import { PointsFooter } from "./PointsFooter";
import { BadgesFooter } from "./BadgesFooter";
import { PfpFooter } from "./PfpFooter";
import { ClaimedSuccess } from "./ClaimOwoSuccess";
import { useClientProvider } from "client";
import { useNavigate } from "react-router-dom";

export const Achievement = () => {
  const navigate = useNavigate();
  const { isPC } = useClientProvider();
  const [currentId, setCurrentId] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const { state: { userInfo } } = useContext(TaskContext);
  const { inviteCode = '', handle = '', avatar = '', idToken = '', appVersion } = userInfo || {};
  const { achievementDetail, pointData, shareText } = useAchievementDetail({ seasonId: currentId });
  const { 
    settleStatus, points,
    pfpPortion, pfpCount,
    maxPfpCount, pfp,
    badgeCount, badges,
    previousVipCount, vipCount,
    claimStatus, topRanked,
    owoCount, claimEndTime,
    vipIcon, previousVipIcon,
    topLeaderBoardLevel, tribes,
    claimStartTime, joinNftCount
  } = achievementDetail;
  const curSeasonPoints = settleStatus === settleStatusEum.SETTLED ? points : pointData?.available;
  const { seasonConfig, currentSeasonId, getSeasonInfo, isCalculating} = useSeasonConfig();
  const currentSeasonInfo = getSeasonInfo(currentId) || {};
  const { onClaim, isClaiming, successVisible, setSuccessVisible, curClaimStatus } = useClaimOwO({
    owoTokenAddress: currentSeasonInfo?.owoTokenAddress,
    claimStatus,
  });

  const Toast = useToast();
  const isShowTribe = currentId === 2 || currentId === 3;
  const isShowPfpTotal = currentId > 3;
  const isHiddenOSProfile = currentId > 5;
  const isHiddenMember = currentId > 5;

  const claimOwoTextMap = {
    [claimStatusEum.CLAIMED]: "Claimed",
    [claimStatusEum.UN_CLAIMED]: "Claim OwO",
    [claimStatusEum.UN_STARTED]: `Claim on ${dayjs(claimStartTime).utc().format("MMM D, HH:mm")} UTC`,
    [claimStatusEum.EXPIRED]: "Expired",
  } as any;

  const goBack = () => {
    if(window?.ReactNativeWebView) {
			const messageData = {
				action: 'back'
			};
			const message = JSON.stringify(messageData);
			sendMessage(message);
		} else {
			navigate(-1);
		}
  };

  const onCheckTab = (id:number) => {
    logEventAnalytics("click_airdrop_seasonTab", {
      type: tabAnalyticsType[curClaimStatus],
      tab: `season${id}`
    })
    setCurrentId(id)
    getSeasonInfo(id);
  }

  const seasonShareOptions = useMemo(() => {
    return (
      {
        inviteCode,
        handle,
        avatar,
        season: currentId.toString(),
        mcount: vipCount === defaultCount ? "" : vipCount,
        earned: !!owoCount ? `${formatPrettyValue(owoCount)}` : owoCount,
        points: !!curSeasonPoints ? `${formatPrettyValue(curSeasonPoints)}` : defaultCount,
        badge: badgeCount,
        pfp: isShowPfpTotal ? pfpPortion : pfpCount,
        topranked: topLeaderBoardLevel,
        ownedTribeSize: isShowTribe ? `${!!joinNftCount ? formatPrettyValue(joinNftCount): defaultCount}` : "",
      }
    );
  }, [userInfo, currentId, achievementDetail, curSeasonPoints, isShowPfpTotal]);

  const onShare = useCallback(async () => {
    logEventAnalytics('click_airdrop_share');
    const { shareOptions, inviteLink }= await onShareOptions(seasonShareOptions, idToken, shareText);

    if(userInfo?.platformOS === "web") {
      copyText(inviteLink)
      setTimeout(() => {
        Toast.show("copy success", "success");
      }, 100)
    } else {
      sendMessage(JSON.stringify({
        action: 'share',
        option: shareOptions,
      }));
    }
  }, [seasonShareOptions, idToken, userInfo?.platformOS, shareText]);

  const onShareTwitter = useCallback(async () => {
    await onShareSettle(seasonShareOptions, idToken, shareText);
    setSuccessVisible(false)
  }, [seasonShareOptions, idToken, shareText]);

  const getScrollTop = () => {
    let scrollTop = document.documentElement.scrollTop || window.pageXOffset || document.body.scrollTop || 0;
    if(scrollTop > 0) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', getScrollTop);
    return () => {
      window.removeEventListener('scroll', getScrollTop);
    }
  }, []);

  useEffect(() => {
    const currentSeason = seasonConfig?.find((item:any) => item.current);
    if(!currentSeason?.startTime || !currentSeasonId) return;
    const preSeasonClaimOwoTime = dayjs(currentSeason.startTime).utc().add(7, 'days');
    const initSeasonId = dayjs().utc() > preSeasonClaimOwoTime ? currentSeasonId : currentSeasonId - 1;
    setCurrentId(initSeasonId);
  }, [currentSeasonId, seasonConfig]);

  const handleClaimOwo = () => {
    if(isClaiming || curClaimStatus !== claimStatusEum.UN_CLAIMED) return;
    // 1.12.0 之后才会有discard绑定信息
    const isNeedBindInfo = !!appVersion && compare(
      appVersion,
      "1.12.0",
      "<"
    );
    const isNeed = !!appVersion && compare(
      appVersion,
      "1.4.0",
      "<"
    );
    // 旧版本没有绑定 twitter 的时候，提示需要更新版本
    if(window?.ReactNativeWebView && (isNeed || (isNeedBindInfo && !pointData?.twitterName))) {
      Toast.show("Please upgrade to the latest version and try again.");
      return;
    }
    // 没有绑定discard & twitter的时候，需要提示用户去绑定
    if(!userInfo?.discordName && !pointData?.twitterName) {
      sendMessage(JSON.stringify({
        action: "bindDiscard",
      }));
      return;
    }
    logEventAnalytics('click_token_owo');
    onClaim();
  }

  return (
    <div className="w-full h-fit relative">
      <div className={cn(`bg-[url('/img/achievement_bg.webp')] h-[443px] bg-cover bg-no-repeat bg-[center_bottom_0] pt-[192px]`, {
        "pt-0 bg-[url('/img/achievement_bg_pc.webp')]": isPC,
      })}>
        <div className={cn("fixed w-full z-10 top-0 pt-[54px]", {
          "bg-Content-Accent2" : isScroll,
          "sticky pt-0": isPC
        })}>
          <div className="flex justify-between p-4">
            <div
              className="bg-Content-Inverse-Primary-BG cursor-pointer rounded-full flex flex-row justify-center items-center w-[46px] h-[46px]"
              onClick={goBack}
            >
              <LeftLogo className="w-6 h-6 text-Content-Primary"/>
            </div>
            {settleStatus !== settleStatusEum.SETTLEING && <div className="bg-Content-Inverse-Primary-BG cursor-pointer rounded-full flex flex-row justify-center items-center w-[46px] h-[46px]" onClick={onShare}>
              <Share className="w-6 h-6 text-Content-Primary"/>
            </div>}
          </div>
          <SeasonTab currentId={currentId} callback={onCheckTab} seasonConfig={seasonConfig}/>
        </div>
        <AirdropHeader currentId={currentId} isExpired={curClaimStatus === claimStatusEum.EXPIRED} settleStatus={settleStatus} owoCount={owoCount} endTime={currentSeasonInfo?.endTime} claimEndTime={claimEndTime}/>
      </div>
      {
        settleStatus === settleStatusEum.SETTLEING ? <Calculating /> : (
          <div className="w-full h-fit px-6 pt-1">
            <div className="text-left align-top text-base font-SFProDisplay font-normal whitespace-nowrap text-Content-Secondary my-3">
              Achievement
            </div>
            {isShowTribe && <DetailCard
              title="Owned Tribe size"
              count={!!joinNftCount ? `${formatPrettyValue(joinNftCount)}` : defaultCount}
              footer={(
                <div className="flex">
                  <PfpFooter
                    pfp={tribes.list}
                    containerClassName="mt-4"
                    contentClassName={cn("py-[3px] px-1 rounded-md h-[30px]", {
                      "rounded-l-md rounded-r-none pr-0": tribes.total - tribes.list.length > 0,
                      "px-0": !tribes.list.length,
                    })}
                    imgClassName="rounded"
                    imgContainerClassName="rounded-md"
                  />
                  {tribes.total - tribes.list.length > 0 && (
                    <div className="bg-Button-white/5 py-[3px] pr-1 mt-4 rounded-r-md">
                      <div className="h-6 z-[6] ml-[-6px] bg-Border-Solid border-2 flex items-center justify-center border-Backgroun-Tertiary rounded relative text-[10px] text-Content-Accent2 font-bold px-[3px]">+{tribes.total - tribes.list.length}</div>
                    </div>
                  )}
                </div>
              )}
            />}
            <DetailCard
              title="Points"
              count={isCalculating ? "--" : (!!curSeasonPoints ? `${formatPrettyValue(curSeasonPoints?.toFixed(0))}` : defaultCount)}
              icon={!isCalculating ? `${process.env.VITE_PUBLIC_BASE_PATH}/img/points-icon.svg` : ''}
              contentClassName={`bg-[url('/img/pointsBg.png')] bg-no-repeat bg-[length:158px_auto] bg-right-bottom`}
              footer={(
                <PointsFooter
                  points={curSeasonPoints?.toFixed(0)}
                  vipCount={vipCount}
                  pfpCount={maxPfpCount}
                  vipIcon={vipIcon}
                  previousVipCount={previousVipCount}
                  previousVipIcon={previousVipIcon}
                  seasonId={currentId}
                />
              )}
            />
            <DetailCard
              title="Badges"
              count={badgeCount}
              countClassName={`${cn({
                "leading-[30px]" : !badges?.length
              })}`}
              footer={(
                <BadgesFooter
                  badges={badges}
                />
              )}
            />
            <DetailCard
              title={isShowPfpTotal ? "OwOG PFP Holder Points" : "OwOG pfp"}
              containerClassName="flex-1"
              count={isShowPfpTotal ? pfpPortion : pfpCount}
              footer={(
                // 已结算完成后，后端获取不到用户持有的pfp
                pfp && !!pfp.length ? (
                  <PfpFooter
                    pfp={pfp}
                    pfpCount={isShowPfpTotal && (
                      settleStatus === settleStatusEum.SETTLED || pfpCount === defaultCount ? "" : pfpCount
                    )}
                  />
                ) : null
              )}
            />
            <div className="flex gap-4">
              <DetailCard
                title="Membership"
                containerClassName={cn("flex-1", {'hidden': isHiddenMember})}
                footer={(
                  <div className="flex justify-between items-end mt-[26px]">
                    <osp-image src={vipIcon} class="w-5 h-5" />
                    <div className="text-Content-Accent2 text-4xl font-Oswald">{vipCount}</div>
                  </div>
                )}
              />
              <DetailCard
                title="Top Ranked"
                containerClassName="flex-1"
                contentClassName={cn({"flex-row": isHiddenMember})}
                footer={(
                  <div className={cn("flex items-center mt-[44px]", {
                    "mt-0": isHiddenMember,
                  })}>
                    {topRanked?.icon && <osp-image src={topRanked?.icon} class="w-6 h-6 mr-[2px]" />}
                    <div className="text-Content-Accent2 text-base font-Oswald">{topRanked?.name}</div>
                  </div>
                )}
              />
            </div>
            <DetailCard
              title="OpenSocial Profile"
              containerClassName={cn("mb-[110px]", {
                "mb-[6px]": settleStatus === settleStatusEum.SETTLED && curClaimStatus,
                "hidden": isHiddenOSProfile
              })}
              contentClassName="flex-row"
              footer={<CheckedLogo className="w-6 h-6 mr-[2px] text-Content-Accent2" />}
            />
            {(settleStatus === settleStatusEum.SETTLED && curClaimStatus) && <div className="sticky bottom-0 py-6 bg-Backgroun-Primary z-auto">
              <div
                className={cn(`flex items-center justify-center cursor-pointer rounded-[36px] bg-Content-Accent1 h-[56px] text-center leading-[56px] text-base font-SFProDisplay font-bold z-auto`, {
                  "bg-Button-white/5 border-2 border-Overlay-Light-Overlay20/20 text-Content-Disabled cursor-not-allowed": curClaimStatus !== claimStatusEum.UN_CLAIMED,
                  "bg-Primitives-Yellow-700 text-Content-Disabled cursor-not-allowed": curClaimStatus === claimStatusEum.UN_STARTED
                })}
                onClick={handleClaimOwo}
              >
                {isClaiming && <LoadingIcon className="w-4 h-4 mr-1 animate-spin text-Content-Inverse-Primary-BG" />}
                <span>{claimOwoTextMap[curClaimStatus]}</span>
              </div>
            </div>}
            <ClaimedSuccess visible={successVisible} onShare={onShareTwitter} setVisible={setSuccessVisible}/>
          </div>
        )
      }
    </div>
  )
}