// @ts-nocheck
import { transform, TransformResult} from './transform'
import { formatError } from './formatError'
import { Bugsnag } from 'bugsnag'

export interface IFetchFnRes<T = any, K = any> {
  data: T;
  error: ReturnType<typeof formatError<T, K>>['error']
}

export const fetchFn = async <T = any,K = any>(args: any, fnList: any, that: any): Promise<IFetchFnRes<TransformResult<T>, K>> => {
  const osp = await that.getOsp();
  try {
    return {
      data: transform(await osp[fnList[0]][fnList[1]](...args)),
      error: null
    };
  } catch (error) {
    if(typeof Bugsnag !== 'undefined' && typeof Bugsnag.notify !== 'undefined'){
      Bugsnag.notify(error)
    }
    
    return formatError<TransformResult<T>, K>(error);
  }
}