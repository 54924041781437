import { defaultCount } from "./const";

type IBadgesFooterProps = {
  badges: {
    type: string;
    image: string;
    count: string;
  }[]
}

export const BadgesFooter = (props: IBadgesFooterProps) => {
  const { badges } = props;
  return (
    <div className="flex gap-x-[30px] gap-y-3 mt-1 flex-wrap">
      {
        badges.map((item, index) => {
          const { type, image, count } = item;
          return (
            <div className="flex-1 inline-flex flex-col justify-between" key={index}>
              <div className="text-Content-Secondary text-xs font-SFProDisplay min-w-[44px]">{type}</div>
              <div className="mt-1 w-12 inline-flex items-center p-1 bg-Button-white/5 rounded-3xl">
                <osp-image src={image} class="mr-[2px] w-5 h-5" />
                <span className="text-Content-Accent2 text-sm font-SFProDisplay font-bold">
                  {count !== defaultCount && <span>×</span>}{count}
                </span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}