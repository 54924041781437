import { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'react-vant';
import { BadgeDetailsProps } from './type'
import { Button } from 'react-vant';
import { UnclaimedItem } from './UnClaimedItem';
import { UnclaimedSkeleton } from './UnClaimedSkeleton';
import { Empty } from '../Empty';
import { useClaimBadge, useGetUnclaimedList } from './hooks';
import { useQueryClient } from "@tanstack/react-query";
import { getLatestBadgeInSelected } from '../MyBadges/utils'
import { logEventAnalytics } from 'firebase';
// @ts-ignore
import Gas from "/src/assets/currency-coin-dollar.svg?react";

import { TaskContext } from '@/utils/global';
import { sendMessage } from 'utils';
import { Bugsnag } from 'bugsnag';

export const UnclaimedList = () => {
  const queryClient = useQueryClient();
  const { state: { userInfo } } = useContext(TaskContext);

	const [selectedBadge, setSelectedBadge] = useState<string[]>([]);

  const { unclaimedList, isFetching } = useGetUnclaimedList(true)
  const { onClaim, isClaiming } = useClaimBadge();
  const [isConfirming,setisConfirming]= useState(false)

  const selectedNum = selectedBadge.length;

  const handleSelect = (id: string) => {
    const newValue = selectedBadge.includes(id) ? selectedBadge.filter(el => el !== id) : [...selectedBadge, id]
    setSelectedBadge(newValue);
  }

  const goMyBadges = (params: object) => {
    const messageData = {
      action: 'navigate',
      router: 'MyBadges',
      params
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  useEffect(()=>{
    window.addEventListener("message", onSuccess);
    document.addEventListener("message",onSuccess)
    return () =>{
        document.removeEventListener("message",onSuccess,false)
        window.removeEventListener("message", onSuccess,false);
    }
  },[unclaimedList, selectedBadge])

  const onSuccess = (msg: any) => {
    try {
      const message=JSON.parse(msg.data)
      if(message?.code !== 'CLAIM_BADGE') {
        return
      }
      if(message?.payload?.isOnchain) {
        setisConfirming(true)
        return
      }
      if(message?.payload?.success){
        setisConfirming(false)
        const latestBadge = getLatestBadgeInSelected(unclaimedList, selectedBadge);
        const latestBadgeId = latestBadge?.badge_id || ''
        setTimeout(() => {
          setSelectedBadge([])
          queryClient.invalidateQueries({ queryKey: ['GET_CLAIMED_LIST'] });
          queryClient.invalidateQueries({ queryKey: ['GET_UNCLAIMED_LIST'] });
          goMyBadges({
            shareBadgeId: latestBadgeId
          })
        }, 2000)
      }
    } catch (error) {
      Bugsnag.notify(new Error(JSON.stringify(error))) 
    }
  }

  useEffect(() => {
    if (!userInfo?.idToken) return
    const initSelect = [] as string[];
    unclaimedList.forEach(item => {
    initSelect.push(item.id)
    })
    setSelectedBadge(initSelect)
  }, [unclaimedList, userInfo])

  if (isFetching) {
    return <UnclaimedSkeleton />
  }

  if (unclaimedList?.length === 0) {
    return <Empty emptyText='No pending claims'/>
  }


  return (
    <>
      <div className='overflow-scroll h-[calc(100dvh-78px-104px)] mt-[78px] no-scrollbar'>
        <Checkbox.Group
          value={selectedBadge} onChange={setSelectedBadge}
          className='px-4 flex flex-col gap-3'
        >
          {
            unclaimedList.map((item: BadgeDetailsProps) => {
              return (
                <UnclaimedItem key={item.id} itemData={item} handleSelect={handleSelect} isClaiming={isClaiming} isConfirming={isConfirming} />
            )})
          }
        </Checkbox.Group>
      </div>
      <div className='sticky bottom-0 h-[104px] w-full bg-[#0A0A0A] flex flex-row justify-center items-center px-4'>
        <Button
          type='primary'
          block
          round
          color='#FFF345'
          loading={isClaiming}
          loadingText={'Claiming'}
          className='h-14 text-[#0A0A0A]'
          disabled={selectedNum < 1 || isConfirming}
          onClick={() => {
            logEventAnalytics('click_mybadge_claim')
            onClaim(selectedBadge)
          }}
        >
          <span
            className='text-[#0A0A0A] text-base font-SFProDisplay font-bold leading-6'
          >
              {`Claim ${selectedNum} ${ selectedNum  > 1 ? 'badges' : 'badge'}`}
          </span>
        </Button>
      </div>
    </>
  )
}