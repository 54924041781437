'use client'
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'

import { cn } from 'utils';
import { InfiniteScrollProps } from './type';


export const InfiniteScroll = (props: InfiniteScrollProps) => {
  const { className, children, fetchData, invisibleHeight } = props;
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fetchData()
    }
  }, [inView])

  return (
    <div className={cn(className, 'relative')}>
      <div
        style={{ height: `${invisibleHeight}px` }}
        className='absolute z-[-1] bottom-0'
        ref={ref}
      />
      {children}
    </div>
  );
};
