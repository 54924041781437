import { cn } from 'utils';
import { UnclaimedItemProps } from './type'
import { Checkbox, Image } from 'react-vant';
// @ts-ignore
import CheckIcon from "/src/assets/check.svg?react";
// @ts-ignore
import Spinner from "/src/assets/spinner.svg?react";

export const UnclaimedItem = (props: UnclaimedItemProps) => {
  const { itemData, handleSelect, isClaiming, isConfirming } = props;
  const disabled = isClaiming || isConfirming;
  const claiming = false;

  const { id, image, name, description, remark } = itemData || {};

  let des = remark || description || '';

  return (
    <div
      className="bg-Backgroun-Tertiary rounded-2xl p-3 flex flex-row justify-between items-center w-[100%] h-[82px]"
      onClick={() => {
        if (disabled || claiming) return;
        handleSelect(id)
      }}
    >
      <div className='flex flex-row gap-3'>
        <div className='w-[58px] h-[58px]'>
          <Image width='58' height='58' src={image} fit='cover' />
        </div>
        <div className='flex flex-col justify-between py-1 mr-4'>
          <span className='text-base font-SFProDisplay font-bold leading-6 text-[#fdfdfd] break-all line-clamp-1'>
            {name}
          </span>
          <span className='text-sm font-SFProDisplay font-normal leading-[21px] text-[#a0a0a0] break-all line-clamp-1'>
            {des}
          </span>
        </div>
      </div>
      <div>
      {
        claiming 
          ? <Spinner className={cn("fill-Content-Disabled w-5 h-5 animate-spin")} />
          : (
              <Checkbox
                disabled={disabled}
                className="w-5 h-5 rounded"
                name={id}
                shape="square"
                iconRender={({ checked: isActive }) => (
                  <div className={cn("w-5 h-5 box-border rounded-[5px] border-[1.25px] border-[#292929] bg-[#0A0A0A]", {
                    "bg-Content-Accent1": isActive,
                    "bg-[#363636]": disabled
                  })}>
                    {isActive && <CheckIcon className="w-[18px] h-[18px]" />}
                  </div>
                )}
              />
          )
      }
      </div>
    </div>
  )
}